/**
 * @api {module} SystemApi
 * @apiDescription Provides access to system-related endpoints.
 * @apiVersion 1.0.0
 * @apiName SystemApi
 * @apiGroup API Modules
 */

import { dlaasApi } from '../';

const systemApi = dlaasApi.injectEndpoints({
  endpoints: (builder) => ({
    sendSalesInquiry: builder.mutation({
      query: ({ firstname, lastname, email, company, message }) => ({
        url: `${process.env.REACT_APP_API_HOST}/system/v1/send_sales_inquiry`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname,
          lastname,
          company,
          email,
          message,
        }),
      }),
    }),

    /**
     * @api {function} useListInstallerFilesQuery List Installer Files Query
     * @apiDescription This query allows you to list installer files.
     * @apiName useListInstallerFilesQuery
     * @apiGroup SystemApi
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the list of installer files.
     */
    listInstallerFiles: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_HOST}/system/v1/get_installer_files`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useSendSalesInquiryMutation, useListInstallerFilesQuery } =
  systemApi;
