import _ from 'lodash';
import React, { useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'flowbite-react';
import { useUserProfileQuery } from 'api/dlaas/user';
import { useDeleteMirrorMutation } from 'api/dlaas/mirror';

const DeleteMirrorModal = ({ storeId, label, onClose = _.noop }) => {
  const [deleteMirror] = useDeleteMirrorMutation();
  const { data: userProfile, isLoading } = useUserProfileQuery();
  const [showSpinner, setShowSpinner] = React.useState(false);

  const handleDelete = useCallback(async () => {
    setShowSpinner(true);
    await deleteMirror({
      userId: userProfile.id,
      storeId,
    });
    onClose();
  }, [storeId, deleteMirror, onClose, userProfile]);

  if (isLoading) {
    return null;
  }


  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="delete-mirror" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <FormattedMessage id="are-you-sure-you-want-to-delete-mirror" />
        <Spacer size={20} />
        <div>You are about to delete: {label}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: 75 }} onClick={onClose} color="light">
          <FormattedMessage id="cancel" />
        </Button>
        <Button style={{ width: 75 }} onClick={handleDelete} color="failure">
          {showSpinner ? (
            <Spinner size="sm" />
          ) : (
            <FormattedMessage id="delete" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteMirrorModal };
