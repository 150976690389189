export const WHITE = {
  class: 'white',
  hex: '#FFFFFF',
};

export const GREY50 = {
  class: 'gray-50',
  hex: '#F9FAFB',
};

export const GREY100 = {
  class: 'gray-100',
  hex: '#F3F4F6',
};

export const GREY200 = {
  class: 'gray-200',
  hex: '#E5E7EB',
};

export const GREY400 = {
  class: 'gray-400',
  hex: '#9CA3AF',
};

export const GREY500 = {
  class: 'gray-500',
  hex: '#6B7280',
};

export const GREY600 = {
  class: 'gray-600',
  hex: '#4B5563',
};

export const GREY700 = {
  class: 'gray-700',
  hex: '#374151',
};

export const GREY800 = {
  class: 'gray-500',
  hex: '#1F2A37',
};

export const GREY900 = {
  class: 'gray-900',
  hex: '#111928',
};

export const BLUE100 = {
  class: 'blue-100',
  hex: '#E1EFFE',
};

export const BLUE400 = {
  class: 'blue-400',
  hex: '#76A9FA',
};

export const BLUE600 = {
  class: 'blue-600',
  hex: '#1C64F2',
};

export const BLUE700 = {
  class: 'blue-900',
  hex: '#233876',
};

export const BLUE900 = {
  class: 'blue-900',
  hex: '#233876',
};

export const GREEN100 = {
  class: 'green-100',
  hex: '#DEF7EC',
};

export const GREEN300 = {
  class: 'green-300',
  hex: '#84E1BC',
};

export const GREEN400 = {
  class: 'green-400',
  hex: '#31C48D',
};

export const GREEN500 = {
  class: 'green-500',
  hex: '#0E9F6E',
};

export const GREEN800 = {
  class: 'green-800',
  hex: '#03543F',
};

export const RED100 = {
  class: 'red-100',
  hex: '#FDE8E8',
};

export const RED200 = {
  class: 'red-200',
  hex: '#FBD5D5',
};

export const RED300 = {
  class: 'red-300',
  hex: '#F8B4B4',
};

export const RED400 = {
  class: 'red-400',
  hex: '#F98080',
};

export const RED600 = {
  class: 'red-600',
  hex: '#E02424',
};

export const RED700 = {
  class: 'red-700',
  hex: '#C81E1E',
};

export const YELLOW100 = {
  class: 'yellow-100',
  hex: '#FDF6B2',
};

export const YELLOW300 = {
  class: 'yellow-300',
  hex: '#FACA15',
};

export const YELLOW500 = {
  class: 'yellow-500',
  hex: '#C27803',
};

export const INDIGO200 = {
  class: 'indigo-200',
  hex: '#CDDBFE',
};

export const INDIGO400 = {
  class: 'indigo-400',
  hex: '#8DA2FB',
};

export const INDIGO700 = {
  class: 'indigo-700',
  hex: '#5145CD',
};

export const PINK200 = {
  class: 'pink-200',
  hex: '#FAD1E8',
};

export const PINK300 = {
  class: 'pink-300',
  hex: '#F8B4D9',
};

export const PINK500 = {
  class: 'pink-500',
  hex: '#E74694',
};

export const TEAL100 = {
  class: 'teal-100',
  hex: '#D5F5F6',
};

export const TEAL400 = {
  class: 'teal-400',
  hex: '#16BDCA',
};

export const TEAL500 = {
  class: 'teal-500',
  hex: '#0694A2',
};

export const TEAL700 = {
  class: 'teal-700',
  hex: '#036672',
};