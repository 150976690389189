import React from 'react';
import { withTheme } from 'styled-components';

const SandwhichIcon = withTheme(() => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="23" height="4" fill="white" />
      <rect y="8" width="23" height="4" fill="white" />
      <path d="M0 16H23V20H0V16Z" fill="white" />
    </svg>
  );
});

export { SandwhichIcon };
