import React from 'react';
import { PoliceOfficerIcon } from 'components/icons';
import { Body2, Spacer } from 'components';

const ErrorBoundaryForbidden = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Body2>
        <strong>Access is Denied</strong>
      </Body2>
      <Spacer size={35} />
      <PoliceOfficerIcon width="100%" />
      <Spacer size={35} />
      <Body2>
        <strong>You do not have the required privileges.</strong>
      </Body2>
      <Body2>
        <strong>Please contact your Admin</strong>
      </Body2>
    </div>
  );
};

export { ErrorBoundaryForbidden };
