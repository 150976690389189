import React from 'react';
import styled from 'styled-components';

const Content = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) =>
    props.color || props.theme.surface.slot1.text.hex};
  white-space: nowrap;
`;

const Body1 = ({ children, color }) => (
  <Content color={color}>{children}</Content>
);

export { Body1 };
