import { useMemo, useState, useCallback } from 'react';
import {
  DataTable,
  PageCounter,
  DeleteMirrorModal,
  CreateSubdomainModal,
  DeleteSubdomainModal,
  EditIcon,
  RenameMirrorModal,
  InfoIcon,
  Spacer,
} from 'components';
import { Pagination, Badge, Tooltip } from 'flowbite-react';
import { Tooltip as ReactToolTip } from 'react-tooltip';
import { withTheme } from 'styled-components';
import { Button } from 'flowbite-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SkeletonTable } from './SkeletonTable';
import { hasActiveProductKey } from 'utils/helpers';
import { useUserProfileQuery } from 'api/dlaas/user';
import { WorldIcon } from 'components/icons/WorldIcon';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

/**
 * Converts bytes to megabytes and gigabytes.
 * @param {number} bytes - The number of bytes.
 * @returns {object} An object containing the size in megabytes and gigabytes.
 */
function convertBytes(bytes) {
  const bytesInMegabyte = 1024 * 1024; // 1 MB = 1024 * 1024 bytes
  const bytesInGigabyte = 1024 * 1024 * 1024; // 1 GB = 1024 * 1024 * 1024 bytes

  const megabytes = bytes / bytesInMegabyte;
  const gigabytes = bytes / bytesInGigabyte;

  return {
    megabytes: megabytes,
    gigabytes: gigabytes,
  };
}

function renderSyncBadge(row) {
  if (row?.is_synced) {
    return (
      <Tooltip content="This store is synced with datalayer.storage">
        <Badge
          style={{
            width: 60,
            display: 'flex',
            justifyContent: 'center',
          }}
          color="success"
        >
          Synced
        </Badge>
      </Tooltip>
    );
  } else if (row?.waiting_for_files) {
    return (
      <Tooltip content="This store is waiting for files to be retreived from other mirrors. If this status never goes away its possible that there are no reachable mirrors to retreive the files from.">
        <Badge
          style={{
            width: 100,
            display: 'flex',
            justifyContent: 'center',
          }}
          color="purple"
        >
          Waiting for files
        </Badge>
      </Tooltip>
    );
  } else if (!row?.detected_onchain) {
    return (
      <Tooltip content="We havnt detected your store on chain yet. Normally this is temporary. If not it means that this store might not exist.">
        <Badge
          style={{
            width: 110,
            display: 'flex',
            justifyContent: 'center',
          }}
          color="purple"
        >
          Detecting Store...
        </Badge>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content="This store is currently syncing. Data shown may not be up to date">
        <Badge
          style={{
            width: 80,
            display: 'flex',
            justifyContent: 'center',
          }}
          color="warning"
        >
          Not synced
        </Badge>
      </Tooltip>
    );
  }
}

const UserMirrorsTable = withTheme(
  ({
    data,
    isLoading,
    currentPage,
    onPageChange,
    totalPages,
    totalCount,
    theme,
  }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [createSubdomainRow, setCreateSubdomainRow] = useState(null);
    const [deleteSubdomainRow, setDeleteSubdomainRow] = useState(null);
    const [renameMirrorRow, setRenameMirrorRow] = useState(null);
    const [hoverRow, setHoverRow] = useState(null);

    const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();

    const handleCreateSubdomain = useCallback(
      async (row) => {
        if (!user) return;

        if (!hasActiveProductKey(user?.subscriptions)) {
          navigate('/account');
          return;
        }

        setCreateSubdomainRow(row);
      },
      [navigate, setCreateSubdomainRow, user],
    );

    const columns = useMemo(() => [
      {
        title: 'Label',
        key: 'name',
        render: (row) => {
          return (
            <div>
              <a
                onMouseOver={() => setHoverRow(row)}
                onMouseLeave={() => setHoverRow(null)}
                style={{ display: 'flex', cursor: 'pointer' }}
                onClick={() => setRenameMirrorRow(row)}
              >
                {row.name}
                {hoverRow?.singleton_id === row.singleton_id ? (
                  <EditIcon width={18} height={18} />
                ) : (
                  <div style={{ width: 18, height: 18 }} />
                )}
              </a>
              {row.verified_mirror_id && (
                <div>
                  <Spacer size={5} />
                  <Tooltip
                    content={
                      'The original publisher of the data for this store has been identified and verified. If you think this was a mistake please contact support.'
                    }
                  >
                    <Badge
                      style={{
                        width: 60,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      color="success"
                    >
                      Verified
                    </Badge>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Store Info',
        key: 'singleton_id',
        render: (row) => {
          const { megabytes, gigabytes } = convertBytes(row.size);
          return (
            <div>
              <div>Store ID: {row.singleton_id}</div>
              <div>
                Web2 Url:{' '}
                <a
                  href={
                    row?.subdomain
                      ? `https://${row?.subdomain}.datalayer.link`
                      : `https://datalayer.link/${row.singleton_id}`
                  }
                  target="_blank"
                >
                  {row?.subdomain &&
                  !['PENDING', 'FAILED', 'DELETING'].includes(row?.subdomain)
                    ? `https://${row?.subdomain}.datalayer.link`
                    : `https://datalayer.link/${row?.singleton_id}`}
                </a>
              </div>
              <Spacer size={10} />
              <div>
                <Tooltip
                  content={`https://cdn.datalayer.storage/${row.singleton_id}/${row.salt}`}
                >
                  <a
                    href={`https://cdn.datalayer.storage/${row.singleton_id}/${row.salt}`}
                    target="_blank"
                  >
                    Mirror Location
                  </a>
                </Tooltip>
              </div>
              <Tooltip
                content={`${megabytes.toFixed(2)} MB, ${gigabytes.toFixed(
                  2,
                )} GB`}
              >
                <div>Size: {row.size || 0} bytes</div>
              </Tooltip>
              <Tooltip
                content={
                  'Generations are the number of times the store has been updated.'
                }
              >
                Generations: {row.generation_count || 0}
              </Tooltip>
              <div style={{ display: 'flex' }}>
                Sync Status: {renderSyncBadge(row)}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Mirror Count',
        key: 'global_mirror_count',
        render: (row) => {
          if (_.isNil(row.global_mirror_count)) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  color="warning"
                >
                  Pending
                </Badge>
              </div>
            );
          }

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <a
                style={{
                  border: 'solid thin #e5e7eb',
                  width: 50,
                  padding: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  background: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                href={`https://datalayer.link/${row?.singleton_id}/mirrors.json`}
                target="_blank"
              >
                {row.global_mirror_count}
              </a>
              <div style={{ padding: 5 }} />
              <a
                href={`https://datalayer.link/${row?.singleton_id}/mirrors.html`}
                target="_blank"
                title="View Mirror Map"
              >
                <WorldIcon />
              </a>
            </div>
          );
        },
      },
      {
        title: 'Subdomain',
        key: 'subdomain',
        renderHeader: () => {
          return (
            <div
              data-tooltip-id="static-webhost-description"
              data-tooltip-content={intl.formatMessage({
                id: 'static-webhost-description',
              })}
              data-tooltip-place="top"
              style={{ display: 'flex' }}
            >
              Static Web Host
              <div style={{ marginLeft: 5 }}>
                <InfoIcon />
              </div>
              <ReactToolTip
                style={{ width: 300, textTransform: 'none' }}
                id="static-webhost-description"
              />
            </div>
          );
        },
        render: (row) => {
          if (row?.subdomain) {
            if (['PENDING', 'FAILED', 'DELETING'].includes(row?.subdomain)) {
              switch (row?.subdomain) {
                case 'PENDING':
                  return (
                    <Badge
                      style={{
                        width: 75,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      color="warning"
                    >
                      Pending
                    </Badge>
                  );
                case 'FAILED':
                  return (
                    <Button
                      onClick={() => handleCreateSubdomain(row)}
                      color="gray"
                    >
                      Failed
                    </Button>
                  );
                case 'DELETING':
                  return (
                    <Badge
                      style={{
                        width: 75,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      color="indigo"
                    >
                      DELETING
                    </Badge>
                  );
              }
            } else {
              return (
                <Button
                  onClick={() => setDeleteSubdomainRow(row)}
                  color="failure"
                >
                  <FormattedMessage id="delete" />
                </Button>
              );
            }
          } else {
            return (
              <Button onClick={() => handleCreateSubdomain(row)} color="gray">
                <FormattedMessage id="create" />
              </Button>
            );
          }
        },
      },
      {
        title: 'Mirror Status',
        key: 'status',
        render: (row) => {
          switch (row.status) {
            case 'pending':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="warning"
                >
                  Pending
                </Badge>
              );
            case 'active':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="success"
                >
                  Active
                </Badge>
              );
            case 'failed':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="failure"
                >
                  Failed
                </Badge>
              );
            case 'deleting':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="failure"
                >
                  Deleting
                </Badge>
              );
            default:
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="failure"
                >
                  Unknown
                </Badge>
              );
          }
        },
      },
      {
        title: 'delete',
        key: 'delete',
        render: (row) => {
          return (
            <Tooltip
              content={
                row.name === 'Generated By File Upload'
                  ? 'Can not delete stores generated by file upload'
                  : 'Click to delete'
              }
            >
              <Button
                disabled={row.name === 'Generated By File Upload'}
                onClick={() => setSelectedRow(row)}
              >
                <FormattedMessage id="delete" />
              </Button>
            </Tooltip>
          );
        },
      },
    ]);

    if (isLoading || userDataIsLoading) {
      return <SkeletonTable />;
    }

    return (
      <div className="relative" style={{ height: 'calc(100% - 162px)' }}>
        <DataTable columns={columns} data={data} isLoading={isLoading} />
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 20,
              position: 'sticky',
              bottom: -1,
              background: theme.surface.slot6.background.hex,
            }}
          >
            {totalCount > 0 && (
              <>
                <PageCounter
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalCount={totalCount}
                />
                <Pagination
                  currentPage={currentPage}
                  layout="pagination"
                  onPageChange={onPageChange}
                  showIcons={true}
                  totalPages={totalPages || 1}
                />
              </>
            )}
          </div>
        )}
        {selectedRow && (
          <DeleteMirrorModal
            storeId={selectedRow.singleton_id}
            label={selectedRow.name}
            onClose={() => setSelectedRow(null)}
          />
        )}
        {createSubdomainRow && (
          <CreateSubdomainModal
            mirror={createSubdomainRow}
            onClose={() => setCreateSubdomainRow(null)}
          />
        )}
        {deleteSubdomainRow && (
          <DeleteSubdomainModal
            subdomain={deleteSubdomainRow?.subdomain}
            onClose={() => setDeleteSubdomainRow(null)}
          />
        )}
        {renameMirrorRow && (
          <RenameMirrorModal
            storeId={renameMirrorRow?.singleton_id}
            onClose={() => setRenameMirrorRow(null)}
          />
        )}
      </div>
    );
  },
);

export { UserMirrorsTable };
