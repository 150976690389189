import _ from 'lodash';
import React from 'react';
import { Modal } from 'flowbite-react';
import { Spacer } from 'components';
import { FormattedMessage } from 'react-intl';

const NewAccessKeyModal = ({ accessKey, secretAccessKey, onClose = _.noop }) => {
  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="new-access-key" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <FormattedMessage id="new-access-key-message" />
        <Spacer size={20} />
        <div>
          <FormattedMessage id="access-key" />: <b>{accessKey}</b>
        </div>
        <div>
          <FormattedMessage id="secret-access-key" />: <b>{secretAccessKey}</b>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { NewAccessKeyModal };
