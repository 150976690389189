import React, { useEffect, useState, useMemo } from 'react';
import { Flowbite } from 'flowbite-react';
import { IntlProvider } from 'react-intl';
import { loadLocaleData } from 'translations';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from 'store/slices/app/app.slice';
import { AppNavigator } from 'routes';
import { IndeterminateProgressOverlay } from 'components';
import flowbiteThemeSettings from './flowbite.theme';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.app);
  const { selectedTheme } = useSelector((state) => state.userOptions);
  const [translationTokens, setTranslationTokens] = useState();

  useEffect(() => {
    if (appStore.locale) {
      const processTranslationTokens = async () => {
        setTranslationTokens(await loadLocaleData(appStore.locale));
      };

      processTranslationTokens();
    } else {
      dispatch(setLocale(navigator.language));
    }
  }, [appStore.locale]);

  const selectedThemeButForceLightOnUnAuthenticated = useMemo(() => {
    return !appStore.auth?.accessToken ? 'light' : selectedTheme;
  }, [appStore.auth?.accessToken, selectedTheme]);

  const themeSettings = useMemo(() => {
    return flowbiteThemeSettings(
      theme.colors[selectedThemeButForceLightOnUnAuthenticated],
    );
  }, [selectedThemeButForceLightOnUnAuthenticated, theme, flowbiteThemeSettings]);

  if (!translationTokens) {
    return <IndeterminateProgressOverlay />;
  }

  return (
    <div
      style={{ height: '100%', overflowY: appStore.auth?.accessToken ? 'hidden' : 'scroll' }}
      className={`bg-${theme.colors[selectedThemeButForceLightOnUnAuthenticated].surface.slot6.background.class}`}
    >
      <Flowbite theme={themeSettings}>
        <ThemeProvider
          theme={theme.colors[selectedThemeButForceLightOnUnAuthenticated]}
        >
          <IntlProvider
            locale="en"
            defaultLocale="en"
            messages={translationTokens.default}
          >
            <Elements stripe={stripePromise}>
              <AppNavigator />
            </Elements>
          </IntlProvider>
        </ThemeProvider>
      </Flowbite>
    </div>
  );
};

export default App;
