import { Timeline, Button } from 'flowbite-react';
import { PageTitle } from 'components';
import Step1 from 'assets/images/step1.png';
import Step2 from 'assets/images/step2.png';
import { useNavigate } from 'react-router-dom';

const HowTo = () => {
  const navigate = useNavigate();
  return (
    <div style={{ margin: 25 }}>
      <PageTitle title="4 Easy steps to automate your data pipeline." />
      <h4>
        Follow these steps if you are trying to upload data using the DataLayer Storage Upload plugin and you are an active subscriber to the Enterprise plan. If you are not an active subscriber you can still get a lot out of the free teir by setting up your local machine for datalayer mirroring. <a href="/prerequisites">Click here to learn more.</a>
      </h4>
      <br />
      <Timeline>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>Step 1</Timeline.Time>
            <Timeline.Title>Create your Access Key</Timeline.Title>
            <Timeline.Body>
              Be sure to record the secret key in a safe place. You will not be
              able to retrieve it later.
              <img src={Step1} alt="Step 1" width="900px" />
            </Timeline.Body>
            <Button color="gray" onClick={() => navigate('/access-keys')}>
              <p>Create your Access Key</p>
            </Button>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>Step 2</Timeline.Time>
            <Timeline.Title>
              Download and Install the DataLayer Storage Upload Plugin
            </Timeline.Title>
            <Timeline.Body>
              Insert you Access Key and Secret Key into the plugin settings.
              <img src={Step2} alt="Step 2" />
            </Timeline.Body>
            <Button color="gray" onClick={() => navigate('/downloads')}>
              <p>Download Here</p>
            </Button>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>Step 3</Timeline.Time>
            <Timeline.Title>Restart Your Computer</Timeline.Title>
            <Timeline.Body>
              Some changes require a restart to take effect.
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>Step 4</Timeline.Time>
            <Timeline.Title>Use Chia DataLayer</Timeline.Title>
            <Timeline.Body>
              The DataLayer plugin will automatically push your DataLayer files
              to the DataLayer Storage Cloud as you work. You can view your
              files in the My Files section of the DataLayer website. All your
              data is automatically mirrored on the Chia Blockchain. All you
              need to do is use the DataLayer as normal. Everything else is
              automatic.
              <br />
              <br />
              Dont forget, if you need any XCH to get started using Chia
              DataLayer, subscription customers can request XCH from the
              Blockchain Access section of the DataLayer website.
            </Timeline.Body>
            <Button color="gray" onClick={() => navigate('/blockchain-access')}>
              <p>Get some XCH</p>
            </Button>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export { HowTo };
