import React from 'react';
import { withTheme } from 'styled-components';

const ArrowCircleUpIcon = withTheme(({ color, width = 27, height = 28 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9999 20.1C12.546 20.1 14.9878 19.0886 16.7881 17.2883C18.5885 15.4879 19.5999 13.0461 19.5999 10.5C19.5999 7.95395 18.5885 5.51215 16.7881 3.7118C14.9878 1.91145 12.546 0.900024 9.9999 0.900024C7.45382 0.900024 5.01203 1.91145 3.21168 3.7118C1.41133 5.51215 0.399902 7.95395 0.399902 10.5C0.399902 13.0461 1.41133 15.4879 3.21168 17.2883C5.01203 19.0886 7.45382 20.1 9.9999 20.1V20.1ZM14.4483 9.65163L10.8483 6.05162C10.6233 5.82666 10.3181 5.70028 9.9999 5.70028C9.68171 5.70028 9.37654 5.82666 9.1515 6.05162L5.5515 9.65163C5.33291 9.87795 5.21196 10.1811 5.21469 10.4957C5.21743 10.8103 5.34363 11.1113 5.56612 11.3338C5.78861 11.5563 6.08958 11.6825 6.40422 11.6852C6.71886 11.688 7.02198 11.567 7.2483 11.3484L8.7999 9.79682V14.1C8.7999 14.4183 8.92633 14.7235 9.15137 14.9486C9.37642 15.1736 9.68164 15.3 9.9999 15.3C10.3182 15.3 10.6234 15.1736 10.8484 14.9486C11.0735 14.7235 11.1999 14.4183 11.1999 14.1V9.79682L12.7515 11.3484C12.9778 11.567 13.2809 11.688 13.5956 11.6852C13.9102 11.6825 14.2112 11.5563 14.4337 11.3338C14.6562 11.1113 14.7824 10.8103 14.7851 10.4957C14.7878 10.1811 14.6669 9.87795 14.4483 9.65163V9.65163Z"
        fill={color}
      />
    </svg>
  );
});

export { ArrowCircleUpIcon };
