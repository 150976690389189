import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { withTheme } from 'styled-components';
import { useSendSalesInquiryMutation } from 'api/dlaas/system';
import { MessageReceivedModal, SomethingWentWrong } from 'components';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  message: '',
};

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
  firstname: yup.string().required('First name is required.'),
  lastname: yup.string().required('Last name is required.'),
  company: yup.string().optional(),
  message: yup.string().required('Message is required.'),
});

const ContactForm = withTheme(() => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [triggerSendSalesInquiry, { error }] = useSendSalesInquiryMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await triggerSendSalesInquiry(values);
      setSubmitted(true);
    },
  });

  if (error) {
    return <SomethingWentWrong onClose={() => navigate('/')} />;
  }

  if (submitted) {
    return <MessageReceivedModal onClose={() => navigate('/')} />;
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mx-auto mt-16 max-w-xl sm:mt-20"
    >
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="firstname"
            className="block text-sm font-semibold leading-6 text-white"
          >
            First name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="firstname"
              id="firstname"
              onChange={formik.handleChange}
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            {formik.errors.firstname ? (
              <div className="text-sm text-red-500">
                {formik.errors.firstname}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <label
            htmlFor="lastname"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Last name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="lastname"
              id="lastname"
              onChange={formik.handleChange}
              autoComplete="family-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            {formik.errors.lastname ? (
              <div className="text-sm text-red-500">
                {formik.errors.lastname}
              </div>
            ) : null}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="company"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Company
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="company"
              id="company"
              onChange={formik.handleChange}
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Email
          </label>
          <div className="mt-2.5">
            <input
              type="email"
              name="email"
              id="email"
              onChange={formik.handleChange}
              autoComplete="email"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            {formik.errors.email ? (
              <div className="text-sm text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Message
          </label>
          <div className="mt-2.5">
            <textarea
              name="message"
              id="message"
              onChange={formik.handleChange}
              rows="4"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            ></textarea>
            {formik.errors.message ? (
              <div className="text-sm text-red-500">
                {formik.errors.message}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <button
          type="submit"
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Let's talk
        </button>
      </div>
    </form>
  );
});

export { ContactForm };
