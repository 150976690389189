import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  UserMirrorsTable,
  Container,
  PageTitle,
  CreateNewMirrorModal,
  SubscriptionLandingModal,
  CheckMirrorConnectionModal
} from 'components';
import { useUserMirrorsQuery } from 'api/dlaas/mirror';
import { useQueryParamState } from 'hooks';
import { Button, Spinner, Alert } from 'flowbite-react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowMyMirrorsAlert } from 'store/slices/userOptions/userOptions.slice';
import { hasActiveProductKey } from 'utils/helpers';
import { useUserProfileQuery } from 'api/dlaas/user';

const MyMirrorsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userOptionsStore = useSelector((state) => state.userOptions);
  const [currentPage, setCurrentPage] = useQueryParamState('page', 1);
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const { data, isLoading, isFetching } = useUserMirrorsQuery(
    { page: currentPage },
    { pollingInterval: 60000 },
  );
  const [showSpinner] = useState(false);
  const [showCreateMirrorModal, setShowCreateMirrorModal] = useState(false);
  const [showCheckMirrorConnectionModal, setShowCheckMirrorConnectionModal] = useState(false);
  const [showSubscriptionLandingModal, setShowSubscriptionLandingModal] =
    useState(false);

  const handlePageChange = useCallback(
    _.debounce((page) => setCurrentPage(page), 800),
    [setCurrentPage],
  );

  const handleDismissAlert = useCallback(() => {
    dispatch(toggleShowMyMirrorsAlert());
  }, [dispatch]);

  return (
    <>
      <div style={{ margin: 5 }}>
        <Alert color="yellow">
          We are pleased to announce that DataLayerLink now offers operational
          support for large file handling when used in conjunction with the Sprout Tool. 
          However, please note that this feature is still in an experimental phase. Our team has been
          diligently working on enhancing this functionality to ensure better
          performance and reliability. While large file support is currently
          available, files exceeding 25 MB in size may experience variable
          results as we continue to refine this feature. We are committed to
          evolving and optimizing this service, and we will keep you updated on
          further developments. We appreciate your understanding and patience
          during this experimental phase. Thank you for your continued support.
        </Alert>
      </div>
      <PageTitle title={intl.formatMessage({ id: 'my-mirrors' })} />
      <Container height="unset">
        {userOptionsStore.showMyMirrorsAlert && (
          <div style={{ margin: 25 }}>
            <Alert color="info" onDismiss={handleDismissAlert}>
              <p>
                Chia DataLayer enables peer-to-peer data sharing. A 'mirror' is
                a storage location for data access. Data remains available as
                long as there's one peer actively distributing it and it can be
                accessed via a mirror. Yet, if all peers stop sharing the data,
                there's a risk of permanent data loss.
              </p>
              <br />
              <p>
                To counter this, we recommend using mirrors. Mirrors boost the
                resilience and durability of crucial data, providing an extra
                reliability layer. With an active mirror, you can rest assured
                your valuable data will stay accessible, regardless of changes
                in peer sharing.
              </p>
            </Alert>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 50,
            marginBottom: 20,
          }}
        >
          <Button
            color="light"
            onClick={() => setShowCheckMirrorConnectionModal(true)}
          >
            Check Local File Propagation Server Availability
          </Button>
          <span style={{ width: 10 }}></span>
          <Button
            style={{ width: 75 }}
            onClick={() => {
              if (!isFetching && !showSpinner && !userDataIsLoading) {
                if (
                  user?.subscriptions.length === 0 &&
                  data?.mirrors.length >= 10
                ) {
                  setShowSubscriptionLandingModal(true);
                } else {
                  setShowCreateMirrorModal(true);
                }
              }
            }}
          >
            {isFetching || showSpinner || userDataIsLoading ? (
              <Spinner size="sm" />
            ) : (
              'Create'
            )}
          </Button>
        </div>

        {!userDataIsLoading && (
          <div>
            {data?.mirrors.length <= 10 ? (
              <span>
                You are current using {data?.mirrors.length} of 10 mirrors
                included in the free teir.
              </span>
            ) : (
              <span>
                You are current using 10 of 10 mirrors included in the free
                teir.{' '}
                {!hasActiveProductKey(user?.subscriptions) && (
                  <span style={{ color: 'red' }}>
                    {' '}
                    Please upgrade to the Enterprise plan to use more
                  </span>
                )}
              </span>
            )}
          </div>
        )}

        <UserMirrorsTable
          data={data?.mirrors}
          isLoading={isLoading}
          currentPage={Number(currentPage)}
          onPageChange={handlePageChange}
          totalPages={data?.total_pages}
          totalCount={data?.total_count}
        />
      </Container>
      {showCreateMirrorModal && (
        <CreateNewMirrorModal onClose={() => setShowCreateMirrorModal(false)} />
      )}
      {showSubscriptionLandingModal && (
        <SubscriptionLandingModal
          onClose={() => setShowSubscriptionLandingModal(false)}
        />
      )}
      {showCheckMirrorConnectionModal && (
        <CheckMirrorConnectionModal
          onClose={() => setShowCheckMirrorConnectionModal(false)}
        />
      )}
    </>
  );
};

export { MyMirrorsPage };
