import React from 'react';

const LogoIcon = ({ width = 227, height = 78, color="white" }) => {
  return (
    <div style={{ minWidth: 227 }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 227 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.11 46.87H38.29V77.62H41.96V46.87H54.14V43.77H26.11V46.87Z"
          fill={color}
        />
        <path
          d="M20.19 61.06C18.62 60.46 16.57 59.86 14.02 59.25C11.88 58.73 10.16 58.26 8.9 57.82C7.63 57.39 6.57 56.76 5.73 55.93C4.89 55.11 4.47 54.02 4.47 52.67C4.47 50.87 5.24 49.39 6.77 48.25C8.31 47.11 10.59 46.53 13.63 46.53C15.21 46.53 16.83 46.76 18.48 47.23C20.13 47.7 21.68 48.4 23.14 49.33L24.37 46.48C22.99 45.55 21.34 44.81 19.44 44.28C17.54 43.75 15.6 43.48 13.62 43.48C10.78 43.48 8.4 43.9 6.49 44.74C4.57 45.58 3.15 46.7 2.23 48.1C1.3 49.5 0.84 51.06 0.84 52.77C0.84 54.87 1.39 56.52 2.5 57.75C3.61 58.98 4.93 59.89 6.49 60.48C8.04 61.08 10.12 61.7 12.73 62.34C14.91 62.86 16.61 63.33 17.83 63.77C19.05 64.2 20.08 64.83 20.92 65.63C21.76 66.44 22.18 67.52 22.18 68.87C22.18 70.64 21.41 72.08 19.85 73.2C18.3 74.31 15.97 74.87 12.87 74.87C10.66 74.87 8.51 74.49 6.43 73.73C4.35 72.97 2.68 71.98 1.43 70.76L0 73.52C1.29 74.84 3.11 75.9 5.47 76.71C7.83 77.52 10.3 77.92 12.88 77.92C15.72 77.92 18.11 77.51 20.06 76.69C22.01 75.87 23.45 74.76 24.39 73.35C25.33 71.95 25.8 70.39 25.8 68.68C25.8 66.62 25.26 64.98 24.17 63.77C23.09 62.56 21.76 61.65 20.19 61.06Z"
          fill={color}
        />
        <path
          d="M141.96 43.77L126.11 77.62H129.93L134.09 68.58H153.4L157.56 77.62H161.42L145.57 43.78H141.96V43.77ZM135.42 65.67L143.74 47.49L152.06 65.67H135.42Z"
          fill={color}
        />
        <path
          d="M82.2 45.71C79.44 44.23 76.35 43.49 72.91 43.49C69.48 43.49 66.36 44.24 63.57 45.74C60.78 47.24 58.6 49.29 57.03 51.9C55.46 54.51 54.68 57.44 54.68 60.7C54.68 63.96 55.47 66.89 57.03 69.5C58.6 72.11 60.78 74.17 63.57 75.66C66.36 77.16 69.47 77.91 72.91 77.91C76.35 77.91 79.44 77.17 82.2 75.69C84.96 74.21 87.13 72.15 88.71 69.53C90.29 66.9 91.09 63.96 91.09 60.71C91.09 57.45 90.3 54.51 88.71 51.89C87.13 49.24 84.96 47.19 82.2 45.71ZM85.54 67.87C84.28 70.01 82.55 71.69 80.34 72.9C78.13 74.11 75.65 74.71 72.91 74.71C70.17 74.71 67.69 74.11 65.46 72.9C63.23 71.69 61.48 70.02 60.21 67.87C58.94 65.73 58.3 63.33 58.3 60.69C58.3 58.05 58.94 55.65 60.21 53.51C61.48 51.37 63.23 49.69 65.46 48.48C67.69 47.27 70.17 46.67 72.91 46.67C75.65 46.67 78.13 47.27 80.34 48.48C82.55 49.69 84.28 51.36 85.54 53.51C86.8 55.65 87.42 58.05 87.42 60.69C87.43 63.34 86.8 65.73 85.54 67.87Z"
          fill={color}
        />
        <path
          d="M172.74 48.49C175 47.28 177.53 46.68 180.34 46.68C184.57 46.68 188.05 48 190.79 50.64L193.07 48.37C191.55 46.76 189.69 45.54 187.47 44.72C185.26 43.9 182.83 43.49 180.19 43.49C176.69 43.49 173.55 44.23 170.78 45.71C168.01 47.19 165.83 49.25 164.24 51.87C162.66 54.5 161.86 57.44 161.86 60.69C161.86 63.95 162.65 66.89 164.24 69.51C165.82 72.14 168.01 74.19 170.78 75.67C173.55 77.15 176.67 77.89 180.14 77.89C182.58 77.89 184.91 77.52 187.12 76.78C189.34 76.04 191.25 74.97 192.87 73.59V60.69H189.35V72.1C186.88 73.84 183.86 74.71 180.29 74.71C177.49 74.71 174.96 74.1 172.71 72.87C170.46 71.64 168.69 69.97 167.41 67.84C166.12 65.71 165.48 63.33 165.48 60.68C165.48 58.04 166.12 55.64 167.41 53.5C168.7 51.37 170.48 49.7 172.74 48.49Z"
          fill={color}
        />
        <path
          d="M205.16 61.95H223.08V58.91H205.16V46.87H225.26V43.77H201.49V77.62H226V74.52H205.16V61.95Z"
          fill={color}
        />
        <path
          d="M122.66 62.07C124.03 60.25 124.72 58.02 124.72 55.37C124.72 51.76 123.46 48.92 120.95 46.86C118.44 44.8 114.97 43.77 110.55 43.77H97.57V77.61H101.24V66.88H110.55C111.51 66.88 112.43 66.83 113.32 66.73L121.25 77.61H125.26L116.79 66.01C119.34 65.21 121.3 63.89 122.66 62.07ZM110.46 63.84H101.25V46.87H110.46C113.89 46.87 116.52 47.6 118.34 49.07C120.16 50.54 121.06 52.64 121.06 55.38C121.06 58.06 120.15 60.13 118.34 61.62C116.52 63.1 113.89 63.84 110.46 63.84Z"
          fill={color}
        />
        <path
          d="M208.01 29.87H210.66L215.59 37.12H226.02L219.94 28.23C221.7 27.32 223.06 26.1 223.99 24.57C224.93 23.03 225.4 21.23 225.4 19.18C225.4 16.98 224.86 15.06 223.79 13.42C222.71 11.77 221.18 10.51 219.2 9.63C217.21 8.75 214.88 8.31 212.2 8.31H201.01V18.58H198.24V26.86H201.2V37.13H208.01V29.87ZM208.01 15.79H211.57C212.89 15.79 213.89 16.09 214.55 16.68C215.21 17.27 215.54 18.1 215.54 19.17C215.54 20.24 215.21 21.07 214.55 21.66C213.89 22.25 212.89 22.55 211.57 22.55H208.01V15.79Z"
          fill={color}
        />
        <path
          d="M185.88 26H194.58V19H185.88V15.63H198.05V8.3H186.36L176.27 25.24V37.12H198.23V29.79H185.88V26Z"
          fill={color}
        />
        <path
          d="M162.23 33.66L163.75 37.12H171.99V26.66L182.92 8.3H173.48L167.52 18.26L161.61 8.3H151.3L162.23 26.54V33.66Z"
          fill={color}
        />
        <path
          d="M135.44 37.12H135.52L137.46 32.1H148.47L150.42 37.12H160.52L147.85 8.3H138.25L130.19 26.65H135.45V37.12H135.44ZM142.97 17.85L145.78 25.1H140.15L142.97 17.85Z"
          fill={color}
        />
        <path
          d="M132.49 37.12V29.59H119.41V8.3H109.64V25.28L114.84 37.12H132.49Z"
          fill={color}
        />
        <path
          d="M80.32 28.85V37.13H86.62L88.57 32.11H99.58L101.52 37.13H111.62L98.95 8.31H89.35L88.81 9.55V18.79H84.75L80.32 28.85ZM94.06 17.85L96.88 25.1H91.25L94.06 17.85Z"
          fill={color}
        />
        <path
          d="M33.41 37.12H43.28L45.23 32.1H56.24L58.19 37.12H77.38V15.83H85.86V8.29H59.11V15.82H67.6V35.56L55.61 8.3H46.01L43.26 14.56C43.46 15.84 43.57 17.17 43.57 18.56C43.57 23.1 42.48 27.03 40.37 30.38C38.61 33.14 36.3 35.4 33.41 37.12ZM50.72 17.85L53.53 25.1H47.91L50.72 17.85Z"
          fill={color}
        />
        <path
          d="M29.81 34.87C32.99 33.37 35.46 31.22 37.22 28.43C38.98 25.64 39.86 22.35 39.86 18.56C39.86 14.78 38.98 11.49 37.22 8.69C35.46 5.9 32.99 3.75 29.81 2.25C26.63 0.75 22.96 0 18.8 0H0.41V37.13H18.8C22.96 37.12 26.63 36.37 29.81 34.87ZM12.99 9.75H18.27C20.97 9.75 23.13 10.52 24.75 12.06C26.37 13.6 27.18 15.76 27.18 18.56C27.18 21.35 26.37 23.52 24.75 25.06C23.13 26.6 20.97 27.37 18.27 27.37H12.99V9.75Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export { LogoIcon };
