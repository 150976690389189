import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useConfirmNewPasswordMutation } from 'api/dlaas/user';
import { Container, Spacer } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { useQueryParamState } from 'hooks';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ConfirmNewPasswordForm = withTheme(({ onClose, theme }) => {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [triggerConfirmPassword, { isLoading }] =
    useConfirmNewPasswordMutation();
  const [passwordResetCode] = useQueryParamState('code');

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(9, intl.formatMessage({ id: 'password-length' }))
      .required(
        `${intl.formatMessage({ id: 'password' })} ${intl.formatMessage({
          id: 'is-required',
        })}.`,
      ),
    confirmPassword: yup
      .string()
      .required(
        `${intl.formatMessage({
          id: 'confirm-password',
        })} ${intl.formatMessage({
          id: 'is-required',
        })}.`,
      )
      .oneOf(
        [yup.ref('password')],
        intl.formatMessage({ id: 'passwords-do-not-match' }),
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      triggerConfirmPassword({
        code: passwordResetCode,
        ...values,
      });
      setSubmitted(true);
    },
  });

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {submitted && !isLoading && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div className="mb-2 block text-base font-normal">
              <FormattedMessage id="processing-reset-password" />
            </div>
          </div>
        </div>
      )}

      {(!submitted || isLoading) && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div
              className={`mb-2 block text-base font-normal text-${theme.surface.slot1.text.class}`}
            >
              <FormattedMessage id="new-strong-password" />
              <Spacer size="10" />
            </div>
            <div>
              <TextInput
                id="password"
                type="password"
                placeholder={intl.formatMessage({ id: 'password' })}
                autoComplete="new-password"
                value={formik.values.password}
                required={true}
                onChange={formik.handleChange}
              />
              {formik.errors.password ? (
                <div>{formik.errors.password}</div>
              ) : null}
            </div>
            <Spacer size="10" />
            <div>
              <TextInput
                id="confirmPassword"
                type="password"
                placeholder={intl.formatMessage({ id: 'confirm-password' })}
                autoComplete="new-password"
                value={formik.values.confirmPassword}
                required={true}
                onChange={formik.handleChange}
              />
              {formik.errors.confirmPassword ? (
                <div>{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          {isLoading ? (
            <Spinner
              aria-label={intl.formatMessage({
                id: 'processing-password-reset-request',
              })}
              size="sm"
            />
          ) : (
            <FormattedMessage id={submitted ? 'ok' : 'submit'} />
          )}
        </Button>
      </Container>
    </form>
  );
});

export { ConfirmNewPasswordForm };
