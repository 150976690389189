import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  ClientAccessKeysTable,
  NewAccessKeyModal,
  Container,
  PageTitle,
} from 'components';
import {
  useClientAccessKeysQuery,
  useCreateClientAccessKeysMutation,
  useUserProfileQuery,
} from 'api/dlaas/user';
import { useQueryParamState } from 'hooks';
import { Button, Spinner, Alert } from 'flowbite-react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowAccessKeyAlert } from 'store/slices/userOptions/userOptions.slice';

const AccessKeysPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userOptionsStore = useSelector((state) => state.userOptions);
  const [currentPage, setCurrentPage] = useQueryParamState('page', 1);
  const { data, isLoading, isFetching } = useClientAccessKeysQuery({
    page: currentPage,
  });
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [triggerCreateClientAccessKeys] = useCreateClientAccessKeysMutation();
  const [showNewAccessKeyModal, setShowNewAccessKeyModal] = useState(false);
  const [newAccessKey, setNewAccessKey] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const handlePageChange = useCallback(
    _.debounce((page) => setCurrentPage(page), 800),
    [setCurrentPage],
  );

  const handleDismissAccessKeyAlert = useCallback(() => {
    dispatch(toggleShowAccessKeyAlert());
  }, [dispatch]);

  const handleCreateAccessKey = useCallback(async () => {
    if (!user) return;

    setShowSpinner(true);
    const results = await triggerCreateClientAccessKeys({ userId: user.id });

    setShowSpinner(false);

    if (results?.data?.access_key) {
      setShowNewAccessKeyModal(true);
      setNewAccessKey(results);
    }
  }, [
    setShowNewAccessKeyModal,
    setNewAccessKey,
    triggerCreateClientAccessKeys,
    user,
  ]);

  const handleCloseModal = useCallback(() => {
    setShowNewAccessKeyModal(false);
    setNewAccessKey(null);
  }, [setShowNewAccessKeyModal, setNewAccessKey]);

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'access-keys' })} />
      <Container height="unset">
        {userOptionsStore.showAccessKeyAlert && (
          <div style={{ margin: 25 }}>
            <Alert color="info" onDismiss={handleDismissAccessKeyAlert}>
              <p>
                Access keys provide a secure method for your Chia wallet to
                safely interact with DataLayer.storage. The first step in using
                Datalayer.storage is creating an access key.
        
                After generating an access key, you'll receive a public and
                secret key. It's crucial to securely record both the access key
                and its corresponding secret key.
      
                It's important to note that our system doesn't keep copies of
                the secret key; therefore, if you lose it, it cannot be
                recovered.
              </p>
              <br />
              <p>
                If a key is compromised or lost, you can quickly invalidate the
                access key on our platform, making it non-functional. While you
                can generate an unlimited number of keys, responsible management
                is vital. Be careful to protect these keys, ensuring they are
                not publicly exposed or shared indiscriminately. They are
                essential for the security of your system-to-system
                communications.
              </p>
            </Alert>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 50,
            marginBottom: 20,
          }}
        >
          <Button style={{ width: 75 }} onClick={handleCreateAccessKey}>
            {isFetching || showSpinner ? <Spinner size="sm" /> : 'Create'}
          </Button>
        </div>

        <ClientAccessKeysTable
          data={data?.access_keys}
          isLoading={isLoading || userDataIsLoading}
          currentPage={Number(currentPage)}
          onPageChange={handlePageChange}
          totalPages={data?.total_pages}
          totalCount={data?.total_count}
        />

        {showNewAccessKeyModal && (
          <NewAccessKeyModal
            accessKey={newAccessKey?.data?.access_key}
            secretAccessKey={newAccessKey?.data?.secret_access_key}
            onClose={handleCloseModal}
          />
        )}
      </Container>
    </>
  );
};

export { AccessKeysPage };
