import { useMemo, useState, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { Caption1 } from 'components';

const FocusableInput = styled('input')`
  position: relative;
  display: block;
  background: none;
  border: none;
  width: 100%;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding-left: 25px;
  text-align: left;
  &:focus,
  &:hover {
    background: ${(props) => props.theme.primary.slot1.background.hex};
    color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 1000;
  }
`;

const AutoCompleteContainer = styled('div')`
  display: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  max-height: 300px;
  overflow-y: scroll;
`;

const Suggestion = styled('div')`
  padding: 10px;
  &:hover {
    background-color: ${(props) => props.theme.primary.slot2.background.hex};
  }
`;

const AutoCompleteInput = withTheme(
  ({ value, suggestions, onChange }) => {
    const [input, setInput] = useState(value);

    const handleOnChange = useCallback(
      (event) => {
        onChange(event.target.value);
        setInput(event.target.value);
      },
      [setInput, onChange],
    );

    const handleSetInput = useCallback(
      (value) => {
        onChange(value);
        setInput(value);
      },
      [onChange, setInput],
    );

    const filteredSuggestions = useMemo(() => {
      if (!suggestions) {
        return [];
      }

      return suggestions.filter(
          (suggestion) =>
            suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1,
        )
        
    }, [input, suggestions]);

    return (
      <div style={{height: '100%'}} className='autocomplete-input'>
        <FocusableInput onChange={handleOnChange} type="text" value={input} />
        {filteredSuggestions.length ? (
          <AutoCompleteContainer
            className="autocomplete"
            style={{
              background: 'white',
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              position: 'absolute',
              zIndex: 1000,
              width: '100%'
            }}
          >
            {filteredSuggestions.map((suggestion) => {
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  onMouseDown={() => handleSetInput(suggestion)}
                >
                  <Suggestion>
                    <Caption1>{suggestion}</Caption1>
                  </Suggestion>
                </div>
              );
            })}
          </AutoCompleteContainer>
        ) : null}
      </div>
    );
  },
);

export { AutoCompleteInput };
