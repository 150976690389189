/**
 * @api {module} SubscriptionApi
 * @apiDescription Provides access to subscription-related endpoints.
 * @apiVersion 1.0.0
 * @apiName SubscriptionApi
 * @apiGroup API Modules
 */
import { dlaasApi } from '../';

const subscriptionApi = dlaasApi.injectEndpoints({
  endpoints: (builder) => ({
    createEnterpriseSubscription: builder.mutation({
      query: ({ paymentMethod, xchAddress }) => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/create_subscription`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productKey: 'ENTERPRISE',
          data: {
            paymentMethod,
            xchAddress,
          },
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),

    cancelSubscription: builder.mutation({
      query: () => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/cancel_subscription`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),

    /**
     * @api {function} useGetTopOffHistoryQuery Get Top-Off History Query
     * @apiDescription This query allows you to retrieve the top-off history.
     * @apiName useGetTopOffHistoryQuery
     * @apiGroup SubscriptionApi
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the top-off history.
     */
    getTopOffHistory: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/topoff_history`,
        method: 'GET',
      }),
    }),

    /**
     * @api {function} useUpdateXchTopOffAddressMutation Update XCH Top-Off Address Mutation
     * @apiDescription This mutation allows you to update the XCH top-off address.
     * @apiName useUpdateXchTopOffAddressMutation
     * @apiGroup SubscriptionApi
     *
     * @apiParam {Object} params - The parameters for updating the XCH top-off address.
     * @apiParam {string} params.xchAddress - The new XCH address.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the XCH top-off address is updated successfully.
     */
    updateXchTopOffAddress: builder.mutation({
      query: ({ xchAddress }) => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/update_xch_address`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          xch_address: xchAddress,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),

    /**
     * @api {function} useUpdateXchTopOffPreferenceMutation Update XCH Top-Off Preference Mutation
     * @apiDescription This mutation allows you to update the XCH top-off preference.
     * @apiName useUpdateXchTopOffPreferenceMutation
     * @apiGroup SubscriptionApi
     *
     * @apiParam {Object} params - The parameters for updating the XCH top-off preference.
     * @apiParam {boolean} params.automaticTopoff - The new XCH top-off preference.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the XCH top-off preference is updated successfully.
     */
    updateXchTopOffPreference: builder.mutation({
      query: ({ automaticTopoff }) => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/update_xch_preference`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          automatic_topoff: automaticTopoff,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),

    /**
     * @api {function} useRequestManualTopoffMutation Request Manual Top-Off Mutation
     * @apiDescription This mutation allows you to request a manual top-off.
     * @apiName useRequestManualTopoffMutation
     * @apiGroup SubscriptionApi
     *
     * @apiParam {Object} params - The parameters for requesting a manual top-off.
     * @apiParam {string} params.xchAddress - The XCH address for the top-off.
     * @apiParam {number} params.requestedAmount - The requested top-off amount.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the manual top-off is requested successfully.
     */
    requestManualTopoff: builder.mutation({
      query: ({ xchAddress, requestedAmount }) => ({
        url: `${process.env.REACT_APP_API_HOST}/product/v1/request_manual_topoff`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          xch_address: xchAddress,
          xch_requested: requestedAmount,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),
  }),
});

export const {
  useCreateEnterpriseSubscriptionMutation,
  useGetTopOffHistoryQuery,
  useUpdateXchTopOffAddressMutation,
  useUpdateXchTopOffPreferenceMutation,
  useRequestManualTopoffMutation,
  useCancelSubscriptionMutation,
} = subscriptionApi;
