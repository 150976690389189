import _ from 'lodash';
import { useState, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'flowbite-react';
import { Spacer } from 'components';

const NumberInput = styled('input')`
  border: solid thin black;
  border-radius: 6px;
  width: 45px;
  margin: 5px;
`;

const StartEndTime = withTheme(
  ({ onChange, theme, value, startKeyName = 'start', endKeyName = 'stop' }) => {
    const [expandWidget, setExpandWidget] = useState(false);
    const [endHour, setEndHour] = useState(value?.[endKeyName]?.hour);
    const [startHour, setStartHour] = useState(value?.[startKeyName]?.hour);

    const [endMin, setEndMin] = useState(value?.[endKeyName]?.minute);
    const [startMin, setStartMin] = useState(value?.[startKeyName]?.minute);

    const handleBlur = useCallback((event, set) => {
      if (event.target.value === '0') {
        set('00');
      }

      if (parseInt(event.target.value, 10) < 10) {
        set('0' + parseInt(event.target.value, 10));
      }
    }, []);

    const handleCancel = useCallback(() => {
      setExpandWidget(false);
      setEndHour(value?.[endKeyName]?.hour);
      setStartHour(value?.[startKeyName]?.hour);
      setEndMin(value?.[endKeyName]?.minute);
      setStartMin(value?.[startKeyName]?.minute);
    }, []);

    const handleToggleWidget = useCallback(() => {
      setExpandWidget(!expandWidget);
      onChange({
        [startKeyName]: {
          hour: startHour,
          minute: startMin,
        },
        [endKeyName]: {
          hour: endHour,
          minute: endMin,
        },
      });
    }, [expandWidget, setExpandWidget, onChange]);

    const handlesetStartHour = useCallback(
      (event) => {
        if (_.isEmpty(event.target.value)) {
          setStartHour('');
          return;
        }

        if (!event.target.value.match(/[0-9]|[0-9][0-9]/)) {
          return;
        }

        if (parseInt(event.target.value, 10) > 24) {
          return;
        }

        setStartHour(event.target.value);
      },
      [setStartHour],
    );

    const handlesetStartMin = useCallback(
      (event) => {
        if (_.isEmpty(event.target.value)) {
          setStartMin('');
          return;
        }

        if (!event.target.value.match(/[0-9]|[0-9][0-9]/)) {
          return;
        }

        if (parseInt(event.target.value, 10) > 60) {
          return;
        }

        setStartMin(event.target.value);
      },
      [setStartMin],
    );

    const handlesetEndHour = useCallback(
      (event) => {
        if (_.isEmpty(event.target.value)) {
          setEndHour('');
          return;
        }

        if (!event.target.value.match(/[0-9]|[0-9][0-9]/)) {
          return;
        }

        if (parseInt(event.target.value, 10) > 24) {
          return;
        }

        setEndHour(event.target.value);
      },
      [setEndHour],
    );

    const handlesetEndMin = useCallback(
      (event) => {
        if (_.isEmpty(event.target.value)) {
          setEndMin('');
          return;
        }

        if (!event.target.value.match(/[0-9]|[0-9][0-9]/)) {
          return;
        }

        if (parseInt(event.target.value, 10) > 60) {
          return;
        }

        setEndMin(event.target.value);
      },
      [setEndMin],
    );

    return (
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9308 2.0688C13.6308 1.76885 13.2239 1.60034 12.7996 1.60034C12.3753 1.60034 11.9685 1.76885 11.6684 2.0688L5.59961 8.1376V10.4H7.86201L13.9308 4.3312C14.2308 4.03116 14.3993 3.62426 14.3993 3.2C14.3993 2.77574 14.2308 2.36884 13.9308 2.0688Z"
              fill="#F9FAFB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.59961 4.80001C1.59961 4.37567 1.76818 3.9687 2.06824 3.66864C2.3683 3.36858 2.77526 3.20001 3.19961 3.20001H6.39961C6.61178 3.20001 6.81527 3.2843 6.96529 3.43433C7.11532 3.58436 7.19961 3.78784 7.19961 4.00001C7.19961 4.21219 7.11532 4.41567 6.96529 4.5657C6.81527 4.71573 6.61178 4.80001 6.39961 4.80001H3.19961V12.8H11.1996V9.60001C11.1996 9.38784 11.2839 9.18436 11.4339 9.03433C11.584 8.8843 11.7874 8.80001 11.9996 8.80001C12.2118 8.80001 12.4153 8.8843 12.5653 9.03433C12.7153 9.18436 12.7996 9.38784 12.7996 9.60001V12.8C12.7996 13.2244 12.631 13.6313 12.331 13.9314C12.0309 14.2314 11.624 14.4 11.1996 14.4H3.19961C2.77526 14.4 2.3683 14.2314 2.06824 13.9314C1.76818 13.6313 1.59961 13.2244 1.59961 12.8V4.80001Z"
              fill="#F9FAFB"
            />
          </svg>
        </div>
        <input
          type="text"
          style={{
            height: '100%',
            border: 'none',
            padding: '19px',
            width: '100%',
            paddingLeft: 35,
            cursor: 'pointer',
          }}
          onClick={handleToggleWidget}
          className={`
          text-sm 
          bg-${theme.primary.slot1.background.class}
          text-${theme.primary.slot1.text.class}
          placeholder-white
          focus:ring-blue-500 
          focus:border-blue-500 
          block 
          w-full 
          pl-10 
          p-2.5 
       `}
          placeholder="Choose Time"
          value={`${startHour}:${startMin} - ${endHour}:${endMin}`}
        />
        {expandWidget && (
          <div
            style={{
              position: 'absolute',
              background: 'white',
              bottom: -175,
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
              padding: 16,
              width: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
              zIndex: 1000,
            }}
          >
            <div>
              <NumberInput
                maxLength="2"
                type="text"
                value={startHour}
                onChange={handlesetStartHour}
                onBlur={(event) => handleBlur(event, setStartHour)}
              />
              :
              <NumberInput
                maxLength="2"
                type="text"
                value={startMin}
                onChange={handlesetStartMin}
                onBlur={(event) => handleBlur(event, setStartMin)}
              />
              -
              <NumberInput
                maxLength="2"
                type="text"
                value={endHour}
                onChange={handlesetEndHour}
                onBlur={(event) => handleBlur(event, setEndHour)}
              />
              :
              <NumberInput
                maxLength="2"
                type="text"
                value={endMin}
                onChange={handlesetEndMin}
                onBlur={(event) => handleBlur(event, setEndMin)}
              />
            </div>
            <FormattedMessage id="values-must-be-on-military-time" />
            <Spacer size={25} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              <Button style={{ width: 100 }} onClick={handleToggleWidget}>
                OK
              </Button>
              <Button
              style={{ width: 100 }}
                color="gray"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export { StartEndTime };
