import _ from 'lodash';
import React from 'react';
import { Modal } from 'flowbite-react';
import { Spacer, RenameMirrorForm } from 'components';
import { FormattedMessage } from 'react-intl';

const RenameMirrorModal = ({ storeId, onClose = _.noop }) => {
  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="rename-mirror" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <RenameMirrorForm storeId={storeId} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export { RenameMirrorModal };
