export default {
  locale: null,
  breadcrumbSeed: null,
  auth: {
    accessToken: null,
    refreshToken: null,
  },
  user: {
    username: null,
    email: null,
    id: null,
  }
};
