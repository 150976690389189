import React from 'react';

const PoliceOfficerIcon = () => {
  return (
    <svg
      width="448"
      height="512"
      viewBox="0 0 448 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_15103_80115)">
        <path
          d="M320 352H288L224 416L160 352H128C57.31 352 0 409.31 0 480C0 497.67 14.33 512 32 512H416C433.67 512 448 497.67 448 480C448 409.3 390.7 352 320 352ZM358.5 437.3L342.69 452.67L346.426 474.38C347.098 478.306 342.957 481.232 339.487 479.409L320 469.2L300.46 479.45C296.989 481.286 292.849 478.346 293.521 474.421L297.257 452.711L281.447 437.341C278.603 434.581 280.177 429.761 284.095 429.195L305.9 426L315.664 406.24C317.43 402.674 322.498 402.719 324.246 406.24L334.1 426L355.95 429.18C359.8 429.7 361.4 434.6 358.5 437.3ZM78.54 102.4V146.44C119.1 164.9 169.4 176 223.1 176C276.8 176 328 164.92 368.6 146.45V102.4L390.56 80.1C395.124 75.465 397.689 69.17 397.689 62.62C397.689 51.27 390.087 41.38 379.249 38.63L230.949 0.960004C225.853 -0.334996 220.519 -0.334996 215.429 0.960004L67.129 38.63C57.06 41.39 49.46 51.28 49.46 62.62C49.46 69.175 52.024 75.47 56.589 80.1L78.54 102.4ZM191.1 61.08L221.1 48.58C221.9 48.26 223.2 48 224 48C224.828 48 226.122 48.2559 226.884 48.5723L256.884 61.0723C259.663 62.2243 261.493 64.9613 261.493 67.9923C261.5 107.1 232.1 128 223.1 128C216.1 128 186.5 108.2 186.5 67.1C186.5 64.96 188.3 62.23 191.1 61.08Z"
          fill="#1C64F2"
        />
        <path
          opacity="0.4"
          d="M346 155.5C349.5 167.1 351.1 179.2 351.1 192C351.1 262.75 293.88 320 224 320C154.12 320 96 262.8 96 192C96 179.21 98.488 167.13 101.998 155.5C137.9 168.3 179.3 176 223.1 176C266.9 176 310.1 168.3 346 155.5Z"
          fill="#1C64F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_15103_80115">
          <rect width="448" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { PoliceOfficerIcon };
