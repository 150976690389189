import _ from 'lodash';
import React from 'react';
import { Modal } from 'flowbite-react';
import { Spacer, UpdateXchAddressForm } from 'components';
import { FormattedMessage } from 'react-intl';

const UpdateXchAddressModal = ({ onClose = _.noop }) => {
  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="update-xch-top-off-address" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <UpdateXchAddressForm onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export { UpdateXchAddressModal };
