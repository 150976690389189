import React from 'react';
import { withTheme } from 'styled-components';

const ToggleSwitch = withTheme(({ disabled, label, onChange, theme, checked = false, color="white" }) => {
  return (
    <label className="inline-flex relative items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={Boolean(checked)}
        onChange={() => {
          if (disabled) return;
          onChange();
        }}
        disabled={disabled}
      />
      <div
        style={{ opacity: disabled ? 0.5 : 1 }}
        className={`
        w-11 
        h-6 
        bg-gray-200 
        peer-focus:outline-none 
        peer-focus:ring-4 
        peer-focus:ring-${theme.primary.slot3.background.class} 
        rounded-full 
        peer 
        peer-checked:after:translate-x-full 
        peer-checked:after:border-white 
        after:content-[''] 
        after:absolute 
        after:top-[2px] 
        after:left-[2px] 
        after:bg-white 
        after:border-gray-300 
        after:border 
        after:rounded-full 
        after:h-5 
        after:w-5 
        after:transition-all 
        peer-checked:bg-${theme.primary.slot1.background.class}
      `}
      ></div>
      <span
        style={{ color }}
        className="ml-3 text-sm font-medium dark:text-white"
      >
        {label}
      </span>
    </label>
  );
});

export { ToggleSwitch };
