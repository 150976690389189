import { useMemo } from 'react';
import { DataTable, PageCounter } from 'components';
import { Pagination } from 'flowbite-react';
import { withTheme } from 'styled-components';
import dayjs from 'dayjs';
import { SkeletonTable } from './SkeletonTable';

const TopOffHistoryTable = withTheme(
  ({
    data,
    isLoading,
    currentPage,
    onPageChange,
    totalPages,
    totalCount,
    theme,
  }) => {
    const columns = useMemo(() => [
      {
        title: 'Date',
        key: 'updated_at',
        render: (row) => <span>{dayjs(row.updated_at).format('MM/DD/YYYY')}</span>,
      },
      {
        title: 'Amount',
        key: 'amount_paid',
        render: (row) => <span>{row.amount_paid} XCH</span>,
      },
      {
        title: 'Address',
        key: 'xch_payment_address',
      },
      {
        title: 'Subscription ID',
        key: 'subscription_id',
      },
    ]);

    if (isLoading) {
      return <SkeletonTable />;
    }

    return (
      <div className="relative" style={{ height: 'calc(100% - 162px)' }}>
        <DataTable columns={columns} data={data} isLoading={isLoading} />
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 20,
              position: 'sticky',
              bottom: -1,
              background: theme.surface.slot6.background.hex,
            }}
          >
            {totalCount > 0 && (
              <>
                <PageCounter
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalCount={totalCount}
                />
                <Pagination
                  currentPage={currentPage}
                  layout="pagination"
                  onPageChange={onPageChange}
                  showIcons={true}
                  totalPages={totalPages || 1}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  },
);

export { TopOffHistoryTable };
