import React from 'react';
import { withTheme } from 'styled-components';

const Banner = withTheme(({ children, theme, type = 'success' }) => {
  return (
    <div
      id="toast-default"
      className={`
        flex 
        items-center
        p-4 
        w-full 
        max-w-xs 
        text-${theme[type].slot1.background.class}
        bg-${theme.surface.slot2.background.class}
        border
        border-${theme[type].slot1.background.class}
        rounded-lg 
        shadow 
        dark:text-gray-400 
        dark:bg-gray-800
      `}
      role="alert"
    >
      {children}
    </div>
  );
});

export { Banner };
