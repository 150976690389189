import classNames from 'classnames';
import { Children, useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTheme } from 'flowbite-react';
import { TabItem } from './TabItem';
import { forceBreadCrumbRefresh } from 'store/slices/app';
import { useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';

export const TabsComponent = withTheme(
  ({ children, style = 'default', className, theme: appTheme, ...rest }) => {
    const theme = useTheme().theme.tab;
    const dispatch = useDispatch();

    const id = useId();
    const tabs = useMemo(
      () => Children.map(children, ({ props }) => props),
      [children],
    );
    const tabRefs = useRef([]);
    const [activeTab, setActiveTab] = useState(
      Math.max(
        0,
        tabs.findIndex((tab) => tab.active),
      ),
    );
    const [focusedTab, setFocusedTab] = useState(
      Math.max(
        0,
        tabs.findIndex((tab) => tab.active),
      ),
    );

    const handleClick = ({ target, tab }) => {
      setActiveTab(target);
      setFocusedTab(target);
      if (tab.onClick) {
        tab.onClick();
        dispatch(forceBreadCrumbRefresh());
      }
    };

    const handleKeyboard = ({ event, target }) => {
      if (event.key === 'ArrowLeft') {
        setFocusedTab(Math.max(0, focusedTab - 1));
      }

      if (event.key === 'ArrowRight') {
        setFocusedTab(Math.min(tabs.length - 1, focusedTab + 1));
      }

      if (event.key === 'Enter') {
        setActiveTab(target);
        setFocusedTab(target);
      }
    };

    const tabItemStyle = theme.tablist.tabitem.styles[style];

    useEffect(() => {
      tabRefs.current[focusedTab]?.focus();
    }, [focusedTab]);

    return (
      <div className={classNames(theme.base, className)}>
        <div
          aria-label="Character Tabs"
          role="tablist"
          className={classNames(
            theme.tablist.base,
            theme.tablist.styles[style],
            className,
          )}
          {...rest}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button
                key={index}
                type="button"
                style={{ textTransform: 'capitalize' }}
                aria-controls={`${id}-tabpanel-${index}`}
                aria-selected={index === activeTab}
                className={classNames(
                  theme.tablist.tabitem.base,
                  { ...tabItemStyle },
                  {
                    [tabItemStyle.active.on]: index === activeTab,
                    [tabItemStyle.active.off]:
                      index !== activeTab && !tab.disabled,
                  },
                )}
                disabled={tab.disabled}
                id={`${id}-tab-${index}`}
                onClick={() => handleClick({ target: index, tab })}
                onKeyDown={(event) => handleKeyboard({ event, target: index })}
                ref={(element) => (tabRefs.current[index] = element)}
                role="tab"
                tabIndex={index === focusedTab ? 0 : -1}
              >
                {tab.icon && (
                  <tab.icon className={theme.tablist.tabitem.icon} />
                )}
                {tab.title}
              </button>
              <span
                style={{
                  borderLeft: `solid thin ${appTheme.surface.slot3.background.hex}`,
                  height: '50%',
                }}
              />
            </div>
          ))}
        </div>
        <div
          style={{ height: '100%', position: 'relative', overflow: 'scroll' }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{ height: '100%' }}
              aria-labelledby={`${id}-tab-${index}`}
              className={theme.tabpanel}
              hidden={index !== activeTab}
              id={`${id}-tabpanel-${index}`}
              role="tabpanel"
              tabIndex={0}
            >
              {tab.children}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

TabsComponent.displayName = 'Tabs.Group';
TabItem.displayName = 'Tabs.Item';
const Tabs = { Group: TabsComponent, Item: TabItem };
export { Tabs };
