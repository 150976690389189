import _ from 'lodash';
import React, { useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'flowbite-react';
import {
  useDeleteClientAccessKeysMutation,
  useUserProfileQuery,
} from 'api/dlaas/user';

const DeleteAccessKeyModal = ({ accessKey, onClose = _.noop }) => {
  const [deleteClientAccessKeys] = useDeleteClientAccessKeysMutation();
  const { data: userProfile } = useUserProfileQuery();
  const [showSpinner, setShowSpinner] = React.useState(false);

  const handleDelete = useCallback(async () => {
    setShowSpinner(true);
    await deleteClientAccessKeys({
      userId: userProfile.id,
      accessKey: accessKey,
    });
    onClose();
  }, [accessKey, deleteClientAccessKeys, onClose, userProfile]);

  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="delete-access-key" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <FormattedMessage id="are-you-sure-you-want-to-delete-access-key" />
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: 75 }} onClick={onClose} color="light">
          <FormattedMessage id="cancel" />
        </Button>
        <Button style={{ width: 75 }} onClick={handleDelete} color="failure">
          {showSpinner ? (
            <Spinner size="sm" />
          ) : (
            <FormattedMessage id="delete" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteAccessKeyModal };
