import React, { useState } from 'react';
import { Modal } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import {
  EnterpriseSubscriptionLanding,
  EnterpriseCheckoutForm,
} from 'components';

const SubscriptionLandingModal = ({onClose}) => {
  const [checkoutStep, setCheckoutStep] = useState('CHECKOUT');

  return (
    <Modal
      dismissible={false}
      show={true}
      size="7xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="subscribe-to-use" />
      </Modal.Header>
      <Modal.Body>
        <>
          {checkoutStep === 'LANDING' && (
            <EnterpriseSubscriptionLanding
              onActivate={() => setCheckoutStep('CHECKOUT')}
            />
          )}
          {checkoutStep === 'CHECKOUT' && <EnterpriseCheckoutForm onClose={onClose} />}
        </>
      </Modal.Body>
    </Modal>
  );
};

export { SubscriptionLandingModal };
