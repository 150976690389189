import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { useUrlHash } from 'hooks';

const UserConfirmedModal = ({ onClose = _.noop }) => {
  const [showUserConfirmed, setShowUserConfirmed] = useState(false);
  const [hashIsActive, setHashActive] = useUrlHash('user-confirmed');

  useEffect(() => {
    if (hashIsActive) {
      setShowUserConfirmed(true);
    } else {
      setShowUserConfirmed(false);
    }
  }, [hashIsActive, setShowUserConfirmed]);

  const handleClose = useCallback(() => {
    setHashActive(false);
    onClose();
  });

  if (!showUserConfirmed) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="user-confirmed" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <FormattedMessage id="your-user-is-now-confirmed" />
      </Modal.Body>
    </Modal>
  );
};

export { UserConfirmedModal };
