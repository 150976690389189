import React, { useState } from 'react';
import {
  Container,
  PageTitle,
  CancelSubscriptionModal,
  EnterpriseSubscriptionLanding,
} from 'components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useUserProfileQuery } from 'api/dlaas/user';
import { hasActiveProductKey } from 'utils/helpers';
import { Spinner } from 'flowbite-react';
import { Button } from 'flowbite-react';

const Account = () => {
  const intl = useIntl();
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);

  if (userDataIsLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'account' })} />
      <Container height="unset">
        {hasActiveProductKey(user.subscriptions) ? (
          <>
            <Button
              style={{ marginRight: 15 }}
              onClick={() => setShowCancelSubscriptionModal(true)}
            >
              <FormattedMessage id="cancel-subscription" />
            </Button>
            <br />
            For advanced account management, please visit your{' '}
            <a href="https://billing.stripe.com/p/login/cN2fZd7fG4nz9Ec4gg" target="_blank">
              Stripe Customer Portal
            </a>
            .
          </>
        ) : (
          <EnterpriseSubscriptionLanding />
        )}
      </Container>
      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal
          onClose={() => setShowCancelSubscriptionModal(false)}
        />
      )}
    </>
  );
};

export { Account };
