import _ from 'lodash';
import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { Container, Banner } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { useCheckMirrorConnectionMutation } from 'api/dlaas/mirror';

const initialValues = {
  ipAddress: '',
};

const ipv4Pattern =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const ipv6Pattern =
  /^\[([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|\]$/;

const validationSchema = yup.object({
  ipAddress: yup
    .string()
    .matches(
      new RegExp(`(${ipv4Pattern.source})|(${ipv6Pattern.source})`),
      'Must be a valid IPv4 or IPv6 address. IPv6 must be wrapped in square brackets.',
    )
    .required('Valid IP Address is required.'),
});

const CheckMirrorConnectionForm = withTheme(({ onClose }) => {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [checkIpAddress, { isLoading }] = useCheckMirrorConnectionMutation();
  const [mirrorStatus, setMirrorStatus] = useState(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitted(true);
        const response = await checkIpAddress(values);
        setMirrorStatus(response.data);
      } catch {
        setMirrorStatus({ connection_detected: false });
      }
    },
  });

  console.log('mirrorStatus', mirrorStatus)

  return (
    <>
      {!_.isNil(mirrorStatus) && (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
          {!_.isNil(mirrorStatus) && mirrorStatus.connection_detected && (
            <Banner>Success! Connection Detected</Banner>
          )}

          {!_.isNil(mirrorStatus) && !mirrorStatus.connection_detected && (
            <Banner type="error">
              Can not detect your peer, please refer to the documentation to
              troubleshoot
            </Banner>
          )}
        </div>
      )}

      <form onSubmit={submitted ? onClose : formik.handleSubmit}>
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div>
              <TextInput
                id="ipAddress"
                placeholder={intl.formatMessage({ id: 'ip4-ip6-address' })}
                value={formik.values.ipAddress}
                onChange={formik.handleChange}
              />
              {formik.errors.ipAddress ? (
                <div>{formik.errors.ipAddress}</div>
              ) : null}
            </div>
          </div>
        </div>

        <Container align="center" padding="0">
          <Button type="submit" value="Save">
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <FormattedMessage id={submitted ? 'ok' : 'submit'} />
            )}
          </Button>
        </Container>
      </form>
    </>
  );
});

export { CheckMirrorConnectionForm };
