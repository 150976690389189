import _ from 'lodash';
import React, { useCallback, useState, useMemo } from 'react';
import {
  TopOffHistoryTable,
  Container,
  PageTitle,
  UpdateXchAddressModal,
  ToggleSwitch,
  RequestXchTopoffModal,
} from 'components';
import {
  useGetTopOffHistoryQuery,
  useUpdateXchTopOffPreferenceMutation,
} from 'api/dlaas/subscription';
import { useQueryParamState } from 'hooks';
import { Alert, Button, Spinner } from 'flowbite-react';
import { useIntl } from 'react-intl';
import { useUserProfileQuery } from 'api/dlaas/user';
import constants from 'constants';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowMyTopOffsAlert } from 'store/slices/userOptions/userOptions.slice';
import { Link } from 'react-router-dom';
import { useUrlHash } from 'hooks';
import { hasActiveProductKey } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';

const MyTopOffsPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userOptionsStore = useSelector((state) => state.userOptions);
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [currentPage, setCurrentPage] = useQueryParamState('page', 1);
  const [, setRequestXchModal] = useUrlHash('request-xch');
  const { data, isLoading } = useGetTopOffHistoryQuery({
    page: currentPage,
  });

  const [updateXchTopOffPreferance, { isLoading: topoffPreferanceUpdating }] =
    useUpdateXchTopOffPreferenceMutation();

  const [showUpdateXCHAddressModal, setShowUpdateXCHAddressModal] =
    useState(false);

  const handlePageChange = useCallback(
    _.debounce((page) => setCurrentPage(page), 800),
    [setCurrentPage],
  );

  const enterpriseSubscription = useMemo(() => {
    if (userDataIsLoading) {
      return false;
    }
    return (
      user?.subscriptions?.find(
        (subscription) =>
          subscription.product === constants.ENTERPRISE_PRODUCT_ID &&
          ['active', 'trialing'].includes(subscription.status),
      ) || 'none'
    );
  }, [userDataIsLoading, user?.subscriptions]);

  const isInTrial = useMemo(() => {
    if (userDataIsLoading) {
      return false;
    }
    return user?.subscriptions?.some(
      (subscription) =>
        subscription.product === constants.ENTERPRISE_PRODUCT_ID &&
        ['trialing'].includes(subscription.status),
    );
  }, [userDataIsLoading, user?.subscriptions]);

  const handleDismissAlert = useCallback(() => {
    dispatch(toggleShowMyTopOffsAlert());
  }, [dispatch]);

  const handleChangeTopoffPreferance = useCallback(() => {
    updateXchTopOffPreferance({
      automaticTopoff: !user?.automatic_topoff,
      userId: user?.user_id,
    });
  }, [updateXchTopOffPreferance, user?.automatic_topoff, user?.user_id]);

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'your-blockchain-access' })} />
      <Container height="unset">
        <div style={{ margin: 25 }}>
          {userOptionsStore.showMyTopOffsAlert && (
            <Alert color="info" onDismiss={handleDismissAlert}>
              <p>
                Using Chia DataLayer requires a small amount of a digital coin
                called XCH. This coin ensures that your data modifications are
                correctly saved and updated. This process is typical in most
                blockchain systems and provides access to the blockchain.
              </p>
              <br />
              <p>
                DataLayer.storage covers these costs for you. Please note, the
                digital coins we transfer to your wallet are solely for covering
                these blockchain fees. They remain the property of
                DataLayer.storage. If there's any XCH remaining when you stop
                using our service, it must be returned to us.
              </p>
              <br />
              <p>
                Using this service, means you agree to the{' '}
                <Link to="/terms-of-service">Terms of Service</Link>.
              </p>
            </Alert>
          )}
          {isInTrial && (
            <>
              <br />
              <Alert color="warning">
                You are currently in trial mode, no additional XCH topoffs will
                take place until the trial is over.
              </Alert>
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: 50,
            margin: 25,
            flexWrap: 'wrap',
          }}
        >
          <>
            <div style={{ minWidth: 300 }}>
              {topoffPreferanceUpdating || userDataIsLoading ? (
                <div
                  style={{
                    width: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner size="sm" />
                </div>
              ) : (
                <div>
                  <ToggleSwitch
                    disabled={!hasActiveProductKey(user?.subscriptions)}
                    checked={user?.automatic_topoff || false}
                    label={intl.formatMessage({ id: 'automatic-topoffs' })}
                    onChange={handleChangeTopoffPreferance}
                    color="black"
                  />
                </div>
              )}

              <div>
                {user?.automatic_topoff ? (
                  <div>
                    <div>
                      You have chosen to automatically receive topoffs each
                      month.
                    </div>
                    <div>
                      Next topoff will be on {user.next_automatic_topoff_date}
                    </div>
                  </div>
                ) : (
                  <span>You have chosen to manually request topoffs</span>
                )}
              </div>
            </div>
            {user?.automatic_topoff ? (
              <>
                {enterpriseSubscription && (
                  <div style={{ margin: '15px 0', minWidth: 534 }}>
                    Your XCH Topoff Address:
                    <div>
                      <b>
                        {userDataIsLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            {enterpriseSubscription?.xchAddress || (
                              <Spinner size="sm" />
                            )}
                          </>
                        )}
                      </b>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button onClick={() => {
                    if (!hasActiveProductKey(user?.subscriptions)) {
                      navigate('/account');
                      return;
                    }
                    setShowUpdateXCHAddressModal(true)
                  }}>
                    Update XCH Topoff Address
                  </Button>
                </div>
              </>
            ) : (
              <Button onClick={() => {
                if (!hasActiveProductKey(user?.subscriptions)) {
                  navigate('/account');
                  return;
                }
                setRequestXchModal(true)
              }}>
                Request Topoff
              </Button>
            )}
          </>
        </div>

        <TopOffHistoryTable
          data={data?.transactions}
          isLoading={isLoading}
          currentPage={Number(currentPage)}
          onPageChange={handlePageChange}
          totalPages={data?.total_pages}
          totalCount={data?.total_count}
        />
      </Container>
      {showUpdateXCHAddressModal && (
        <UpdateXchAddressModal
          onClose={() => setShowUpdateXCHAddressModal(false)}
        />
      )}
      <RequestXchTopoffModal />
    </>
  );
};

export { MyTopOffsPage };
