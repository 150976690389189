import React from 'react';
import { withTheme } from 'styled-components';
import heroSubscription from 'assets/images/hero_plant.jpg';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'flowbite-react';

const Container = styled('div')`
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.surface.slot2.background.hex};
  z-index: 1000;
  padding: 5px;
`;

const PrivacyPolicy = withTheme(({ theme }) => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        className={`border-b bg-${theme.surface.slot1.background.class}`}
      >
        <div style={{ padding: 5 }}>
          <Button color="gray" onClick={() => navigate('/login')}>
            Login
          </Button>
        </div>
        <div style={{ padding: 5 }}>
          <Button onClick={() => navigate('/register')}>Sign Up</Button>
        </div>
      </Container>
      <div aria-hidden="true" className="relative">
        <img
          src={heroSubscription}
          alt=""
          className="h-96 w-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />

        <div
          style={{ top: '50%', width: '100%' }}
          className="absolute mx-auto -mt-12 px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8"
        >
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </h2>
          </div>
        </div>
      </div>
      <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="bg-white px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <p className="text-base font-semibold leading-7 text-indigo-600">
              6/8/2023
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy for Datalayer Storage
            </h1>

            <div className="mt-10 max-w-2xl">
              <p>
                At DataLayer Storage, accessible from https://datalayer.storage,
                one of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by DataLayer Storage and how we use it.
              </p>

              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>

              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in DataLayer
                Storage. This policy is not applicable to any information
                collected offline or via channels other than this website.
              </p>

              <h2>Consent</h2>

              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>

              <h2>Information we collect</h2>

              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>

              <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>

              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>

              <h2>How we use your information</h2>

              <p>
                We use the information we collect in various ways, including to:
              </p>

              <ul style={{ listStyleType: 'disc' }}>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>

              <h2>Log Files</h2>

              <p>
                DataLayer Storage follows a standard procedure of using log
                files. These files log visitors when they visit websites. All
                hosting companies do this and a part of hosting services'
                analytics. The information collected by log files include
                internet protocol (IP) addresses, browser type, Internet Service
                Provider (ISP), date and time stamp, referring/exit pages, and
                possibly the number of clicks. These are not linked to any
                information that is personally identifiable. The purpose of the
                information is for analyzing trends, administering the site,
                tracking users' movement on the website, and gathering
                demographic information.
              </p>

              <h2>Third Party Privacy Policies</h2>

              <p>
                DataLayer Storage's Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.
              </p>

              <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>

              <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  Request that a business that collects a consumer's personal
                  data disclose the categories and specific pieces of personal
                  data that a business has collected about consumers.
                </li>
                <li>
                  Request that a business delete any personal data about the
                  consumer that a business has collected.
                </li>
                <li>
                  Request that a business that sells a consumer's personal data,
                  not sell the consumer's personal data.
                </li>
                <li>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </li>
              </ul>

              <h2>GDPR Data Protection Rights</h2>

              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <ul style={{ listStyleType: 'disc' }}>
                <li>
                  The right to access – You have the right to request copies of
                  your personal data. We may charge you a small fee for this
                  service.
                </li>
                <li>
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate. You
                  also have the right to request that we complete the
                  information you believe is incomplete.
                </li>
                <li>
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </li>
                <li>
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </li>
                <li>
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </li>
                <li>
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </li>
                <li>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </li>
              </ul>

              <h2>Children's Information</h2>

              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>

              <p>
                DataLayer Storage does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>

              <h2>Changes to This Privacy Policy</h2>

              <p>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately, after
                they are posted on this page.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at our email:
                <Link to="/contact">Contact Form</Link>.
              </p>

              <p>This policy is effective as of 1 January 2023.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export { PrivacyPolicy };
