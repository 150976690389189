import * as yup from 'yup';
import { useRef, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import productImage from 'assets/images/productImage.png';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useUserProfileQuery } from 'api/dlaas/user';
import { useCreateEnterpriseSubscriptionMutation } from 'api/dlaas/subscription';
import { Spinner } from 'flowbite-react';
import { IndeterminateProgressOverlay, Banner } from 'components';
import { dlaasApi } from 'api/dlaas';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  nameOnCard: yup.string().required('Name is required.'),
  xchAddress: yup
    .string()
    .matches(
      /^xch[a-z0-9]{59}$/,
      'XCH address must start with "xch" and contain 62 alphanumeric characters.',
    )
    .required('Valid XCH Address is required.'),
});

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#4A5568', // Tailwind CSS 'gray.700'
      fontFamily: 'Open Sans, sans-serif', // Your preferred font family
      fontSize: '16px',
      '::placeholder': {
        color: '#A0AEC0', // Tailwind CSS 'gray.400'
      },
    },
    invalid: {
      color: '#E53E3E', // Tailwind CSS 'red.600'
      iconColor: '#E53E3E', // Tailwind CSS 'red.600'
    },
  },
};

const subtotal = '$10.00';
const taxes = '$0.00';
const total = '$10.00';
const products = [
  {
    id: 1,
    name: 'Enterprise Subscription Plan',
    href: '#',
    price: '$10.00',
    color: 'Billed Monthly',
    imageSrc: productImage,
  },
];
const EnterpriseCheckoutForm = (onClose) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { data: user } = useUserProfileQuery();
  const [triggerCreateEnterpriseSubscription] =
    useCreateEnterpriseSubscriptionMutation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const cardElementRef = useRef();
  const nameRef = useRef();
  const xchAddressRef = useRef();

  const initialValues = {
    name: '',
    xchAddress: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ nameOnCard, xchAddress }) => {
      setShowSpinner(true);
      event.preventDefault();

      if (!stripe) {
        // Stripe.js has not loaded yet.
        console.error("stripe.js hasn't loaded yet");
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        // CardElement wasn't found in Elements provider
        console.log('cardElement not found');
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
          email: user.email,
        },
      });

      if (error) {
        console.log('[error]', error);
      } else {
        const subscriptionResponse = await triggerCreateEnterpriseSubscription({
          paymentMethod,
          xchAddress,
          userId: user.id,
        });

        if (subscriptionResponse.error) {
          setErrorMessage(subscriptionResponse.error.data?.message);
          return;
        }

        onClose();

        dispatch(dlaasApi.util.invalidateTags(['user']));
      }
      setShowSpinner(false);
    },
  });

  if (!user) {
    return (
      <div
        className="col-span-full"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 100,
        }}
      >
        <Spinner size="xl" />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
        <h1 className="sr-only">Checkout</h1>

        {/* Mobile order summary */}
        <section
          aria-labelledby="order-heading"
          className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
        >
          <Disclosure as="div" className="mx-auto max-w-lg">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <h2
                    id="order-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Your Order
                  </h2>
                  <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                    {open ? (
                      <span>Hide full summary</span>
                    ) : (
                      <span>Show full summary</span>
                    )}
                  </Disclosure.Button>
                </div>

                <Disclosure.Panel>
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 border-b border-gray-200"
                  >
                    {products.map((product) => (
                      <li key={product.id} className="flex space-x-6 py-6">
                        <div className="flex flex-col justify-between space-y-4">
                          <div className="space-y-1 text-sm font-medium">
                            <h3 className="text-gray-900">{product.name}</h3>
                            <p className="text-gray-900">{product.price}</p>
                            <p className="text-gray-500">{product.color}</p>
                            <ul>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                Unlimited hosting of mirrored datastores
                              </li>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                Unlimited uploading and hosting of datalayer
                                files
                              </li>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                Monthly XCH top-off for your wallet up to 0.05
                                XCH*
                              </li>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                Unlimited client access keys for service
                                integration
                              </li>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                Download and installation of the DataLayer
                                upload plugin
                              </li>
                              <li
                                style={{ margin: 26, listStyleType: 'disc' }}
                                className="text-gray-500"
                              >
                                14 Day Money Back Guarantee{' '}
                                <i>
                                  <a href="/terms-of-service" target="_blank">
                                    See Terms of Service
                                  </a>
                                </i>
                              </li>
                            </ul>
                          </div>

                          <div className="flex space-x-4">
                            <button
                              type="button"
                              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              Edit
                            </button>
                            <div className="flex border-l border-gray-300 pl-4">
                              <button
                                type="button"
                                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                    <div className="flex justify-between">
                      <dt>Subtotal</dt>
                      <dd className="text-gray-900">{subtotal}</dd>
                    </div>

                    <div className="flex justify-between">
                      <dt>Taxes</dt>
                      <dd className="text-gray-900">{taxes}</dd>
                    </div>
                  </dl>
                </Disclosure.Panel>

                <p className="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                  <span className="text-base">Total</span>
                  <span className="text-base">{total}</span>
                </p>
              </>
            )}
          </Disclosure>
        </section>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex"
        >
          <h2 id="summary-heading" className="sr-only">
            Order summary
          </h2>

          <ul
            role="list"
            className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6"
          >
            {products.map((product) => (
              <li key={product.id} className="flex space-x-6 py-6">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                />
                <div className="flex flex-col justify-between space-y-4">
                  <div className="space-y-1 text-sm font-medium">
                    <h3 className="text-gray-900">{product.name}</h3>
                    <p className="text-gray-900">{product.price}</p>
                    <p className="text-gray-500">{product.color}</p>
                    <p className="text-gray-500"></p>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <ul>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              Unlimited hosting of mirrored datastores
            </li>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              Unlimited uploading and hosting of datalayer files
            </li>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              Monthly XCH top-off for your wallet up to 0.05 XCH
            </li>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              Unlimited client access keys for service integration
            </li>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              Download and installation of the DataLayer upload plugin
            </li>
            <li
              style={{ margin: 5, listStyleType: 'disc' }}
              className="text-gray-500"
            >
              14-Day Money Back Guarantee{' '}
              <i>
                <a href="/terms-of-service" target="_blank">
                  See Terms of Service
                </a>
              </i>
            </li>
          </ul>
          <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
            <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="text-gray-900">{subtotal}</dd>
              </div>
              <div className="flex justify-between">
                <dt>Taxes</dt>
                <dd className="text-gray-900">{taxes}</dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt>Total</dt>
                <dd className="text-base">{total}</dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <h2 id="payment-heading" className="sr-only">
            Payment details
          </h2>

          <div className="mx-auto max-w-lg lg:pt-16">
            {errorMessage && <Banner type="error">{errorMessage}</Banner>}
            <form className="mt-6" onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                <div className="col-span-full">
                  <label
                    htmlFor="name-on-card"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name on card
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      ref={nameRef}
                      id="name-on-card"
                      name="nameOnCard"
                      autoComplete="cc-name"
                      onChange={formik.handleChange}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {formik.errors.nameOnCard ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.nameOnCard}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="xch-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    XCH Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      ref={xchAddressRef}
                      id="xchAddress"
                      name="xchAddress"
                      onChange={formik.handleChange}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {formik.errors.xchAddress ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.xchAddress}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-span-full" style={{ marginTop: 5 }}>
                  <div className="border border-gray-300 rounded-md p-3 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:border-indigo-500">
                    <CardElement
                      ref={cardElementRef}
                      options={CARD_ELEMENT_OPTIONS}
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Subscribe
              </button>

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <LockClosedIcon
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  style={{ width: 50 }}
                />
                Payments are processed securely by Stripe, a trusted global
                payment processor. We do not store your payment details on our
                servers. By proceeding with the payment, you agree to Stripe's
                Terms of Service.
              </p>
              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <a
                  href="https://stripe.com/legal"
                  target="_blank"
                  className="link"
                >
                  Stripe Terms of Service
                </a>
              </p>
            </form>
          </div>
        </section>
      </main>
      {showSpinner && (
        <IndeterminateProgressOverlay
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div>
  );
};

export { EnterpriseCheckoutForm };
