import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useUpdateXchTopOffAddressMutation } from 'api/dlaas/subscription';
import { Container } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { useUserProfileQuery } from 'api/dlaas/user';

const initialValues = {
  xchAddress: '',
};

const validationSchema = yup.object({
  xchAddress: yup
    .string()
    .matches(
      /^xch[a-z0-9]{59}$/,
      'XCH address must start with "xch" and contain 62 alphanumeric characters.',
    )
    .required('Valid XCH Address is required.'),
});

const UpdateXchAddressForm = withTheme(({ onClose }) => {
  const intl = useIntl();
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [submitted, setSubmitted] = useState(false);
  const [updateXchAddress, { isLoading }] = useUpdateXchTopOffAddressMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitted(true);
      await updateXchAddress({
        userId: user.id,
        ...values,
      });
      onClose();
    },
  });

  if (userDataIsLoading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <div>
          <div>
            <TextInput
              id="xchAddress"
              placeholder={intl.formatMessage({ id: 'new-xch-address' })}
              value={formik.values.xchAddress}
              onChange={formik.handleChange}
            />
            {formik.errors.xchAddress ? (
              <div>{formik.errors.xchAddress}</div>
            ) : null}
          </div>
        </div>
      </div>

      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <FormattedMessage id={submitted ? 'ok' : 'submit'} />
          )}
        </Button>
      </Container>
    </form>
  );
});

export { UpdateXchAddressForm };
