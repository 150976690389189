import React from 'react';
import { withTheme } from 'styled-components';

const SissorsIcon = withTheme(({ width = 20, height = 20, color, theme }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.121 11.121L16 16M9.00002 9.00002L16 2.00002M9.00002 9.00002L6.12102 11.879M9.00002 9.00002L6.12102 6.12102M6.12102 11.879C5.55837 11.3164 4.79524 11.0003 3.99952 11.0003C3.2038 11.0003 2.44068 11.3164 1.87802 11.879C1.31536 12.4417 0.999268 13.2048 0.999268 14.0005C0.999268 14.7962 1.31536 15.5594 1.87802 16.122C2.44068 16.6847 3.2038 17.0008 3.99952 17.0008C4.79524 17.0008 5.55837 16.6847 6.12102 16.122C6.68368 15.5594 6.99978 14.7962 6.99978 14.0005C6.99978 13.2048 6.68368 12.4417 6.12102 11.879ZM6.12102 6.12102C6.68368 5.55837 6.99978 4.79524 6.99978 3.99952C6.99978 3.2038 6.68368 2.44068 6.12102 1.87802C5.55837 1.31536 4.79524 0.999268 3.99952 0.999268C3.2038 0.999268 2.44068 1.31536 1.87802 1.87802C1.31536 2.44068 0.999268 3.2038 0.999268 3.99952C0.999268 4.79524 1.31536 5.55837 1.87802 6.12102C2.44068 6.68368 3.2038 6.99978 3.99952 6.99978C4.79524 6.99978 5.55837 6.68368 6.12102 6.12102Z"
        stroke={color || theme.surface.slot5.background.hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export { SissorsIcon };
