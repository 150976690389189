import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { Spacer } from 'components';
import { useUrlHash } from 'hooks';
import { handleLogout } from 'store/slices/app';
import { useDispatch } from 'react-redux';
import { dlaasApi } from 'api/dlaas';

const LogoutModal = ({ onClose = _.noop }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [hashIsActive, setHashIsActive] = useUrlHash('logout');

  const handleOnLogout = useCallback(() => {
    dispatch(handleLogout());
    dispatch(dlaasApi.util.resetApiState());
  }, [handleLogout, dispatch])

  useEffect(() => {
    if (hashIsActive) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [hashIsActive, setShowModal]);

  const handleClose = useCallback(() => {
    setHashIsActive(false);
    onClose();
  }, [onClose]);

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="logout" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="20" />
        <FormattedMessage id="would-you-like-to-logout" />
        <Spacer size="20" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Button type="submit" onClick={handleOnLogout}>
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="yes" />
            </span>
          </Button>
          <Button type="submit" onClick={handleClose}>
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="cancel" />
            </span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { LogoutModal };
