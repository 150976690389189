import { createSlice, current } from '@reduxjs/toolkit';
import initialState from './userOptions.initialstate';

export const userOptionsSlice = createSlice({
  name: 'userOptions',
  initialState,
  reducers: {
    setSaveTokenToLocalStorage: (state, { payload }) => {
      state.saveTokenToLocalStorage = payload;
    },
    toggleTheme: (state) => {
      if (state.selectedTheme === 'light') {
        state.selectedTheme = 'dark';
      } else {
        state.selectedTheme = 'light';
      }
    },
    toggleShowAccessKeyAlert: (state) => {
      state.showAccessKeyAlert = !current(state).showAccessKeyAlert;
    },
    toggleShowMyMirrorsAlert: (state) => {
      state.showMyMirrorsAlert = !current(state).showMyMirrorsAlert;
    },
    toggleShowMyTopOffsAlert: (state) => {
      state.showMyTopOffsAlert = !current(state).showMyTopOffsAlert;
    },
    toggleShowMyFilesAlert: (state) => {
      state.showMyFilesAlert = !current(state).showMyFilesAlert;
    },
  },
});

export const {
  setSaveTokenToLocalStorage,
  toggleTheme,
  toggleShowAccessKeyAlert,
  toggleShowMyMirrorsAlert,
  toggleShowMyTopOffsAlert,
  toggleShowMyFilesAlert,
} = userOptionsSlice.actions;

export default userOptionsSlice.reducer;
