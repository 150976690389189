import React from 'react';
import { withTheme } from 'styled-components';

const UndoIcon = withTheme(({ width = 20, height = 19, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.5H11C13.1217 7.5 15.1566 8.34285 16.6569 9.84315C18.1571 11.3434 19 13.3783 19 15.5V17.5M1 7.5L7 13.5M1 7.5L7 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export { UndoIcon };
