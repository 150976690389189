import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { Container, Spacer } from 'components';
import { useUrlHash } from 'hooks';

const AccountCreatedModal = ({ onClose = _.noop }) => {
  const [showModal, setShowModal] = useState(false);
  const [hashIsActive, setHashIsActive] = useUrlHash('account-created');

  useEffect(() => {
    if (hashIsActive) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [hashIsActive, setShowModal]);

  const handleClose = useCallback(() => {
    setHashIsActive(false);
    onClose();
  }, [onClose]);

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="thank-you" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="20" />
        <FormattedMessage id="account-has-been-created" />
        <Spacer size="20" />
        <Container align="center" padding="0">
          <Button type="submit" onClick={handleClose}>
            <FormattedMessage id="ok" />
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export { AccountCreatedModal };
