import { useRef, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import Datepicker from 'flowbite-datepicker/Datepicker';

const DatePicker = withTheme(({ onChange, theme, value }) => {
  const ref = useRef();
  const [datePickerInstance, setDatePickerInstance] = useState();

  useEffect(() => {
    if (!datePickerInstance) {
      const instance = new Datepicker(ref.current);
      setDatePickerInstance(instance);
    }
  }, [setDatePickerInstance]);

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          aria-hidden="true"
          className={`w-5 h-5 text-${theme.primary.slot1.text.class}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      <input
        ref={ref}
        type="text"
        style={{
          height: '100%',
          border: 'none',
          padding: '20px',
          width: '100%',
          paddingLeft: 35,
          cursor: 'pointer',
          borderRadius: 0
        }}
        onBlur={onChange}
        className={`
          text-sm 
          bg-${theme.primary.slot1.background.class}
          text-${theme.primary.slot1.text.class}
          placeholder-white
          focus:ring-blue-500 
          focus:border-blue-500 
          block 
          w-full 
          pl-10 
       `}
        placeholder="Select date"
        value={value}
      />
    </div>
  );
});

export { DatePicker };
