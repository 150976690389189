import _ from 'lodash';
import React, { useCallback } from 'react';
import { Modal, Button } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { Spacer } from 'components';
import { useDeleteSubdomainMutation } from 'api/dlaas/mirror';

const DeleteSubdomainModal = ({ subdomain, onClose = _.noop }) => {
  const [deleteSubDomain] = useDeleteSubdomainMutation();

  const handleOnDeleteSubdomain = useCallback(async () => {
    await deleteSubDomain({ subdomain });
    onClose();
  }, [onClose, deleteSubDomain]);

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="delete-subdomain" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="20" />
        <FormattedMessage id="would-you-like-to-delete-subdomain" />
        <Spacer size="20" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Button type="submit" onClick={handleOnDeleteSubdomain}>
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="yes" />
            </span>
          </Button>
          <Button type="submit" onClick={onClose}>
            <span style={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="cancel" />
            </span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { DeleteSubdomainModal };
