import React from 'react';

const StopSignIcon = ({
  width = 421,
  height = 380,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 421 380"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19305 220.414C-2.73102 201.574 -2.73102 178.426 8.19305 159.586L82.9679 30.4135C93.8919 11.574 113.962 0 135.81 0H285.275C307.038 0 327.193 11.574 338.117 30.4135L412.807 159.586C423.731 178.426 423.731 201.574 412.807 220.414L338.032 349.586C327.108 368.426 307.038 380 285.19 380H135.725C113.962 380 93.8073 368.426 82.8832 349.586L8.19305 220.414ZM210.5 81.8631C199.237 81.8631 190.176 90.9026 190.176 102.139V196.759C190.176 207.995 199.237 217.034 210.5 217.034C221.763 217.034 230.824 207.995 230.824 196.759V102.139C230.824 90.9026 221.763 81.8631 210.5 81.8631ZM237.598 271.103C237.598 256.149 225.489 244.068 210.5 244.068C195.511 244.068 183.402 256.149 183.402 271.103C183.402 286.056 195.511 298.137 210.5 298.137C225.489 298.137 237.598 286.056 237.598 271.103Z"
        fill="#E02424"
      />
    </svg>
  );
};

export { StopSignIcon };
