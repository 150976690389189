import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { Spacer } from 'components';
import { useUrlHash } from 'hooks';
import DataLayerLinkLogo from 'assets/images/DatalayerLinkLogo.png';
import styled from 'styled-components';

const Body = styled('div')`
  font-family: Arial, sans-serif;
  background-color: #f1f9ff;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    text-align: center;
    padding: 20px;
  }
  .message {
    margin-bottom: 30px;
  }
  .advantages {
    text-align: left;
    display: inline-block;
    text-align: center;
  }
  .button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
  }
  .button:hover {
    background-color: #2a3ba4;
  }
`;

const DatalayerLinkModal = ({ onClose = _.noop }) => {
  const [showModal, setShowModal] = useState(true);
  const [hashIsActive, setHashIsActive] = useUrlHash('datalayer-link');

  useEffect(() => {
    if (hashIsActive) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [hashIsActive, setShowModal]);

  const handleClose = useCallback(() => {
    setHashIsActive(false);
    onClose();
  }, [onClose]);

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="7xl"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header></Modal.Header>
      <img src={DataLayerLinkLogo} alt="DataLayer Link" width="100%" />
      <Modal.Body>
        <Spacer size="20" />
        <Body>
          <div class="container">
            <div class="message">
              <p>
                Oops! It seems the link you followed may no longer be active or
                it might never have existed. But don't worry, you're at the
                right place to explore endless possibilities with DataLayer
                Storage!
              </p>
            </div>
            <div class="advantages">
              <p>
                With DataLayer Storage, you can effortlessly mirror and index
                your DataLayer Stores. Here's why you'll love our platform:
              </p>
              <ul>
                <li>
                  <strong>Seamless Indexing:</strong> Get your data indexed
                  quickly and made easily accessible through DataLayer Link.
                </li>
                <li>
                  <strong>Free Mirroring:</strong> Create mirrors of your data
                  at no cost and with minimal setup.
                </li>
                <li>
                  <strong>Enhanced Visibility:</strong> Expose your data to a
                  broader audience and unlock new insights.
                </li>
              </ul>
              <p>
                Ready to leverage the full potential of your data on DataLayer? Sign in to
                your account or{' '}
                <a href="https://datalayer.storage/signup" class="button">
                  Create an Account
                </a>{' '}
                and start adding new mirrors today!
              </p>
            </div>
          </div>
        </Body>
      </Modal.Body>
    </Modal>
  );
};

export { DatalayerLinkModal };
