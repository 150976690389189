import _ from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParamState = (name, defaultValue) => {
  const [param, setParam] = useState();
  const location = useLocation();

  const setQueryStringParameter = useCallback(
    (value) => {
      let params = new URLSearchParams(window.location.search || window.location.hash.replace(/#.*\?/, ""));

      if (_.isNil(value) || value === '') {
        params.delete(name);
      } else {
        params.set(name, value);
      }

      const newPath = params.toString()
        ? `${window.location.pathname}?${params}`
        : window.location.pathname;
      

      window.history.pushState({}, '', decodeURIComponent(`${newPath}${window.location.hash}`));
      setParam(value);
    },
    [location],
  );

  const value = useMemo(() => {
    const params = new URLSearchParams(
      window.location.search || window.location.hash.replace(/#.*\?/, ''),
    );
    return params.get(name) || defaultValue || '';
  }, [location, param, name]);

  return [value, setQueryStringParameter];
};

export { useQueryParamState };
