import React from 'react';
import { withTheme } from 'styled-components';

const BellIcon = withTheme(({ color, theme, width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00009 0.400024C7.09053 0.400024 5.25918 1.15859 3.90892 2.50886C2.55866 3.85912 1.80009 5.69047 1.80009 7.60002V11.9032L0.951686 12.7516C0.783915 12.9194 0.669667 13.1332 0.623386 13.366C0.577105 13.5987 0.600869 13.84 0.691673 14.0592C0.782477 14.2785 0.936244 14.4658 1.13354 14.5977C1.33083 14.7296 1.56279 14.8 1.80009 14.8H16.2001C16.4374 14.8 16.6693 14.7296 16.8666 14.5977C17.0639 14.4658 17.2177 14.2785 17.3085 14.0592C17.3993 13.84 17.4231 13.5987 17.3768 13.366C17.3305 13.1332 17.2163 12.9194 17.0485 12.7516L16.2001 11.9032V7.60002C16.2001 5.69047 15.4415 3.85912 14.0913 2.50886C12.741 1.15859 10.9096 0.400024 9.00009 0.400024ZM9.00009 19.6C8.04531 19.6 7.12963 19.2207 6.4545 18.5456C5.77937 17.8705 5.40009 16.9548 5.40009 16H12.6001C12.6001 16.9548 12.2208 17.8705 11.5457 18.5456C10.8705 19.2207 9.95486 19.6 9.00009 19.6Z"
        fill={color || theme.surface.slot5.background.hex}
      />
    </svg>
  );
});

export { BellIcon };
