import _ from 'lodash';
import React from 'react';
import { Modal, Button } from 'flowbite-react';
import { CheckMirrorConnectionForm, Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const CheckMirrorConnectionModal = ({ onClose = _.noop }) => {
  const navigate = useNavigate();
  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="enter-ip4-or-ip6" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="25" />
        <FormattedMessage id="file-propagation-server-explaination" />
        <Spacer size="25" />
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button color="light" onClick={() => navigate('/prerequisites')}>
            <FormattedMessage id="dataLayer-prerequisites-documentation" />
          </Button>
        </div>

        <Spacer size="25" />
        <CheckMirrorConnectionForm onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export { CheckMirrorConnectionModal };
