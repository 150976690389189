import React from 'react';
import { withTheme } from 'styled-components';

const CircleCheckmark = withTheme(({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22ZM16.6337 10.3837C16.8614 10.148 16.9874 9.83224 16.9846 9.5045C16.9817 9.17675 16.8503 8.86324 16.6185 8.63148C16.3868 8.39972 16.0732 8.26826 15.7455 8.26541C15.4178 8.26256 15.102 8.38855 14.8663 8.61625L10.75 12.7325L9.13375 11.1163C8.898 10.8886 8.58224 10.7626 8.2545 10.7654C7.92675 10.7683 7.61324 10.8997 7.38148 11.1315C7.14972 11.3632 7.01826 11.6768 7.01541 12.0045C7.01256 12.3322 7.13855 12.648 7.36625 12.8837L9.86625 15.3837C10.1007 15.6181 10.4185 15.7497 10.75 15.7497C11.0815 15.7497 11.3993 15.6181 11.6337 15.3837L16.6337 10.3837Z"
        fill="#0E9F6E"
      />
    </svg>
  );
});

export { CircleCheckmark };
