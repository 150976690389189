import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Template,
  UnauthenticatedTemplate,
} from 'components';
import * as Pages from 'pages';
import ROUTES from './route-constants';

const AppNavigator = () => {

  const appStore = useSelector((state) => state.app);

  return (
    <>
      <Router>
        <Routes>
          <Route // remove trailing slash
            path="*(/+)"
            loader={({ params }) => redirect(params['*'] || '/')}
          />
          {appStore?.auth?.accessToken ? (
            <Route path="" element={<Template />}>
              <Route
                exact
                path="/"
                element={<Navigate to={ROUTES.MY_MIRRORS} />}
              />

              <Route path={ROUTES.MY_FILES} element={<Pages.MyFilesPage />} />
              <Route
                path={ROUTES.MY_MIRRORS}
                element={<Pages.MyMirrorsPage />}
              />
              <Route
                path={ROUTES.ACCESS_KEYS}
                element={<Pages.AccessKeysPage />}
              />

              <Route
                path={ROUTES.NOTIFICATIONS}
                element={<Pages.Notifications />}
              />

              <Route
                path={ROUTES.PREREQUISITES}
                element={<Pages.Prerequisites />}
              />

              <Route path={ROUTES.PROXY} element={<Pages.Proxy />} />

              <Route
                path={ROUTES.MY_XCH_TOP_OFFS}
                element={<Pages.MyTopOffsPage />}
              />

              <Route path={ROUTES.ACCOUNT} element={<Pages.Account />} />
              <Route path={ROUTES.HOW_TO} element={<Pages.HowTo />} />

              <Route path={ROUTES.DOWNLOADS} element={<Pages.Downloads />} />

              <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
              <Route
                path="/terms-of-service"
                element={<Pages.TermsOfService />}
              />

              <Route
                path="/forbidden"
                element={<Pages.ErrorBoundaryForbidden />}
              />

              <Route path="/contact" element={<Pages.Contact />} />

              <Route path="/404" element={<Pages.ErrorBoundary404 />} />

              <Route
                path="*"
                element={<Navigate replace to={ROUTES.MY_MIRRORS} />}
              />
            </Route>
          ) : (
            <Route path="" element={<UnauthenticatedTemplate />}>
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/register" element={<Pages.NewUser />} />
              <Route path="/contact" element={<Pages.Contact />} />
              <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
              <Route path="/roadmap" element={<Pages.Roadmap />} />
              <Route
                path="/terms-of-service"
                element={<Pages.TermsOfService />}
              />
              <Route path="/" element={<Pages.Home />} />
              <Route path="*" element={<Navigate replace to="/login" />} />
            </Route>
          )}
        </Routes>
      </Router>
    </>
  );
};

export { AppNavigator };
