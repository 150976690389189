import * as yup from 'yup';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, Button, Spinner } from 'flowbite-react';
import { useLoginMutation } from 'api/dlaas/user';
import { setLoginCredentials, clearCachedUserData } from 'store/slices/app';
import { setSaveTokenToLocalStorage } from 'store/slices/userOptions';
import { ToggleSwitch, Container, Banner } from 'components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQueryParamState } from 'hooks';
import { useNavigate, Link } from 'react-router-dom';

const validationSchema = yup.object({
  username: yup.string().required('User name is required.'),
  password: yup.string().required('Password is required.'),
});

const LoginForm = ({ header, footer }) => {
  const userNameRef = useRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.app);
  const [triggerLogin, { isLoading, isError }] = useLoginMutation();
  const userOptionsStore = useSelector((state) => state.userOptions);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [loginRedirect] = useQueryParamState('redirect');

  const [rememberMe, setRememberMe] = useState(
    userOptionsStore.saveTokenToLocalStorage,
  );

  const initialValues = {
    username: appStore?.user?.username || '',
    password: '',
  };

  useEffect(() => {
    userNameRef.current.select();
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ username, password }) => {
      const loginResponse = await triggerLogin({
        username,
        password,
      });

      if (loginResponse.error) {
        if (loginResponse.error.status == 400) {
          setErrorMessage(intl.formatMessage({id: 'invalid-credentials'}));
        } else {
          setErrorMessage(loginResponse.error.data?.message);
        }
      } else {
        dispatch(setLoginCredentials(loginResponse.data));
        dispatch(setSaveTokenToLocalStorage(rememberMe));
        if (loginRedirect) {
          setTimeout(() => navigate(loginRedirect), 100);
        }
      }
    },
  });

  const handleUsernameOnChange = useCallback((...params) => {
    dispatch(clearCachedUserData());
    formik.handleChange(...params);
  });

  const handleRememberMe = useCallback(
    () => setRememberMe(!rememberMe),
    [rememberMe, setRememberMe],
  );

  return (
    <form
      className="flex flex-col gap-4"
      style={{ width: 317 }}
      onSubmit={formik.handleSubmit}
    >
      {errorMessage && <Banner type="error">{errorMessage}</Banner>}

      {header}
      <div>
        <TextInput
          id="username"
          ref={userNameRef}
          type="text"
          placeholder={intl.formatMessage({ id: 'username-or-email' })}
          autoComplete="username"
          value={formik.values.username}
          required={true}
          onChange={handleUsernameOnChange}
        />
      </div>
      <div>
        <TextInput
          id="password"
          type="password"
          placeholder={intl.formatMessage({ id: 'password' })}
          autoComplete="password"
          value={formik.values.password}
          required={true}
          onChange={formik.handleChange}
        />
      </div>
      <div className="flex items-center gap-2" style={{ alignSelf: 'center' }}>
        <div className="flex flex-col gap-4" id="toggle">
          <ToggleSwitch
            checked={rememberMe}
            label={intl.formatMessage({ id: 'remember-me' })}
            onChange={handleRememberMe}
          />
        </div>
      </div>
      {footer}
      <p style={{fontSize: '0.8rem', color: 'white'}}>
        By using this service you agree to our{' '}
        <Link to="/terms-of-service">Terms of Service</Link> and{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>
      <Container align="center" padding="0">
        <Button style={{ width: 122 }} type="submit">
          {isLoading && !isError ? (
            <Spinner
              aria-label={intl.formatMessage({
                id: 'processing-new-account',
              })}
              size="sm"
            />
          ) : (
            <FormattedMessage id="login" />
          )}
        </Button>
      </Container>
    </form>
  );
};

export { LoginForm };
