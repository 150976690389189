import React, { useMemo } from 'react';
import { PageTitle } from 'components';
import { useListInstallerFilesQuery } from 'api/dlaas/system';
import { useIntl } from 'react-intl';
import { Spinner } from 'flowbite-react';
import { Accordion } from 'flowbite-react';

const Download = ({ title, description }) => {
  return (
    <li className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-lg font-semibold leading-6 text-gray-900">
            {title}
          </p>
        </div>
        <div className="mt-1 items-center gap-x-2 text-xs leading-5 text-gray-500">
          {description}
        </div>
      </div>
    </li>
  );
};

const Downloads = () => {
  const intl = useIntl();
  const { data, isLoading } = useListInstallerFilesQuery();

  // eslint-disable-next-line no-unused-vars
  const linuxDeb = useMemo(() => {
    if (!data) {
      return null;
    }
    return data['datalayer-storage-uploader-linux-x64-deb-latest.deb'];
  }, [data]);

  // eslint-disable-next-line no-unused-vars
  const windowsExe = useMemo(() => {
    if (!data) {
      return null;
    }
    return data['DatalayerStorageInstaller-latest.exe'];
  }, [data]);

  const windowsMirrorServiceExe = useMemo(() => {
    if (!data) {
      return null;
    }
    return data['DlMirrorSync-service-win-x64-latest.msi'];
  }, [data]);

  const linuxMirrorServiceDeb = useMemo(() => {
    if (!data) {
      return null;
    }
    return data['datalayer-storage-mirror-sync-linux-x64-deb-latest.deb'];
  }, [data]);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'downloads' })} />
      <h3>
        You can explore the following tools to interact with DataLayer and
        DataLayer Storage to help get the most out of your datalayer experience.
      </h3>
      <Accordion collapseAll>
        <Accordion.Panel>
          <Accordion.Title>Sprout CLI Tool</Accordion.Title>
          <Accordion.Content>
            <Download
              title="Sprout CLI Tool"
              description={
                <>
                  The Sprout tool is an open-source command-line tool that
                  allows you to easily interact with the DataLayer. It is
                  available for Windows, Linux, and macOS. The tool is available
                  through NPM (Node Package Manager),{' '}
                  <a href="https://nodejs.org/" target="_blank">
                    available after installing NodeJS on your machine
                  </a>{' '}
                  and can be installed using the following command:
                  <pre>npm install chia-sprout-cli -g</pre>
                  <p>
                    For more information, please visit the{' '}
                    <a
                      href="https://github.com/MichaelTaylor3D/ChiaSproutCli"
                      target="_blank"
                    >
                      GitHub repository
                    </a>
                    .
                  </p>
                </>
              }
            />
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>DataLayer Upload Plugin</Accordion.Title>
          <Accordion.Content>
            You can hide your IP address from the DataLayer Peer-to-Peer network
            and skip the need to set up your local machine to be a DataLayer
            mirror buy running this software. It will run in the background and
            push all your datalayer files up to https://datalayer.storage which
            will mirror the files on your behalf.
            <ul role="list" className="divide-y divide-gray-100">
              <Download
                title="Linux Installation Instructions"
                description={
                  <div>
                    <h3 style={{ width: 130 }}>
                      {linuxDeb.downloadUrl && (
                        <a
                          href={linuxDeb.downloadUrl}
                          class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Download Deb
                        </a>
                      )}
                    </h3>
                    MD5 Checksum: {linuxDeb.md5}
                    <div>
                      Use the following commands to download and install the
                      package using wget:
                      <div>
                        <pre>wget {linuxDeb.downloadUrl}</pre>
                      </div>
                      <div>
                        <pre>
                          md5sum
                          datalayer-storage-uploader-linux-x64-deb-latest.deb
                        </pre>
                        <br />
                        <p>
                          Compare the output of the above command with the
                          provided checksum. If they match, you can proceed with
                          the installation:
                        </p>
                      </div>
                      <div>
                        <pre>
                          sudo dpkg -i
                          datalayer-storage-uploader-linux-x64-deb-latest.deb
                        </pre>
                      </div>
                    </div>
                  </div>
                }
              />
              <Download
                title="Windows Installer"
                description={
                  <>
                    <h3 style={{ width: 190 }}>
                      {windowsExe.downloadUrl && (
                        <a
                          href={windowsExe.downloadUrl}
                          class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Download .msi installer
                        </a>
                      )}
                    </h3>
                    MD5 Checksum: {windowsExe.md5}
                    <div>
                      Download the installer, then using PowerShell, Compare the
                      output of the below command with the provided checksum. If
                      they match, you can double-click on the installer to start
                      the installation process.
                      <pre>
                        Get-FileHash
                        path\to\DatalayerStorageInstaller-latest.exe -Algorithm
                        MD5
                      </pre>
                    </div>
                  </>
                }
              />
            </ul>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>DataLayer Mirror Sync</Accordion.Title>
          <Accordion.Content>
            The DataLayer Mirror Sync is a background service that will monitor
            DataLayer Storage for new stores and automatically subscribe to them
            and mirror them. Running this software helps build up DataLayer
            based decentralized web. You only need to download and run this
            software if your interested in helping build up the DataLayer
            network. There is currently no incentice to run this, but if you do
            your pretty awesome. There is an incentive program being worked out
            for the future.
            <ul role="list" className="divide-y divide-gray-100">
              <Download
                title="Linux Installation Instructions"
                description={
                  <>
                    <h3 style={{ width: 130 }}>
                      {linuxMirrorServiceDeb.downloadUrl && (
                        <a
                          href={linuxMirrorServiceDeb.downloadUrl}
                          class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Download Deb
                        </a>
                      )}
                    </h3>
                    MD5 Checksum: {linuxMirrorServiceDeb.md5}
                    <div>
                      Use the following commands to download and install the
                      package using wget:
                      <div>
                        <pre>wget {linuxMirrorServiceDeb.downloadUrl}</pre>
                      </div>
                      <div>
                        <pre>
                          md5sum
                          datalayer-storage-uploader-linux-x64-deb-latest.deb
                        </pre>
                        <br />
                        <p>
                          Compare the output of the above command with the
                          provided checksum. If they match, you can proceed with
                          the installation:
                        </p>
                      </div>
                      <div>
                        <pre>
                          sudo dpkg -i
                          datalayer-storage-uploader-linux-x64-deb-latest.deb
                        </pre>
                      </div>
                    </div>
                  </>
                }
              />
              <Download
                title="Windows Installer"
                description={
                  <>
                    <h3 style={{ width: 180 }}>
                      {windowsMirrorServiceExe.downloadUrl && (
                        <a
                          href={windowsMirrorServiceExe.downloadUrl}
                          class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          Download .msi installer
                        </a>
                      )}
                    </h3>
                    MD5 Checksum: {windowsMirrorServiceExe.md5}
                    <div>
                      Download the installer, then using PowerShell, Compare the
                      output of the below command with the provided checksum. If
                      they match, you can double-click on the installer to start
                      the installation process.
                      <pre>
                        Get-FileHash
                        path\to\DatalayerStorageInstaller-latest.exe -Algorithm
                        MD5
                      </pre>
                    </div>
                  </>
                }
              />
            </ul>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </>
  );
};

export { Downloads };
