import React from 'react';
import { withTheme } from 'styled-components';

const MagnifyGlassIcon = withTheme(({
  width = 20,
  height = 20,
  color,
  theme
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5999 2.79997C6.32686 2.79997 5.10596 3.30568 4.20579 4.20585C3.30561 5.10603 2.7999 6.32693 2.7999 7.59997C2.7999 8.87301 3.30561 10.0939 4.20579 10.9941C5.10596 11.8943 6.32686 12.4 7.5999 12.4C8.87294 12.4 10.0938 11.8943 10.994 10.9941C11.8942 10.0939 12.3999 8.87301 12.3999 7.59997C12.3999 6.32693 11.8942 5.10603 10.994 4.20585C10.0938 3.30568 8.87294 2.79997 7.5999 2.79997ZM0.399902 7.59997C0.399758 6.46681 0.667073 5.34962 1.18011 4.33926C1.69314 3.32889 2.43741 2.45388 3.35238 1.7854C4.26735 1.11691 5.32718 0.673822 6.44568 0.492167C7.56418 0.310512 8.70976 0.395421 9.78926 0.739988C10.8688 1.08455 11.8517 1.67905 12.6581 2.47512C13.4645 3.2712 14.0717 4.24637 14.4301 5.32133C14.7886 6.39629 14.8883 7.54068 14.7211 8.66143C14.5539 9.78218 14.1245 10.8476 13.4679 11.7712L19.2483 17.5516C19.4669 17.7779 19.5878 18.081 19.5851 18.3956C19.5824 18.7103 19.4562 19.0113 19.2337 19.2338C19.0112 19.4562 18.7102 19.5824 18.3956 19.5852C18.0809 19.5879 17.7778 19.467 17.5515 19.2484L11.7723 13.4692C10.6951 14.2351 9.42792 14.6898 8.10951 14.7833C6.79111 14.8769 5.47239 14.6057 4.29786 13.9995C3.12334 13.3934 2.13833 12.4756 1.45077 11.3468C0.763215 10.218 0.399645 8.92169 0.399902 7.59997V7.59997Z"
        fill={color || theme.primary.slot1.background.hex}
      />
    </svg>
  );
});

export { MagnifyGlassIcon };
