import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import initialState from './app.initialstate';

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetApp: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
      
    },
    handleLogout: (state) => {
      state.auth = {
        accessToken: null,
        refreshToken: null,
      };

      // on logout clear the auth data while retaining the user data
      // so the login screen can display the last logged in user information prefilled
      const savedSessionStorage = JSON.parse(
        window.sessionStorage.getItem('persist:auth') || '{}',
      );

      if (!_.isEmpty(savedSessionStorage)) {
        savedSessionStorage.app.auth = initialState.auth;

        window.sessionStorage.setItem(
          'persist:auth',
          JSON.stringify(savedSessionStorage),
        );
      }

      const savedLocalStorage = JSON.parse(
        window.localStorage.getItem('persist:auth') || '{}',
      );

      if (!_.isEmpty(savedLocalStorage)) {
        savedLocalStorage.app.auth = initialState.auth;

        window.localStorage.setItem(
          'persist:auth',
          JSON.stringify(savedLocalStorage),
        );
      }
    },
    clearCachedUserData: (state) => {
      state.user = initialState.user;
      window.localStorage.removeItem('persist:auth');
      window.sessionStorage.removeItem('persist:auth');
    },
    setLocale: (state, { payload }) => {
      state.locale = payload;
    },
    setCsrftoken: (state, { payload }) => {
      state.csrftoken = payload;
    },
    setLoginCredentials: (
      state,
      { payload: { access_token, refresh_token } },
    ) => {
      state.auth.accessToken = access_token;
      state.auth.refreshToken = refresh_token;
    },
    setToken: (state, { payload }) => {
      state.auth.accessToken = payload.accessToken;
      state.auth.refreshToken = payload.refreshToken;
    },
    setAvatar: (state, { payload }) => {
      state.user.avatar = payload;
    },
    forceBreadCrumbRefresh: (state) => {
      state.breadcrumbSeed = Math.random();
    },
  },
});

export const {
  resetApp,
  setLocale,
  setLoginCredentials,
  setToken,
  handleLogout,
  clearCachedUserData,
  setAvatar,
  forceBreadCrumbRefresh,
  setCsrftoken,
} = appSlice.actions;

export default appSlice.reducer;
