import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useCreateMirrorMutation } from 'api/dlaas/mirror';
import { Container, Spacer } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import {  Alert } from 'flowbite-react';

const initialValues = {
  label: '',
  storeId: '',
};

const validationSchema = yup.object({
  label: yup.string().required('Label is required.'),
  storeId: yup
    .string()
    .required('Store ID is required')
    .length(64, 'A valid store ID is 64 characters long'),
});

const NewMirrorForm = withTheme(({ onClose, theme }) => {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [createNewMirror, { isLoading }] = useCreateMirrorMutation();
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitted(true);
      const response = await createNewMirror(values);
      if (response.error) {
        setSubmitted(false);
        setError(response.error.message);
        return;
      } else {
        onClose();
      }
    },
  });

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {error && <Alert type="error">{error}</Alert>}
      <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <div>
          <div
            className={`mb-2 block text-base font-normal text-${theme.surface.slot1.text.class}`}
          >
            <FormattedMessage id="enter-mirror-information" />
            <Spacer size="10" />
          </div>
          <div>
            <TextInput
              id="label"
              placeholder={intl.formatMessage({ id: 'label' })}
              value={formik.values.label}
              onChange={formik.handleChange}
            />
            {formik.errors.label ? <div>{formik.errors.label}</div> : null}
          </div>
          <br />
          <div>
            <TextInput
              id="storeId"
              placeholder={intl.formatMessage({ id: 'storeId' })}
              value={formik.values.storeId}
              onChange={formik.handleChange}
            />
            {formik.errors.storeId ? <div>{formik.errors.storeId}</div> : null}
          </div>
        </div>
      </div>

      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <FormattedMessage id={submitted ? 'ok' : 'submit'} />
          )}
        </Button>
      </Container>
    </form>
  );
});

export { NewMirrorForm };
