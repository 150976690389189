import { Timeline } from 'flowbite-react';
import { PageTitle } from 'components';
import Step1 from 'assets/images/activate-server.png';
import Step2 from 'assets/images/host_ip.png';
import Step3 from 'assets/images/forward-port.png';
import CreateMirrorCmd from 'assets/images/chia-create-mirror.png';
import { FormattedMessage } from 'react-intl';

const Prerequisites = () => {
  return (
    <div style={{ margin: 25 }}>
      <PageTitle title="Steps to prepare your DataLayer node." />
      <h4>Follow these steps if you are trying to run a mirror from your machine. If you would like to skip these requirements you can use the <a href="/how-to">DataLayer Storage Upload Plugin</a></h4>
      <Timeline>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              <FormattedMessage id="step1" />
            </Timeline.Time>
            <Timeline.Title>
              <FormattedMessage id="activate-datalayer" />
            </Timeline.Title>
            <Timeline.Body>
              <img src={Step1} alt="Step 1" width="900px" />
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              <FormattedMessage id="step2" />
            </Timeline.Time>
            <Timeline.Title>
              <FormattedMessage id="configure-router" />
            </Timeline.Title>
            <Timeline.Body>
              <FormattedMessage id="configure-router-message" />
              <img src={Step3} alt="Step 2" />
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              <FormattedMessage id="step3" />
            </Timeline.Time>
            <Timeline.Title>
              <FormattedMessage id="chia-ip-host-settings" />
            </Timeline.Title>
            <Timeline.Body>
              <FormattedMessage id="chia-ip-host-settings-1" />
              <ol>
                <li>
                  <FormattedMessage id="chia-ip-host-settings-2" />
                </li>
                <li>
                  <FormattedMessage id="chia-ip-host-settings-3" />
                </li>
                <li>
                  <FormattedMessage id="chia-ip-host-settings-4" />
                </li>
                <li>
                  <FormattedMessage id="chia-ip-host-settings-5" />
                </li>
                <li>
                  <FormattedMessage id="chia-ip-host-settings-6" />
                </li>
              </ol>
              <FormattedMessage id="chia-ip-host-settings-7" />
              <div>
                <strong>
                  <FormattedMessage id="chia-ip-host-settings-8" />
                </strong>
              </div>
              <img src={Step2} alt="Step 3" />
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              <FormattedMessage id="step4" />
            </Timeline.Time>
            <Timeline.Title>Mirror your DataLayer Store</Timeline.Title>
            <Timeline.Body>
              <FormattedMessage id="dont-forget-your-mirror" />
              <img src={CreateMirrorCmd} alt="Create Mirror Coin" />
              <div>
                <a
                  href="https://docs.chia.net/datalayer-cli#add_mirror"
                  target="_blank"
                >
                  <FormattedMessage id="add-mirror-official-link" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.npmjs.com/package/chia-sprout-cli"
                  target="_blank"
                >
                  <FormattedMessage id="sprout-tool-alternative" />
                </a>
              </div>
            </Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export { Prerequisites };
