import * as swatches from './utils/swatches';

const hexToRgba = (hex, opacity) => {
  opacity = opacity || 1;
  hex = hex.replace(/[^0-9A-F]/gi, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${[r, g, b, opacity].join(',')})`;
};

const headings = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '36px',
  xl: '43px',
  xxl: '60px',
};

/**
 * Values should be hex values and not rgb/rgba
 * https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * @type {ThemeVariant}
 */
const colors = {
  light: {
    surface: {
      slot1: swatches.SURFACE_01,
      slot2: swatches.SURFACE_02,
      slot3: swatches.SURFACE_03,
      slot4: swatches.SURFACE_04,
      slot5: swatches.SURFACE_05,
      slot6: swatches.SURFACE_06,
    },
    primary: {
      slot1: swatches.PRIMARY_01,
      slot2: swatches.PRIMARY_02,
      slot3: swatches.PRIMARY_03,
      slot4: swatches.PRIMARY_04,
    },
    success: {
      slot1: swatches.SUCCESS_01,
      slot2: swatches.SUCCESS_02,
      slot3: swatches.SUCCESS_03,
    },
    error: {
      slot1: swatches.ERROR_01,
      slot2: swatches.ERROR_02,
      slot3: swatches.ERROR_03,
    },
    warning: {
      slot1: swatches.WARNING_01,
      slot2: swatches.WARNING_02,
      slot3: swatches.WARNING_03,
    },
    info: {
      slot1: swatches.INFO_A01,
      slot2: swatches.INFO_A02,
      slot3: swatches.INFO_B01,
      slot4: swatches.INFO_B02,
      slot5: swatches.INFO_C01,
      slot6: swatches.INFO_C02,
    },
  },
  dark: {
    surface: {
      slot1: swatches.SURFACE_04,
      slot2: swatches.SURFACE_07,
      slot3: swatches.SURFACE_08,
      slot4: swatches.SURFACE_06,
      slot5: swatches.SURFACE_01,
      slot6: swatches.SURFACE_09,
    },
    primary: {
      slot1: swatches.PRIMARY_03,
      slot2: swatches.PRIMARY_01,
      slot3: swatches.PRIMARY_05,
      slot4: swatches.PRIMARY_04,
    },
    success: {
      slot1: swatches.SUCCESS_01,
      slot2: swatches.SUCCESS_02,
      slot3: swatches.SUCCESS_03,
    },
    error: {
      slot1: swatches.ERROR_01,
      slot2: swatches.ERROR_02,
      slot3: swatches.ERROR_03,
    },
    warning: {
      slot1: swatches.WARNING_01,
      slot2: swatches.WARNING_02,
      slot3: swatches.WARNING_03,
    },
    info: {
      slot1: swatches.INFO_A03,
      slot2: swatches.INFO_A01,
      slot3: swatches.INFO_B03,
      slot4: swatches.INFO_B01,
      slot5: swatches.INFO_C03,
      slot6: swatches.INFO_C04,
    },
  },
};

const typography = {
  primary: {
    regular: 'NunitoSans',
    semiBold: 'NunitoSansSemiBold',
    bold: 'NunitoSansBold',
    extraBold: 'NunitoSansExtraBold',
    light: 'NunitoSansLight',
  },
};

const theme = {
  colors,
  headings,
  typography,
  hexToRgba,
};

export default theme;
