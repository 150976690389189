import React from 'react';
import { withTheme } from 'styled-components';

const HomeIcon = withTheme(({ width = 16, height = 16, theme, color, style = {} }) => {
  return (
    <div style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.70692 0.293031C8.51939 0.105559 8.26508 0.000244141 7.99992 0.000244141C7.73475 0.000244141 7.48045 0.105559 7.29292 0.293031L0.292919 7.29303C0.110761 7.48163 0.00996641 7.73423 0.0122448 7.99643C0.0145233 8.25863 0.119692 8.50944 0.3051 8.69485C0.490508 8.88026 0.741321 8.98543 1.00352 8.9877C1.26571 8.98998 1.51832 8.88919 1.70692 8.70703L1.99992 8.41403V15C1.99992 15.2652 2.10528 15.5196 2.29281 15.7071C2.48035 15.8947 2.7347 16 2.99992 16H4.99992C5.26514 16 5.51949 15.8947 5.70703 15.7071C5.89456 15.5196 5.99992 15.2652 5.99992 15V13C5.99992 12.7348 6.10528 12.4805 6.29281 12.2929C6.48035 12.1054 6.7347 12 6.99992 12H8.99992C9.26514 12 9.51949 12.1054 9.70703 12.2929C9.89456 12.4805 9.99992 12.7348 9.99992 13V15C9.99992 15.2652 10.1053 15.5196 10.2928 15.7071C10.4803 15.8947 10.7347 16 10.9999 16H12.9999C13.2651 16 13.5195 15.8947 13.707 15.7071C13.8946 15.5196 13.9999 15.2652 13.9999 15V8.41403L14.2929 8.70703C14.4815 8.88919 14.7341 8.98998 14.9963 8.9877C15.2585 8.98543 15.5093 8.88026 15.6947 8.69485C15.8801 8.50944 15.9853 8.25863 15.9876 7.99643C15.9899 7.73423 15.8891 7.48163 15.7069 7.29303L8.70692 0.293031Z"
          fill={color || theme.surface.slot5.background.hex}
        />
      </svg>
    </div>
  );
});

export { HomeIcon };
