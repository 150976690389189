export default {
  HOME: '/home',
  MY_MIRRORS: '/my-mirrors',
  MY_FILES: '/my-files',
  ACCESS_KEYS: '/access-keys',
  MY_XCH_TOP_OFFS: '/blockchain-access',
  DOWNLOADS: '/downloads',
  HOW_TO: '/how-to',
  _404: '/404',
  ACCOUNT: '/account',
  PREREQUISITES: '/prerequisites',
  PROXY: '/proxy',
  NOTIFICATIONS: '/notifications',
};