import React from 'react';

const PeopleWithCloudLock = ({ width = 350, height = 262 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 350 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M350 0H0V262H350V0Z" fill="white" />
      <path
        d="M171.8 246.717C140.196 246.717 108.536 246.096 76.9304 245.531C66.1337 245.337 54.4876 245.733 44.0156 242.87C32.3705 239.687 30.1589 229.828 42.9685 224.922C48.3595 222.857 54.3659 222.603 59.7847 220.606C60.6418 220.29 61.5356 219.886 61.9996 219.135C62.908 217.664 61.6113 215.737 59.9748 215.03C58.3382 214.322 56.4601 214.368 54.7157 213.953C48.5512 212.484 47.2722 204.202 51.1585 200.049C56.2532 194.605 63.89 193.698 71.4677 193.698C73.36 193.698 75.2492 193.754 77.0937 193.811C78.9386 193.867 80.739 193.924 82.4549 193.924C82.5605 193.924 82.6661 193.924 82.7711 193.924C88.5735 193.899 94.3777 193.8 100.182 193.669C110.101 193.447 120.021 193.133 129.934 192.946C156.569 192.444 183.206 192.084 209.846 192.084C216.524 192.084 223.205 192.107 229.884 192.156C244.572 192.262 259.28 192.49 273.922 193.683C281.836 194.329 303.073 193.589 307.137 202.205C307.545 203.068 307.545 203.973 306.934 204.814C305.412 206.906 300.405 208.321 298.867 210.411C296.66 213.41 301.881 215.399 308.286 215.709C313.633 215.966 321.23 217.652 318.214 220.48C317.094 221.532 314.362 222.12 311.698 222.442C309.034 222.764 306.207 222.904 303.751 223.439C300.568 224.128 298.276 225.479 297.792 226.954C296.462 230.991 305.649 232.066 312.686 233.3C316.705 234.005 320.781 235.35 320.765 237.157C320.74 239.751 313.011 240.908 306.837 241.352C293.341 242.323 279.868 243.363 266.317 244.188C234.91 246.098 203.382 246.717 171.8 246.717Z"
        fill="#F7F6F6"
      />
      <path
        d="M76.8499 221.188L76.4727 223.566L80.8432 226.7L83.5946 224.699L81.598 221.943L77.2273 218.809L76.8499 221.188Z"
        fill="#A16652"
      />
      <path
        d="M80.7808 148.192L79.6351 155.417L84.2829 188.253L76.7231 220.381L81.2823 222.075L98.4556 184.338L99.9423 172.589L99.2851 148.04L80.7808 148.192Z"
        fill="#2E4661"
      />
      <path
        d="M111.612 147.904L114.503 178.513L112.011 218.496L107.581 218.863L101.765 182.646L92.6841 146.7L111.612 147.904Z"
        fill="#2E4661"
      />
      <path
        d="M107.555 220.734L112.545 222.735L114.742 220.136L112.147 217.935L107.356 218.333L107.555 220.734Z"
        fill="#A16652"
      />
      <path
        d="M91.391 110.846C90.0947 111.336 88.7985 111.827 87.5023 112.317C86.9999 111.331 86.5764 110.304 86.2369 109.251C85.0278 109.399 83.5689 109.445 82.8561 108.455C82.6317 108.143 82.5187 107.767 82.4094 107.398C81.9579 105.875 81.5064 104.351 81.0547 102.827C80.8986 102.3 80.7407 101.756 80.7985 101.21C80.9002 100.251 81.6398 99.4862 82.4166 98.9166C84.3992 97.4632 86.9954 96.7612 89.3807 97.3503C90.0155 97.5069 90.6453 97.7615 91.1159 98.2164C91.8412 98.9178 92.0782 99.971 92.2816 100.96C92.4692 101.873 92.6567 102.787 92.8443 103.7C93.0601 104.75 93.2305 105.981 92.5091 106.773C92.126 107.194 91.5392 107.418 91.2448 107.905C90.7256 108.764 91.3856 109.842 91.391 110.846Z"
        fill="#EACDB0"
      />
      <path
        d="M96.9922 90.6371C97.0321 90.1808 97.0945 89.7286 97.1944 89.284C97.4112 88.321 97.8054 87.3918 97.8761 86.4072C97.9469 85.4225 97.5934 84.3106 96.7093 83.8746C96.1386 83.5935 95.4644 83.6459 94.8381 83.756C92.7745 84.1185 90.8126 85.0471 89.2224 86.414C87.7079 87.7159 86.5186 89.2178 84.4513 89.4305C82.509 89.6304 81.4485 90.4678 80.038 91.9302C78.5118 93.5129 76.1001 94.9042 74.8846 96.6726C74.3769 97.4114 74.1193 98.2993 74.0168 99.1903C73.6618 102.277 75.2457 105.503 77.9026 107.105C78.4986 107.464 79.1383 107.745 79.7878 107.995C81.1323 108.511 82.7585 109.166 84.1359 108.921C83.6315 107.829 80.9704 104.914 81.3602 103.62C81.9789 101.566 85.1867 100.584 86.9847 100.122C88.9457 99.6182 91.1503 100.093 93.0432 99.4886C94.4053 99.0537 95.7139 97.6296 96.4666 96.4395C97.3953 94.9702 96.8471 92.3022 96.9922 90.6371Z"
        fill="#3A3029"
      />
      <path
        d="M75.2039 163.964C75.4689 166.757 75.9957 170.502 77.0797 170.513C77.2829 170.516 77.4632 170.394 77.6269 170.278C79.3557 169.05 80.4959 167.475 82.2025 166.285C83.991 165.037 86.1101 164.237 88.2064 163.605C91.3389 162.643 94.5684 161.895 97.8234 161.455C101.357 160.978 108.333 160.866 108.333 160.866C110.284 160.297 112.106 159.386 113.938 158.525C117.086 157.046 120.318 155.688 123.213 153.794C124.142 153.186 126.693 151.412 125.567 150.21C124.714 149.301 122.135 148.927 120.936 148.448C117.031 146.885 113.536 144.575 111.025 141.134C107.586 136.422 105.11 130.267 103.22 124.761C102.869 123.741 102.547 122.712 102.236 121.68C102.059 121.094 101.796 118.83 101.315 118.505C96.4421 115.214 94.7489 112.027 89.4855 113.488C77.476 116.822 78.4066 117.264 76.3799 123.591C75.565 126.135 74.8362 132.794 74.7203 135.439C74.5799 138.643 74.4728 147.212 74.4769 150.417C74.4812 153.995 74.6155 157.572 74.9717 161.13C75.0112 161.525 75.0781 162.637 75.2039 163.964Z"
        fill="#527DA1"
      />
      <path
        d="M96.7838 106.371L103.452 114.681L82.5712 123.474L79.8988 119.345L96.7838 106.371Z"
        fill="#DEBC4F"
      />
      <path
        d="M84.9333 110.64L90.7919 111.242L100.807 116.115L110.265 154.8L94.1825 155.981L90.6201 122.167L81.2885 118.969L78.4861 116.821L84.9333 110.64Z"
        fill="#EFCB55"
      />
      <path
        d="M232.684 72.818C222.269 61.4893 205.718 58.883 192.904 66.0861C192.88 66.0993 192.855 66.1067 192.83 66.1177C188.991 68.7597 186.214 72.7776 185.88 78.1548C185.807 79.3361 184.062 79.2533 184.136 78.0634C184.493 72.3002 187.321 67.9333 191.304 64.9913C182.62 49.8953 165.159 41.8934 149.266 47.0255C133.627 52.0761 123.728 68.054 124.56 85.3318C125.979 85.3518 127.405 85.5085 128.826 85.8242C134.476 87.0793 139.414 90.3869 143.416 94.8019C144.208 95.6752 142.96 96.9299 142.171 96.0595C140.854 94.607 139.409 93.2817 137.852 92.1272C132.074 87.8422 124.872 86.184 118.153 88.1238C111.363 90.0839 104.887 95.7538 101.53 102.203C97.6823 109.593 96.7859 118.563 99.4677 126.747C102.1 134.78 107.91 141.472 115.277 144.924C122.11 148.126 129.292 148.612 136.47 147.21C141.177 146.29 145.861 145.251 150.542 144.209C163.922 141.23 177.271 138.114 190.615 134.979C196.477 133.601 202.338 132.218 208.199 130.828C213.012 129.687 218.174 128.892 222.684 126.791C237.434 119.918 245.585 101.974 240.001 85.2618C238.453 80.6273 235.939 76.3583 232.684 72.818Z"
        fill="url(#paint0_linear_74_25712)"
      />
      <path
        d="M129.881 148.034C127.967 148.033 126.066 147.895 124.187 147.604C125.799 147.84 127.422 147.953 129.05 147.953C131.515 147.953 133.992 147.694 136.47 147.21C141.177 146.29 145.861 145.251 150.542 144.209C163.922 141.23 177.271 138.114 190.615 134.979C196.477 133.601 202.338 132.218 208.199 130.828C213.012 129.687 218.174 128.892 222.684 126.791C234.228 121.412 241.729 109.253 241.77 96.2099C241.809 109.331 234.288 121.59 222.684 126.997C221.305 127.639 219.868 128.158 218.396 128.603C216.58 129.018 214.764 129.406 212.994 129.832C206.93 131.289 200.866 132.741 194.8 134.187C193.158 134.578 191.516 134.968 189.874 135.359C176.777 138.435 163.674 141.492 150.542 144.415C145.861 145.457 141.177 146.497 136.47 147.416C135.609 147.585 134.748 147.725 133.888 147.837C132.547 147.966 131.211 148.034 129.881 148.034Z"
        fill="#E5E5E5"
      />
      <path
        d="M129.05 147.953C127.422 147.953 125.799 147.84 124.187 147.604C122.064 147.275 119.967 146.752 117.907 146.016C117.961 145.958 117.996 145.865 118.069 145.852C126.976 144.285 133.849 143.085 141.056 141.261C163.641 135.565 187.451 129.402 209.314 121.399C217.642 118.351 225.367 113.132 232.311 107.298C239.069 101.62 241.216 93.049 239.705 84.6206C239.808 84.9018 239.906 85.1842 240.001 85.4681C241.191 89.0321 241.757 92.6531 241.77 96.2099C241.729 109.253 234.228 121.412 222.684 126.791C218.174 128.892 213.012 129.687 208.199 130.828C202.338 132.218 196.477 133.601 190.615 134.979C177.271 138.114 163.922 141.23 150.542 144.209C145.861 145.251 141.177 146.29 136.47 147.21C133.992 147.694 131.515 147.953 129.05 147.953Z"
        fill="#5B87AD"
      />
      <path
        d="M281.081 219.31L281.305 221.553L277.235 224.346L274.863 222.378L276.786 219.859L280.856 217.068L281.081 219.31Z"
        fill="#426362"
      />
      <path
        d="M281.046 150.727L281.729 157.541L275.606 180.941L281.233 218.56L277.064 219.993L266.107 183.262L264.374 172.578L264.458 149.963L281.046 150.727Z"
        fill="#9E6652"
      />
      <path
        d="M248.976 158.289L257.684 221.322L261.668 221.326L262.426 180.928L269.708 152.58L248.976 158.289Z"
        fill="#9E6652"
      />
      <path
        d="M261.648 221.978L261.644 223.092L257.332 225.351L255.179 223.086L257.336 220.823L261.648 220.827V221.978Z"
        fill="#426362"
      />
      <path
        d="M258.946 112.204C261.954 109.714 269.201 111.155 273.014 111.158C273.801 119.543 275.326 127.917 277.196 136.118C278.81 143.205 281.896 150.116 283.265 157.193C284.486 163.505 287.156 168.044 292.182 172.372C292.964 173.046 293.866 173.922 293.631 174.929C293.489 175.537 292.962 175.968 292.45 176.32C287.375 179.8 280.856 180.157 274.709 180.255C270.039 180.329 265.37 180.321 260.703 180.232C254.621 180.116 247.856 179.393 242.674 175.863C242.346 175.64 241.998 175.352 241.974 174.956C241.953 174.617 242.179 174.32 242.379 174.045C246.005 169.034 245.595 160.74 246.305 154.869C247.11 148.244 247.915 141.6 249.501 135.118C250.528 130.913 251.885 126.788 253.546 122.793C254.812 119.751 256.326 114.768 258.699 112.428C258.778 112.35 258.862 112.276 258.946 112.204Z"
        fill="#84A599"
      />
      <path
        d="M255.547 84.9057C253.824 81.9979 251.284 79.7493 248.204 78.4036C246.118 77.493 243.946 77.2691 241.846 77.0525L235.735 76.4227C232.894 76.13 230.341 76.4118 227.93 77.284C224.856 78.3964 222.05 80.6832 220.228 83.558C218.909 85.642 218.108 87.9955 217.844 90.553L214.521 122.919L219.378 123.42L222.717 90.8933C222.847 89.6223 223.076 88.7687 223.623 87.5096C224.049 86.5332 224.984 85.1642 225.754 84.3935C226.779 83.3663 227.706 82.7247 229.054 82.1054C230.281 81.5412 231.474 81.2579 233.038 81.1583C233.104 81.1542 233.314 81.1465 233.841 81.1659C234.059 81.1744 234.296 81.1919 234.582 81.2214L243.139 82.1031C244.432 82.2366 245.292 82.4669 246.55 83.0156C247.524 83.4416 248.891 84.379 249.659 85.15C250.684 86.1778 251.325 87.1049 251.942 88.4559C252.506 89.6873 252.789 90.8812 252.887 92.4484C252.891 92.5147 252.9 92.7257 252.881 93.2526C252.873 93.4729 252.854 93.7067 252.826 93.9883L249.486 126.523L254.343 127.024L257.682 94.497C258.024 91.1537 257.287 87.837 255.547 84.9057Z"
        fill="#8F979B"
      />
      <path
        d="M234.391 135.527L233.52 144.024C233.454 144.656 232.668 145.092 231.762 144.998L231.729 144.995C230.823 144.902 230.141 144.314 230.207 143.683L231.08 135.186C228.352 134.162 226.545 131.394 226.858 128.355C227.228 124.742 230.45 122.115 234.057 122.486C237.662 122.858 240.283 126.088 239.913 129.701C239.602 132.74 237.271 135.081 234.391 135.527ZM210.22 110.182L206.599 145.473C206.109 150.246 209.574 154.513 214.336 155.004L246.758 158.346C251.522 158.837 255.78 155.365 256.27 150.592L259.894 115.302L210.22 110.182Z"
        fill="#EFCB55"
      />
      <path
        d="M212.895 154.653C213.36 154.782 213.84 154.876 214.336 154.927L246.758 158.269C251.522 158.76 255.78 155.288 256.27 150.515L257.915 134.481C252.471 159.428 213.076 154.648 212.895 154.653Z"
        fill="#DEBC4F"
      />
      <path
        d="M117.045 110.778L118.395 107.173L120.271 105.767L120.895 106.237L120.269 107.645L122.927 105.456L124.176 106.709L122.61 110.778L119.341 112.899L117.045 110.778Z"
        fill="#EACDB0"
      />
      <path
        d="M79.0767 117.694L105.074 123.731L116.143 109.891L120.669 114.021L109.254 132.298L78.652 131.103L76.3943 123.88L79.0767 117.694Z"
        fill="#2B79C3"
      />
      <path
        d="M274.464 114.021L255.714 126.945L244.193 119.713L241.718 123.424L255.294 135.403L274.04 126.138L274.464 114.021Z"
        fill="#91B5A8"
      />
      <path
        d="M267.853 101.706C265.403 102.06 263.447 103.073 262.883 105.489C261.738 104.119 261.308 102.18 261.763 100.452C262.22 98.7241 263.552 97.2527 265.224 96.6295C266.595 97.5936 267.563 99.1181 267.853 100.772V101.706Z"
        fill="#4E4E4E"
      />
      <path
        d="M265.199 112.944C263.398 112.092 262.253 110.18 261.926 108.21C261.598 106.241 261.987 104.217 262.597 102.316C264.625 95.9896 272.564 98.1142 274.231 103.649C275.685 108.473 270.439 115.421 265.199 112.944Z"
        fill="#EACDB0"
      />
      <path
        d="M267.363 157.165C266.216 156.291 266.352 154.573 266.056 153.161C265.761 151.748 263.986 150.342 263.048 151.437C261.057 150.615 260.013 148.303 260.021 146.145C260.029 143.987 260.88 141.933 261.716 139.944C263.566 135.537 265.3 131.055 267.279 126.704C269.15 122.591 269.895 118.064 270.651 113.684C271.03 111.489 270.513 109.216 269.593 107.187C268.674 105.159 267.369 103.335 266.086 101.516C265.553 100.759 265.004 99.9526 264.927 99.0292C264.841 98.0082 265.368 97.0072 266.119 96.3118C267.276 95.2421 268.547 94.7963 270.072 94.9494C275.431 95.4864 279.055 99.1529 279.924 104.569C280.657 109.149 279.469 113.785 278.614 118.343C277.328 125.198 277.752 133.359 281.089 139.555C282.448 142.08 283.959 144.632 284.286 147.482C284.614 150.333 283.368 153.606 280.681 154.598C279.631 154.986 278.441 155.006 277.494 155.604C276.5 156.233 276.274 157.496 276.99 158.389C275.571 160.279 274.458 158.635 273.031 158.063C271.291 157.364 268.829 158.284 267.363 157.165Z"
        fill="#4E4E4E"
      />
      <path
        d="M243.637 120.545L242.754 118.267L238.636 117.438L238.016 119.088L240.897 121.566L242.606 122.092L243.637 120.545Z"
        fill="#EACDB0"
      />
      <defs>
        <linearGradient
          id="paint0_linear_74_25712"
          x1="119.532"
          y1="146.072"
          x2="169.997"
          y2="61.924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#003EA0" />
          <stop offset="1" stop-color="#95E5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { PeopleWithCloudLock };
