/**
 * @api {module} MirrorApi
 * @apiDescription Provides access to mirror-related endpoints.
 * @apiVersion 1.0.0
 * @apiName MirrorApi
 * @apiGroup API Modules
 */

import { dlaasApi } from '../';

const mirrorApi = dlaasApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @api {function} useUserMirrorsQuery Get User Mirrors Query
     * @apiDescription This query allows you to retrieve user mirrors.
     * @apiName useUserMirrorsQuery
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for retrieving user mirrors.
     * @apiParam {number} params.page - The page number.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the user mirrors.
     */
    userMirrors: builder.query({
      query: ({ page }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/list`,
        params: { page },
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'mirrors', id: result.user_id }],
    }),

    /**
     * @api {function} useCreateMirrorMutation Create Mirror Mutation
     * @apiDescription This mutation allows you to create a mirror.
     * @apiName useCreateMirrorMutation
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for creating a mirror.
     * @apiParam {string} params.label - The label for the mirror.
     * @apiParam {string} params.storeId - The store ID for the mirror.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the mirror is created successfully.
     */
    createMirror: builder.mutation({
      query: ({ label, storeId }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/create`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          label,
          store_id: storeId,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'mirrors', id: userId },
      ],
    }),

    /**
     * @api {function} useRenameMirrorMutation Rename Mirror Mutation
     * @apiDescription This mutation allows you to rename a mirror.
     * @apiName useRenameMirrorMutation
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for renaming a mirror.
     * @apiParam {string} params.label - The new label for the mirror.
     * @apiParam {string} params.storeId - The store ID for the mirror.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the mirror is renamed successfully.
     */
    renameMirror: builder.mutation({
      query: ({ label, storeId }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/rename`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          label,
          store_id: storeId,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'mirrors', id: userId },
      ],
    }),

    /**
     * @api {function} useDeleteMirrorMutation Delete Mirror Mutation
     * @apiDescription This mutation allows you to delete a mirror.
     * @apiName useDeleteMirrorMutation
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for deleting a mirror.
     * @apiParam {string} params.storeId - The store ID of the mirror to delete.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the mirror is deleted successfully.
     */
    deleteMirror: builder.mutation({
      query: ({ storeId }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/remove/${storeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'mirrors', id: userId },
      ],
    }),

    /**
     * @api {function} useCreateSubdomainMutation Create Subdomain Mutation
     * @apiDescription This mutation allows you to create a subdomain for a mirror.
     * @apiName useCreateSubdomainMutation
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for creating a subdomain.
     * @apiParam {string} params.singletonId - The ID of the singleton for the subdomain.
     * @apiParam {string} params.subdomain - The subdomain to create.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the subdomain is created successfully.
     */
    createSubdomain: builder.mutation({
      query: ({ singletonId, subdomain }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/create-subdomain`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          singleton_id: singletonId,
          subdomain,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'mirrors', id: userId },
      ],
    }),

    /**
     * @api {function} useDeleteSubdomainMutation Delete Subdomain Mutation
     * @apiDescription This mutation allows you to delete a subdomain for a mirror.
     * @apiName useDeleteSubdomainMutation
     * @apiGroup MirrorApi
     *
     * @apiParam {Object} params - The parameters for deleting a subdomain.
     * @apiParam {string} params.subdomain - The subdomain to delete.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves when the subdomain is deleted successfully.
     */
    deleteSubdomain: builder.mutation({
      query: ({ subdomain }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/delete-subdomain`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subdomain,
        }),
        timeout: 900000,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'mirrors', id: userId },
      ],
    }),

    checkMirrorConnection: builder.mutation({
      query: ({ ipAddress, host }) => ({
        url: `${process.env.REACT_APP_API_HOST}/mirrors/v1/check_connection`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hostname: host ?? `http://${ipAddress}:8575`,
        }),
      }),
    }),
  }),
});

export const {
  useUserMirrorsQuery,
  useCreateMirrorMutation,
  useDeleteMirrorMutation,
  useCreateSubdomainMutation,
  useDeleteSubdomainMutation,
  useCheckMirrorConnectionMutation,
  useRenameMirrorMutation,
} = mirrorApi;
