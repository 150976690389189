import _ from 'lodash';
import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const useUrlHash = (name) => {
  const [, setHash] = useState();
  const location = useLocation();

  const setActive = useCallback(
    (isActive) => {
      if (isActive) {
        window.history.pushState(
          {},
          '',
          decodeURIComponent(
            `${window.location.href.replace(window.location.hash, '')}#${name}`,
          ),
        );
      } else {
        window.history.pushState(
          {},
          '',
          decodeURIComponent(
            window.location.href.replace(window.location.hash, ''),
          ),
        );
      }
      setHash(Math.random());
    },
    [location.hash],
  );

  const currentHash = new URLSearchParams(
    window.location.hash.substring(1).replace(/\$.*$/, '').replace(/\?.*/, ''),
  );

  window.currentHash = currentHash;

  const isActive = !_.isNil(currentHash.get(name));

  return [isActive, setActive];
};

export { useUrlHash };
