import React from 'react';
import { withTheme } from 'styled-components';
import heroSubscription from 'assets/images/hero_plant.jpg';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'flowbite-react';
import './style.css'

const Container = styled('div')`
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.surface.slot2.background.hex};
  z-index: 1000;
  padding: 5px;
`;

const TermsOfService = withTheme(({ theme }) => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        className={`border-b bg-${theme.surface.slot1.background.class}`}
      >
        <div style={{ padding: 5 }}>
          <Button color="gray" onClick={() => navigate('/login')}>
            Login
          </Button>
        </div>
        <div style={{ padding: 5 }}>
          <Button onClick={() => navigate('/register')}>Sign Up</Button>
        </div>
      </Container>
      <div aria-hidden="true" className="relative">
        <img
          src={heroSubscription}
          alt=""
          className="h-96 w-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />

        <div
          style={{ top: '50%', width: '100%' }}
          className="absolute mx-auto -mt-12 px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8"
        >
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Terms Of Service
            </h2>
          </div>
        </div>
      </div>
      <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="bg-white px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <h1>Terms of Service</h1>
            <p>Effective Date: July 3, 2023</p>
            <p>
              Welcome to DataLayer.storage. The following Terms of Service
              ("ToS") contain the terms and conditions that govern your use of
              the website and DataLayer.storage Service (as defined below) and
              that provide additional information about DataLayer.storage.
            </p>
            <ol>
              <li>
                <strong>Acceptance of Terms</strong>
                <br />
                By using our website and/or the DataLayer.storage services, you
                agree to these terms and conditions.
              </li>

              <li>
                <strong>Description of Service</strong>
                <br />
                DataLayer.storage ("we", "us", "our") provides a platform via
                its website ("Service") that allows users ("you", "your") to
                store and manage data ("Your Data").
              </li>

              <li>
                <strong>Payment Processing</strong>
                <br />
                We use Stripe.com, a third-party service, to process credit card
                payments. By using our service, you agree that we send necessary
                information required to process payments to Stripe.com. This
                data may include your name, credit card number, expiration date,
                and security code. Please note that we do not store your credit
                card information on our servers, and we do not have direct
                control over the data you provide to Stripe.com. We encourage
                you to review Stripe's own Privacy Policy and Terms of Service
                for further information. By using our service, you consent to
                this data sharing and acknowledge that you are subject to
                Stripe's terms and conditions in respect to the processing of
                your payment information.
              </li>

              <li>
                <strong>Your Data</strong>
                <br />
                You retain all rights and ownership of Your Data. We do not
                claim any ownership rights to Your Data.
              </li>

              <li>
                <strong>No Responsibility for Loss of Your Data</strong>
                <br />
                You acknowledge and agree that we are not responsible for any
                loss or corruption of Your Data, and we disclaim any liability
                regarding the recovery or restoration of Your Data. You are
                solely responsible for protecting and backing up Your Data.
              </li>

              <li>
                <strong>Your Responsibilities</strong>
                <br />
                You are solely responsible for Your Data and your use of the
                Service. You must comply with all applicable laws and
                regulations, and you must not violate the rights of any third
                party.
              </li>

              <li>
                <strong>Blockchain Funds Usage</strong>
                <ol>
                  <li>
                    <strong>Scope and Application</strong>
                    <br />
                    This policy applies to all users of DataLayer.storage's
                    services and is meant to govern the handling of the
                    blockchain currency provided by DataLayer.storage for the
                    purpose of paying fees on behalf of the user.
                  </li>

                  <li>
                    <strong>Blockchain Currency Provision</strong>
                    <br />
                    DataLayer.storage will provide the necessary blockchain
                    currency (specifically XCH, or Chia coin) to cover your
                    required fees. This service is designed to facilitate easy
                    and secure access to blockchain technologies for our users.
                  </li>

                  <li>
                    <strong>Agreement of Usage</strong>
                    <br />
                    By using our service, you agree that any XCH sent to your
                    provided address is solely for the purpose of enabling your
                    blockchain access. This currency remains the property of
                    DataLayer.storage at all times.
                  </li>

                  <li>
                    <strong>No Rights to Excess Funds</strong>
                    <br />
                    The user has no claim to any excess amount of XCH left in
                    their account after their fees have been paid. The remaining
                    XCH is not to be considered as a bonus or reward, nor can it
                    be converted to other forms of currencies or used for other
                    transactions.
                  </li>

                  <li>
                    <strong>Return of Unused Funds</strong>
                    <br />
                    Any unused XCH should be returned to DataLayer.storage at
                    the termination of the subscription if it is not renewed.
                    Failure to return the unused amount may result in penalties
                    and could lead to the termination of your account with
                    DataLayer.storage.
                  </li>

                  <li>
                    <strong>Return Address</strong>
                    <br />
                    The return address for any unused XCH is:
                    xch178k2hz02827c9nslpwfjv0jal9ajsfr4a0et4n5yxlg3mkvk67wqntd82w.
                    Please make sure to double-check this address before
                    returning any unused XCH.
                  </li>

                  <li>
                    <strong>Compliance</strong>
                    <br />
                    Users must agree to this policy and ensure compliance at all
                    times. Non-compliance may lead to immediate termination of
                    services, at the sole discretion of DataLayer.storage.
                  </li>

                  <li>
                    <strong>Updates and Amendments</strong>
                    <br />
                    DataLayer.storage reserves the right to update and amend
                    this policy from time to time. Continued use of the service
                    after such amendments constitutes acceptance of the new
                    terms.
                  </li>

                  <li>
                    <strong>Contact and Clarifications</strong>
                    <br />
                    For any clarifications on this policy, please feel free to
                    reach out to us via our{' '}
                    <Link to="/contact">Contact Form</Link>.
                  </li>
                </ol>
              </li>

              <li>
                <strong>14-Day Money-Back Guarantee</strong>
                <ol>
                  <li>
                    <strong>Refund Eligibility</strong>
                    <br />
                    You have 14 days from the start of your first subscription
                    purchase to request a full refund. Any XCH that was sent to
                    your Chia wallet and not returned will be subtracted from
                    your refund. The amount subtracted will be based on the
                    price of XCH at the time the XCH was issued to your Chia
                    Wallet.
                  </li>
                  <li>
                    <strong>Prorated Refunds</strong>
                    <br />
                    After 14 days, and cancellation of services will be refunded
                    based on the prorated amount based on the remaining time in
                    the month.
                  </li>
                  <li>
                    <strong>Cancellation and Refund Process</strong>
                    <br />
                    To request a cancellation or refund, please use the contact
                    form. Cancellations and refunds will be processed in the
                    order they are received.
                  </li>
                </ol>
              </li>

              <li>
                <strong>Prohibited Content and Activities</strong>
                <ol>
                  <li>
                    <strong>General Prohibition</strong>
                    <br />
                    It is strictly prohibited to use our service for illegal
                    purposes. This includes, but is not limited to, content
                    related to drug trafficking, violence, child pornography,
                    human trafficking, or any other activities that are illegal
                    in your local area. If you are aware of any illegal
                    activities being conducted through our service, please
                    report it to us immediately via our{' '}
                    <Link to="/contact">Contact Form</Link>.
                  </li>

                  <li>
                    <strong>Immediate Action</strong>
                    <br />
                    If we detect such illegal usage of our service, your access
                    will be immediately terminated.
                  </li>

                  <li>
                    <strong>Legal Consequences</strong>
                    <br />
                    If necessary, we will forward any relevant information to
                    the authorities to support investigations into illegal
                    activities.
                  </li>
                </ol>
              </li>

              <li>
                <strong>Changes to the Terms of Service</strong>
                <br />
                We reserve the right to modify these ToS at any time. Your
                continued use of the Service following the posting of any
                changes to the ToS constitutes your acceptance of those changes.
              </li>

              <li>
                <strong>Termination</strong>
                <br />
                We reserve the right, in our sole discretion, to restrict,
                suspend, or terminate your access to the Service, at any time
                and for any reason without prior notice or liability.
              </li>

              <li>
                <strong>Termination</strong>
                <br />
                We reserve the right, in our sole discretion, to restrict,
                suspend, or terminate your access to the Service, at any time
                and for any reason without prior notice or liability.
              </li>

              <li>
                <strong>Disclaimer</strong>
                <br />
                The Service and the content we provide are on an "as is" basis
                without warranties of any kind, either express or implied,
                including, but not limited to, warranties of merchantability,
                fitness for a particular purpose and non-infringement.
              </li>

              <li>
                <strong>Limitation of Liability</strong>
                <br />
                In no event shall we be liable for any direct, indirect,
                incidental, special, exemplary, or consequential damages
                (including, but not limited to, procurement of substitute goods
                or services; loss of use, data, or profits; or business
                interruption) however caused and on any theory of liability,
                whether in contract, strict liability, or tort (including
                negligence or otherwise) arising in any way out of the use of
                the Service, even if advised of the possibility of such damage.
              </li>

              <li>
                <strong>Contact Us</strong>
                <br />
                If you have any questions about these Terms of Service, please
                contact us using our <Link to="/contact">Contact Form</Link>.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
});

export { TermsOfService };
