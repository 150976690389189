import React, { useState, useMemo } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useRequestManualTopoffMutation } from 'api/dlaas/subscription';
import { Container, Spacer, Banner } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { useUserProfileQuery } from 'api/dlaas/user';
import { Link } from 'react-router-dom';
import constants from 'constants';

const RequestXchForm = withTheme(({ onClose, theme }) => {
  const intl = useIntl();
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setError] = useState(false);
  const [requestXch, { isLoading }] = useRequestManualTopoffMutation();

  const maxXchAllowed = useMemo(() => {
    if (!user?.total_topoffs_this_month) {
      return 0.05;
    }

    return 0.05 - user?.total_topoffs_this_month;
  }, [user?.total_topoffs_this_month]);

  const enterpriseSubscription = useMemo(() => {
    if (userDataIsLoading) {
      return false;
    }
    return (
      user?.subscriptions?.find(
        (subscription) =>
          subscription.product === constants.ENTERPRISE_PRODUCT_ID &&
          ['active', 'trialing'].includes(subscription.status),
      ) || 'none'
    );
  }, [userDataIsLoading, user?.subscriptions]);

  const initialValues = {
    xchAddress: enterpriseSubscription?.xchAddress || '',
    requestedAmount: '',
  };

  const validationSchema = yup.object({
    xchAddress: yup
      .string()
      .matches(
        /^xch[a-z0-9]{59}$/,
        'XCH address must start with "xch" and contain 62 alphanumeric characters.',
      )
      .required('Valid XCH Address is required.'),
    requestedAmount: yup
      .number()
      .required('This field is required.')
      .typeError('This field must be a decimal number.')
      .test(
        'is-decimal',
        'The number must be a decimal.',
        (value) => (value + '').match(/^\d+\.\d+$/) !== null,
      )
      .test(
        'is-less-than-0.25',
        `The number must be less than ${maxXchAllowed}.`,
        (value) => value <= maxXchAllowed,
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const result = await requestXch({
        userId: user.id,
        ...values,
      });

      console.log(result);
      if (result.error) {
        setError(result.error.data.message);
      } else {
        setError(null);
        setSubmitted(true);
      }
    },
  });

  if (userDataIsLoading) {
    return <Spinner />;
  }

  if (maxXchAllowed === 0) {
    return (
      <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <div>
          <div className="mb-2 block text-base font-normal">
            We are sorry, you have reached your maximum topoff limit for this
            month. You may request more XCH at the start of the next month.
          </div>
        </div>
        <Container align="center" padding="0">
          <Button type="submit" value="Save" onClick={onClose}>
            <FormattedMessage id={'ok'} />
          </Button>
        </Container>
      </div>
    );
  }
  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {submitted && !isLoading && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div className="mb-2 block text-base font-normal">
              Your request has been submitted, and will be processed shortly.
              You will receive an email when your request has been processed.
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {errorMessage && <Banner type="error">{errorMessage}</Banner>}
      </div>

      {(!submitted || isLoading) && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div
              className={`mb-2 block text-base font-normal text-${theme.surface.slot1.text.class}`}
            >
              You can request a maximum topoff of up to 0.05 XCH per month.
              <br />
              You have requested {user?.total_topoffs_this_month} XCH this
              month.
              <br />
              You have <b>{maxXchAllowed} XCH left</b> to request.
              <br />
              <br />
              Please only request what you need to use datalayer. Requested XCH
              is subject to our{' '}
              <Link to="/terms-of-service">Terms of Service</Link>.
              <Spacer size="10" />
            </div>
            <div>
              <TextInput
                id="xchAddress"
                placeholder={intl.formatMessage({ id: 'new-xch-address' })}
                value={formik.values.xchAddress}
                onChange={formik.handleChange}
              />
              {formik.errors.xchAddress ? (
                <div>{formik.errors.xchAddress}</div>
              ) : null}
            </div>
            <br />

            <div>
              <TextInput
                id="requestedAmount"
                placeholder={0.05}
                value={formik.values.requestedAmount}
                onChange={formik.handleChange}
              />
              {formik.errors.requestedAmount ? (
                <div>{formik.errors.requestedAmount}</div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          {isLoading ? (
            <Spinner
              aria-label={intl.formatMessage({
                id: 'processing-password-reset-request',
              })}
              size="sm"
            />
          ) : (
            <FormattedMessage id={submitted ? 'ok' : 'submit'} />
          )}
        </Button>
      </Container>
    </form>
  );
});

export { RequestXchForm };
