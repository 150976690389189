import dayjs from 'dayjs';

import { useMemo, useState, useCallback } from 'react';
import {
  DataTable,
  PageCounter,
  CreateSubdomainModal,
  DeleteSubdomainModal,
  EditIcon,
  RenameMirrorModal,
  InfoIcon
} from 'components';
import { Pagination, Badge } from 'flowbite-react';
import { Tooltip } from 'react-tooltip';
import { withTheme } from 'styled-components';
import { Button } from 'flowbite-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SkeletonTable } from './SkeletonTable';
import { hasActiveProductKey } from 'utils/helpers';
import { useUserProfileQuery } from 'api/dlaas/user';
import { WorldIcon } from 'components/icons/WorldIcon';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const FileUploadsTable = withTheme(
  ({
    data,
    isLoading,
    currentPage,
    onPageChange,
    totalPages,
    totalCount,
    theme,
  }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [createSubdomainRow, setCreateSubdomainRow] = useState(null);
    const [deleteSubdomainRow, setDeleteSubdomainRow] = useState(null);
    const [renameMirrorRow, setRenameMirrorRow] = useState(null);
    const [hoverRow, setHoverRow] = useState(null);

    const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();

    const handleCreateSubdomain = useCallback(
      async (row) => {
        if (!user) return;

        if (!hasActiveProductKey(user?.subscriptions)) {
          navigate('/account');
          return;
        }

        setCreateSubdomainRow(row);
      },
      [navigate, setCreateSubdomainRow, user],
    );

    const columns = useMemo(() => [
      {
        title: 'Label',
        key: 'name',
        render: (row) => {
          return (
            <a
              onMouseOver={() => setHoverRow(row)}
              onMouseLeave={() => setHoverRow(null)}
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => setRenameMirrorRow(row)}
            >
              {row.name}
              {hoverRow?.singleton_id === row.singleton_id ? (
                <EditIcon width={18} height={18} />
              ) : (
                <div style={{ width: 18, height: 18 }} />
              )}
            </a>
          );
        },
      },
      {
        title: 'Store ID',
        key: 'singleton_id',
        render: (row) => {
          return (
            <div>
              <div>
                Store Files:{' '}
                <a
                  href={`https://cdn.datalayer.storage/${row.singleton_id}/${row.salt}`}
                  target="_blank"
                >
                  {row.singleton_id}
                </a>
              </div>
              <div>
                Web2 Url:{' '}
                <a
                  href={
                    row?.subdomain
                      ? `https://${row?.subdomain}.datalayer.link`
                      : `https://datalayer.link/${row.singleton_id}`
                  }
                  target="_blank"
                >
                  {row?.subdomain &&
                  !['PENDING', 'FAILED', 'DELETING'].includes(row?.subdomain)
                    ? `https://${row?.subdomain}.datalayer.link`
                    : `https://datalayer.link/${row?.singleton_id}`}
                </a>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Subdomain',
        key: 'subdomain',
        renderHeader: () => {
          return (
            <div
              data-tooltip-id="static-webhost-description"
              data-tooltip-content={intl.formatMessage({
                id: 'static-webhost-description',
              })}
              data-tooltip-place="top"
              style={{ display: 'flex' }}
            >
              Static Web Host
              <div style={{ marginLeft: 5 }}>
                <InfoIcon />
              </div>
              <Tooltip style={{ width: 300, textTransform: 'none' }} id="static-webhost-description" />
            </div>
          );
        },
        render: (row) => {
          if (row?.subdomain) {
            if (['PENDING', 'FAILED', 'DELETING'].includes(row?.subdomain)) {
              switch (row?.subdomain) {
                case 'PENDING':
                  return (
                    <Badge
                      style={{
                        width: 75,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      color="warning"
                    >
                      Pending
                    </Badge>
                  );
                case 'FAILED':
                  return (
                    <Button
                      onClick={() => handleCreateSubdomain(row)}
                      color="gray"
                    >
                      Failed
                    </Button>
                  );
                case 'DELETING':
                  return (
                    <Badge
                      style={{
                        width: 75,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      color="indigo"
                    >
                      DELETING
                    </Badge>
                  );
              }
            } else {
              return (
                <Button
                  onClick={() => setDeleteSubdomainRow(row)}
                  color="failure"
                >
                  <FormattedMessage id="delete" />
                </Button>
              );
            }
          } else {
            return (
              <Button onClick={() => handleCreateSubdomain(row)} color="gray">
                <FormattedMessage id="create" />
              </Button>
            );
          }
        },
      },
      {
        title: 'Upload Date',
        key: 'updated_at',
        render: (row) => (
          <span>{dayjs(row.updated_at).format('MM/DD/YYYY')}</span>
        ),
      },
      {
        title: 'Mirror Status',
        key: 'mirror_status',
        render: (row) => {
          switch (row.mirror_status) {
            case null:
            case 'pending':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="warning"
                >
                  Pending
                </Badge>
              );
            case 'active':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="success"
                >
                  Active
                </Badge>
              );
            case 'failed':
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="failure"
                >
                  Failed
                </Badge>
              );
            default:
              return (
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  color="failure"
                >
                  Unknown
                </Badge>
              );
          }
        },
      },
      {
        title: 'Mirror Count',
        key: 'global_mirror_count',
        render: (row) => {
          if (_.isNil(row.global_mirror_count)) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Badge
                  style={{
                    width: 75,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  color="warning"
                >
                  Pending
                </Badge>
              </div>
            );
          }

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <a
                style={{
                  border: 'solid thin #e5e7eb',
                  width: 50,
                  padding: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  background: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                href={`https://datalayer.link/${row?.singleton_id}/mirrors.json`}
                target="_blank"
              >
                {row.global_mirror_count}
              </a>
              <div style={{ padding: 5 }} />
              <a
                href={`https://datalayer.link/${row?.singleton_id}/mirrors.html`}
                target="_blank"
                title="View Mirror Map"
              >
                <WorldIcon />
              </a>
            </div>
          );
        },
      },
    ]);

    if (isLoading || userDataIsLoading) {
      return <SkeletonTable />;
    }

    if (!hasActiveProductKey(user?.subscriptions)) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>
            Private Mirrors is a Premium Feature. Please consider upgrading your
            account to access this feature.
          </div>
          <div>
            <Button onClick={() => navigate('/account')}>Upgrade Now</Button>
          </div>
        </div>
      );
    }

    return (
      <div className="relative" style={{ height: 'calc(100% - 162px)' }}>
        <DataTable columns={columns} data={data} isLoading={isLoading} />
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 20,
              position: 'sticky',
              bottom: -1,
              background: theme.surface.slot6.background.hex,
            }}
          >
            {totalCount > 0 && (
              <>
                <PageCounter
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalCount={totalCount}
                />
                <Pagination
                  currentPage={currentPage}
                  layout="pagination"
                  onPageChange={onPageChange}
                  showIcons={true}
                  totalPages={totalPages || 1}
                />
              </>
            )}
          </div>
        )}
        {createSubdomainRow && (
          <CreateSubdomainModal
            mirror={createSubdomainRow}
            onClose={() => setCreateSubdomainRow(null)}
          />
        )}
        {deleteSubdomainRow && (
          <DeleteSubdomainModal
            subdomain={deleteSubdomainRow?.subdomain}
            onClose={() => setDeleteSubdomainRow(null)}
          />
        )}
        {renameMirrorRow && (
          <RenameMirrorModal
            storeId={renameMirrorRow?.store_id}
            onClose={() => setRenameMirrorRow(null)}
          />
        )}
      </div>
    );
  },
);

export { FileUploadsTable };
