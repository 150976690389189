import React from 'react';

const HexagramLogo = ({
  width = 376,
  height = 435,
  color = 'black',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 376 435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M375.185 204.863L376 152.311H214.275V115.632H375.185L376 63.0798H214.275V0.939931L161.725 0V63.0798H0V115.632H161.725V152.311H0V204.863H161.725V241.541H0V294.094H161.725V330.772H0V383.325H161.725V435H214.275V383.325H375.185L376 330.772H214.275V294.094H375.185L376 241.541H214.275V204.863H375.185Z"
        fill={color}
      />
    </svg>
  );
};

export { HexagramLogo };
