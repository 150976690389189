import React, { useState } from 'react';
import { TextInput, Button } from 'flowbite-react';
import { Container } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { withTheme } from 'styled-components';
import { Alert } from 'flowbite-react';

const initialValues = {

};

const validationSchema = yup.object({

});

const CreateNewNotificationForm = withTheme(({ onClose }) => {
  const [submitted] = useState(false);
  const [error] = useState(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {error && <Alert type="error">{error}</Alert>}
      <div
        style={{
          width: '100%',
          border: '1px solid #e5e7eb',
          borderRadius: '0.375rem',
          padding: '0.75rem 1rem',
        }}
      >
        <h3>Add New Event</h3>
        <div style={{ width: 200 }}>
          <label
            for="eventType"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Event Type
          </label>
          <select
            id="eventType"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={formik.handleChange}
          >
            <option selected>Choose a Event Type</option>
            <option value="STORE_UPDATED">Store Is Updated</option>
          </select>
          {formik.errors.eventType ? (
            <div>{formik.errors.eventType}</div>
          ) : null}
        </div>
        <div style={{ maxWidth: 500, flex: 2 }}>
          <label
            for="storeid"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            StoreID
          </label>
          <TextInput
            id="storeid"
            type="text"
            placeholder="store ID"
            value={formik.values.storeid}
            required={true}
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.storeid ? <div>{formik.errors.storeid}</div> : null}
        <div style={{ width: 200 }}>
          <label
            for="notifitcationType"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Event Type
          </label>
          <select
            id="notificationType"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={formik.handleChange}
          >
            <option selected>Choose a notification type</option>
            <option value="email">Email</option>
            <option value="webhook">WebHook</option>
          </select>
          {formik.errors.notificationType ? (
            <div>{formik.errors.notificationType}</div>
          ) : null}
        </div>
        {formik.values.notificationType === 'webhook' && (
          <div style={{ width: 200 }}>
            <label
              for="postback"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              StoreID
            </label>
            <TextInput
              id="postback"
              type="text"
              placeholder="Postback Url"
              value={formik.values.postback}
              required={true}
              onChange={formik.handleChange}
            />
            {formik.errors.postback ? (
              <div>{formik.errors.postback}</div>
            ) : null}
          </div>
        )}
      </div>

      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          Submit
        </Button>
      </Container>
    </form>
  );
});

export { CreateNewNotificationForm };

