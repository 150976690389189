import React from 'react';
import styled, { withTheme } from 'styled-components';
import {
  LogoIcon,
  LoginButton,
  SignUpButton,
} from 'components';
import headerBackground from 'assets/images/header-background.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import { Link } from 'react-router-dom';
import { CreateNewUserForm } from 'components';
import { SandwhichIcon, DataLayerExperts } from 'components';
import { Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  width: 100%; /* or any other percentage you'd like */
  position: relative;
`;

const HeaderContainer = styled('div')`
  padding: 50px;
  top: 0;
  left: 0;
  width: calc(100% + 10px);
  height: 100%;
  background-image: url(${headerBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 180%;
  margin-left: -10px;
`;

const Header = styled('div')``;

const ButtonGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 450px;
`;


const NewUser = withTheme(() => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <HeaderContainer windowSize={windowSize}>
          <Header>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/')}
              >
                <LogoIcon />
                {windowSize.width > 1074 ? (
                  <span style={{ marginLeft: 15 }}>
                    <DataLayerExperts />
                  </span>
                ) : null}
              </div>
              {windowSize.width > 1002 ? (
                <ButtonGroup>
                  <div class="text-white p-2">
                    <Link to="/roadmap">Roadmap</Link>
                  </div>
                  <div class="text-white p-2">
                    <Link to="/contact">Contact</Link>
                  </div>
                  <Link to="/login">
                    <LoginButton />
                  </Link>
                  <Link to="/register">
                    <SignUpButton />
                  </Link>
                </ButtonGroup>
              ) : (
                <div>
                  <Dropdown inline label={<SandwhichIcon />}>
                    <Dropdown.Item>
                      <Link to="/register">Sign Up</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/login">Login</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/contact">Contact Us</Link>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div className="grid grid-cols-1 md:grid-cols-1 h-full">
                <Container
                  style={{
                    position: 'relative',
                    flexDirection: 'column',
                    marginTop: 100,
                  }}
                  classNames="flex items-center justify-center"
                >
                  <CreateNewUserForm />
                </Container>
              </div>
            </div>
          </Header>
        </HeaderContainer>
      </Container>
    </>
  );
});

export { NewUser };
