import { Caption1 } from 'components';
import { withTheme } from 'styled-components';

const PageCounter = withTheme(({theme, currentPage, totalCount}) => {
return (
  <Caption1>
    Show{' '}
    <span className={`text-${theme.primary.slot1.background.class} font-bold `}>
      {(currentPage - 1) * 10 + 1} -{' '}
      {Math.min((currentPage - 1) * 10 + 10, totalCount)}
    </span>{' '}
    of{' '}
    <span className={`text-${theme.primary.slot1.background.class} font-bold `}>
      {totalCount}
    </span>
  </Caption1>
);
});

export { PageCounter };