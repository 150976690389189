import * as yup from 'yup';
import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useCreateUserMutation } from 'api/dlaas/user';
import { TextInput, Button, Spinner } from 'flowbite-react';
import {
  H1,
  Container,
  AccountCreatedModal,
  Banner,
} from 'components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useUrlHash } from 'hooks';

const validationSchema = (intl) =>
  yup.object({
    username: yup.string().required(
      `${intl.formatMessage({ id: 'username' })} ${intl.formatMessage({
        id: 'is-required',
      })}.`,
    ),
    email: yup
      .string()
      .email('Email must be a valid email')
      .required(
        `${intl.formatMessage({ id: 'email' })} ${intl.formatMessage({
          id: 'is-required',
        })}.`,
      ),
    password: yup
      .string()
      .min(9, intl.formatMessage({ id: 'password-length' }))
      .required(
        `${intl.formatMessage({ id: 'password' })} ${intl.formatMessage({
          id: 'is-required',
        })}.`,
      ),
    confirmPassword: yup
      .string()
      .required(
        `${intl.formatMessage({
          id: 'confirm-password',
        })} ${intl.formatMessage({
          id: 'is-required',
        })}.`,
      )
      .oneOf(
        [yup.ref('password')],
        intl.formatMessage({ id: 'passwords-do-not-match' }),
      ),
  });

const CreateNewUserForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [triggerCreateUser, { isLoading }] = useCreateUserMutation();
  const [, setAccountCreatedHashActive] = useUrlHash('account-created');

  const initialValues = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(intl),
    onSubmit: async ({ username, email, password }) => {
      const response = await triggerCreateUser({
        username,
        email,
        password,
      });

      if (response.data?.success) {
        setAccountCreatedHashActive(true);
      } else {
        if (response.error?.data?.errors) {
          setAccountError(_.values(response.error?.data?.errors));
        } else {
          setAccountError([response.error?.data?.message]);
        }
      }
    },
  });

  const onCloseModal = useCallback(() => {
    navigate(`/login`);
  }, [navigate]);

  return (
    <>
      <form
        className="flex flex-col gap-4"
        style={{ width: 317 }}
        onSubmit={formik.handleSubmit}
      >
        <>
          <Container type="column" align="center">
            <H1 color="white">
              <FormattedMessage id="create-new-account" />
            </H1>
          </Container>

          {submitted && Object.keys(formik.errors).length ? (
            <Banner type="error">
              <Container type="column">
                {Object.keys(formik.errors).map((key) => (
                  <div key={key}>• {formik.errors[key]}</div>
                ))}
              </Container>
            </Banner>
          ) : null}

          {accountError && (
            <Banner type="error">
              {accountError.map((error) => (
                <div key={error}>• {error}</div>
              ))}
            </Banner>
          )}

          <div>
            <TextInput
              id="username"
              type="text"
              placeholder={intl.formatMessage({ id: 'username' })}
              autoComplete="new-username"
              value={formik.values.username}
              required={true}
              onChange={formik.handleChange}
            />
          </div>

          <div>
            <TextInput
              id="email"
              type="text"
              placeholder={intl.formatMessage({ id: 'email' })}
              autoComplete="new-email"
              value={formik.values.email}
              required={true}
              onChange={formik.handleChange}
            />
          </div>

          <div>
            <TextInput
              id="password"
              type="password"
              placeholder={intl.formatMessage({ id: 'password' })}
              autoComplete="new-password"
              value={formik.values.password}
              required={true}
              onChange={formik.handleChange}
            />
          </div>

          <div>
            <TextInput
              id="confirmPassword"
              type="password"
              placeholder={intl.formatMessage({ id: 'confirm-password' })}
              autoComplete="new-password"
              value={formik.values.confirmPassword}
              required={true}
              onChange={formik.handleChange}
            />
          </div>
          <Container type="column" align="center">
            <span style={{color: 'white'}}>
              <FormattedMessage id="already-have-an-account" />{' '}
              <Link to="/login">
                <FormattedMessage id="login" />
              </Link>
            </span>
          </Container>
          <p style={{ fontSize: '0.8rem', color: 'white' }}>
            By using this service you agree to our{' '}
            <Link to="/terms-of-service">Terms of Service</Link> and{' '}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </p>
          <Container align="center" padding="0">
            <Button
              style={{ width: 122 }}
              type="submit"
              onClick={() => setSubmitted(true)}
            >
              {isLoading ? (
                <Spinner
                  aria-label={intl.formatMessage({
                    id: 'processing-new-account',
                  })}
                  size="sm"
                />
              ) : (
                <FormattedMessage id="create" />
              )}
            </Button>
          </Container>
        </>
      </form>
      <AccountCreatedModal onClose={onCloseModal} />
    </>
  );
};

export { CreateNewUserForm };
