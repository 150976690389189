import { useMemo, useState } from 'react';
import { DataTable, PageCounter, DeleteAccessKeyModal } from 'components';
import { Pagination } from 'flowbite-react';
import { withTheme } from 'styled-components';
import { Button } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { SkeletonTable } from './SkeletonTable';

const ClientAccessKeysTable = withTheme(
  ({
    data,
    isLoading,
    currentPage,
    onPageChange,
    totalPages,
    totalCount,
    theme,
  }) => {
    const [selectedRow, setSelectedRow] = useState(null);

    const columns = useMemo(() => [
      {
        title: 'Access Key',
        key: 'access_key',
      },
      {
        title: 'delete',
        key: 'delete',
        render: (row) => {
          return (
            <Button onClick={() => setSelectedRow(row)}>
              <FormattedMessage id="delete" />
            </Button>
          );
        }
      }
    ]);

      if (isLoading) {
        return <SkeletonTable />;
      }

    return (
      <div className="relative" style={{ height: 'calc(100% - 162px)' }}>
        <DataTable columns={columns} data={data} isLoading={isLoading} />
        {!isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 20,
              position: 'sticky',
              bottom: -1,
              background: theme.surface.slot6.background.hex,
            }}
          >
            {totalCount > 0 && (
              <>
                <PageCounter
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalCount={totalCount}
                />
                <Pagination
                  currentPage={currentPage}
                  layout="pagination"
                  onPageChange={onPageChange}
                  showIcons={true}
                  totalPages={totalPages || 1}
                />
              </>
            )}
          </div>
        )}
        {selectedRow && (
          <DeleteAccessKeyModal
            accessKey={selectedRow.access_key}
            onClose={() => setSelectedRow(null)}
          />
        )}
      </div>
    );
  },
);

export { ClientAccessKeysTable };
