import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useResetPasswordMutation } from 'api/dlaas/user';
import { Container, Spacer } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
});

const ResetPasswordForm = withTheme(({ onClose, theme }) => {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [triggerResetPassword, { isLoading }] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      triggerResetPassword(values);
      setSubmitted(true);
    },
  });

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {submitted && !isLoading && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div className="mb-2 block text-base font-normal">
              <FormattedMessage
                id="reset-password-processed"
                values={{ email: formik.values.email }}
              />
            </div>
          </div>
        </div>
      )}

      {(!submitted || isLoading) && (
        <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div
              className={`mb-2 block text-base font-normal text-${theme.surface.slot1.text.class}`}
            >
              <FormattedMessage id="dont-worry-we-can-help" />
              <Spacer size="10" />
            </div>
            <TextInput
              id="email"
              placeholder={intl.formatMessage({ id: 'email' })}
              autoComplete="email"
              required={true}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email ? <div>{formik.errors.email}</div> : null}
          </div>
        </div>
      )}
      <Container align="center" padding="0">
        <Button type="submit" value="Save">
          {isLoading ? (
            <Spinner
              aria-label={intl.formatMessage({
                id: 'processing-password-reset-request',
              })}
              size="sm"
            />
          ) : (
            <FormattedMessage id={submitted ? 'ok' : 'submit'} />
          )}
        </Button>
      </Container>
    </form>
  );
});

export { ResetPasswordForm };
