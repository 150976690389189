import _ from 'lodash';
import React from 'react';
import { Modal } from 'flowbite-react';
import { CreateSubdomainForm, Spacer } from 'components';
import { FormattedMessage } from 'react-intl';

const CreateSubdomainModal = ({ mirror, onClose = _.noop }) => {
  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="create-subdomain" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="25" />
        <CreateSubdomainForm mirror={mirror} onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export { CreateSubdomainModal };
