import React from 'react';
import { withTheme } from 'styled-components';

const ZeroIcon = withTheme(({ width = 394, height = 406 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 394 406"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M196.682 6.34375C309.322 6.34375 394 93.6496 394 203C394 319.646 299.869 399.656 196.682 399.656C90.2387 399.656 0 312.826 0 203C0 97.6145 83.169 6.34375 196.682 6.34375ZM197.318 41.7895C103.425 41.7895 35.5077 119.421 35.5077 203C35.5077 290.068 107.953 363.814 197.318 363.814C279.295 363.814 358.413 299.504 358.413 203C358.492 112.76 286.762 41.7895 197.318 41.7895ZM197 89.7641C131.942 89.7641 115.579 151.061 115.579 203C115.579 254.939 131.942 316.236 197 316.236C262.058 316.236 278.421 254.939 278.421 203C278.421 151.061 262.058 89.7641 197 89.7641ZM197 132.505C199.621 132.505 202.084 132.902 204.308 133.457C208.995 137.501 211.298 143.052 206.771 150.823L163.478 230.278C162.128 220.207 161.969 210.375 161.969 203C161.969 180.163 163.558 132.505 197 132.505ZM229.41 169.14C231.713 181.352 232.031 194.039 232.031 203C232.031 225.917 230.442 273.495 197 273.495C186.276 273.495 171.104 267.389 181.033 252.561L229.41 169.14Z"
        fill="#1C64F2"
      />
    </svg>
  );
});

export { ZeroIcon };
