import React from 'react';
import styled from 'styled-components';

const _Container = styled('div')`
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.align};
  padding: ${(props) => props.padding || '0'}px;
  margin: ${(props) => props.margin || '0'}px;
  flex-direction: ${(props) => props.type};
  width: 100%;
  height: ${(props) => props.height || '100%'};
`;

const Container = ({
  children,
  classNames,
  align,
  padding,
  type,
  width,
  margin,
  height,
  style = {}
}) => {
  return (
    <_Container
      className={`${classNames || ''}`}
      align={align}
      padding={padding}
      margin={margin}
      type={type}
      width={width}
      height={height}
      style={style}
    >
      {children}
    </_Container>
  );
};

export { Container };
