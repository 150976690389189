import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { RequestXchForm, Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { useUrlHash } from 'hooks';

const RequestXchTopoffModal = ({ onClose = _.noop }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [hashIsActive, setHashActive] = useUrlHash('request-xch');

  useEffect(() => {
    if (hashIsActive) {
      setShowForgotPassword(true);
    } else {
      setShowForgotPassword(false);
    }
  }, [hashIsActive, setShowForgotPassword]);

  const handleClose = useCallback(() => {
    setHashActive(false);
    onClose();
  });

  if (!showForgotPassword) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="request-xch" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <RequestXchForm onClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

export { RequestXchTopoffModal };
