import { useEffect } from 'react';
import constants from 'constants';
import { H1, Container } from 'components';
import { withTheme } from 'styled-components';

const PageTitle = withTheme(({ title, theme }) => {
  useEffect(() => {
    document.title = `${constants.APP_NAME} - ${title}`;
  }, [title]);

  return (
    <Container padding={20} height="unset">
      <H1 style={{textTransform: 'capitalize'}} color={theme.primary.slot1.background.hex}>{title}</H1>
    </Container>
  );
});

export { PageTitle };
