import React from 'react';
import styled, { withTheme } from 'styled-components';
import { LogoIcon, LoginButton, SignUpButton } from 'components';
import headerBackground from 'assets/images/header-background.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import { Link, useNavigate } from 'react-router-dom';
import { SandwhichIcon, DataLayerExperts } from 'components';
import { Dropdown } from 'flowbite-react';
import { Timeline } from 'flowbite-react';

const Container = styled('div')`
  width: 100%; /* or any other percentage you'd like */
  position: relative;
`;

const HeaderContainer = styled('div')`
  padding: 50px;
  top: 0;
  left: 0;
  width: calc(100% + 10px);
  height: 100%;
  background-image: url(${headerBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 180%;
  margin-left: -10px;
`;

const Header = styled('div')``;

const ButtonGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 450px;
`;

const Roadmap = withTheme(() => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <HeaderContainer windowSize={windowSize}>
          <Header>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/')}
              >
                <LogoIcon />
                {windowSize.width > 1074 ? (
                  <span style={{ marginLeft: 15 }}>
                    <DataLayerExperts />
                  </span>
                ) : null}
              </div>
              {windowSize.width > 1002 ? (
                <ButtonGroup>
                  <div class="text-white p-2">
                    <Link to="/roadmap">Roadmap</Link>
                  </div>
                  <div class="text-white p-2">
                    <Link to="/contact">Contact</Link>
                  </div>
                  <Link to="/login">
                    <LoginButton />
                  </Link>
                  <Link to="/register">
                    <SignUpButton />
                  </Link>
                </ButtonGroup>
              ) : (
                <div>
                  <Dropdown inline label={<SandwhichIcon />}>
                    <Dropdown.Item>
                      <Link to="/register">Sign Up</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/login">Login</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/contact">Contact Us</Link>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              )}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  width: windowSize.width > 690 ? '75%' : '100%',
                  margin: windowSize.width > 690 ? 100 : '50px 0',
                  backgroundColor: 'white',
                  padding: 25,
                  borderRadius: 10,
                }}
              >
                <h1>
                  Embarking on the Odyssey: Shaping the Future with a
                  Chia-Powered Decentralized Internet
                </h1>

                <Timeline>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 1 - Summer 2023</Timeline.Time>
                      <Timeline.Title>
                        Make DataLayer easy to use.
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          DataLayer.storage has been meticulously engineered to
                          streamline the use of Chia DataLayer, ensuring a
                          user-friendly experience. This sophisticated system
                          offers a suite of tools for seamless data integration
                          onto always-on mirror servers, while facilitating
                          efficient management of your local mirror. It provides
                          the ability to push your datalayer data into an
                          always-on mirror server and fast content delivery
                          network either through direct file uploads or
                          delivered from your own local store.
                        </p>
                        <br />
                        <p>
                          <a href="https://www.npmjs.com/package/chia-sprout-cli">
                            The Sprout tool
                          </a>{' '}
                          stands as a testament to this commitment, specifically
                          crafted to simplify the process of uploading your data
                          onto the DataLayer. With a minimalistic command set,
                          Sprout effortlessly uploads entire folder directories
                          onto the DataLayer, making your data readily
                          accessible for consumption by a wider audience. This
                          design philosophy not only enhances usability but also
                          inspires a more connected and collaborative
                          data-sharing environment.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 2 - Early Fall 2023</Timeline.Time>
                      <Timeline.Title>
                        Make DataLayer Data easy to consume.
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          DataLayer.Link is a revolutionary development, crafted
                          to extend the accessibility of DataLayer data well
                          beyond the Chia Ecosystem. As the first fully
                          functional Web2 Gateway for DataLayer Data, it stands
                          as a significant leap forward in the realm of data
                          accessibility and interoperability.
                        </p>
                        <br />
                        <p>
                          This innovative tool offers an efficient pathway for
                          accessing a diverse array of digital assets, such as
                          NFTs, and facilitates the direct use of entire Single
                          Page JavaScript applications from the DataLayer.
                          Crucially, it enables the decentralization of web
                          asset hosting, democratizing the way data is stored
                          and accessed. By making all data mirrored by DataLayer
                          Storage globally accessible through web browsers,
                          DataLayer.Link not only bridges the technological
                          divide between Web2 and Web3 environments but also
                          champions a more inclusive, interconnected, and
                          decentralized digital world.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 3 - Late Fall 2023</Timeline.Time>
                      <Timeline.Title>
                        Make it easy to power Apps from DataLayer.
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          At the heart of our vision lies DataLayer.Storage,
                          meticulously crafted to serve as the backbone for
                          applications. It's not just a repository of data from
                          various providers; it's a gateway to endless
                          possibilities. Recognizing the need for user-friendly
                          tools to harness this data, we are dedicated to
                          developing a suite of tools that seamlessly integrate
                          DataLayer data into your applications. This is where
                          innovation meets functionality, transforming the way
                          data is utilized in the digital realm.
                        </p>
                        <br />
                        <p>
                          A cornerstone of these tools is the innovative
                          webhooks system. This system revolutionizes data
                          interaction by allowing you to subscribe to data
                          changes, ensuring your application remains synced with
                          the latest updates from DataLayer. Imagine building
                          applications that evolve in real-time, always aligned
                          with the most current decentralized data, a harmony of
                          accuracy and efficiency.
                        </p>
                        <br />
                        <p>
                          Flexibility is key in our approach. Whether your
                          application chooses to consume data directly from
                          DataLayer or through DataLayer Storage, the choice
                          remains yours, catering to diverse preferences and
                          tech stacks. This flexibility is not just a feature;
                          it's a testament to our commitment to adaptability and
                          inclusiveness in technology.
                        </p>
                        <br />
                        <p>
                          The culmination of our efforts is to offer a suite of
                          simple yet powerful APIs, a toolkit for creating
                          applications that not only leverage the strength of
                          DataLayer but also champion the autonomy of data
                          ownership. Envision applications thriving on a
                          decentralized network, where you are not just a user
                          but a custodian of your own data. This is more than
                          just technology; it's a movement towards a
                          decentralized, empowered digital future.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>
                        Step 4 - Late 2023 - Early 2024
                      </Timeline.Time>
                      <Timeline.Title>
                        Decentralize Data Retrieval - Building the DataLayer
                        Army
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          By offering open-source tools for establishing
                          independent Web2 Gateways and mechanisms for
                          discovering mirror stores, we are fostering the
                          development of a robust mesh network of DataLayer Web2
                          providers. This network, inherently decentralized, is
                          supported by a variety of incentive programs designed
                          to encourage active participation and engagement.
                        </p>
                        <br />
                        <p>
                          A prime example of these incentives is the "DataLayer
                          Minion Army" NFT collection, which serves as a unique
                          reward for mirror providers. These NFTs are minted and
                          distributed anonymously, adhering to a first-come,
                          first-served basis. This strategy not only offers
                          exclusivity and recognition to early adopters but also
                          integrates a practical aspect in the operation of the
                          Web2 Gateways. Each gateway is equipped to reveal its
                          "tipping" address, thereby enabling users to directly
                          support the Web2 operators. This feature is especially
                          beneficial for data creators who wish to incentivize
                          the mirroring of their specific data, thereby
                          fostering a collaborative and supportive community
                          within this decentralized ecosystem.
                        </p>
                        <br />
                        <p>
                          The introduction of the "Browser Coin," a new ChiaLisp
                          Puzzle, plays a pivotal role in this ecosystem.
                          Mirroring the functionality of the "Mirror Coin"
                          puzzle, it allows DataLayer Web2 Gateways to publicly
                          announce the stores they are mirroring. This
                          enhancement significantly boosts transparency and
                          connectivity within the network.
                        </p>
                        <br />
                        <p>
                          A significant step forward is the transformation of
                          the DataLayer.Link service into a Routing Service.
                          This evolution enables dynamic routing of requests for
                          DataLayer data throughout the mesh network,
                          introducing an additional layer of decentralization.
                          The blockchain-based nature of "Browser Coin" not only
                          benefits our service but also extends the capability
                          to other platforms, promoting a decentralized routing
                          mechanism.
                        </p>
                        <br />
                        <p>
                          While the domain "https://datalayer.link" is currently
                          centralized, there are ongoing plans to decentralize
                          it in future phases. This step is a testament to our
                          unwavering commitment to creating a decentralized and
                          open digital future, underscoring our dedication to
                          innovation and community-driven development.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 5 - Spring 2024</Timeline.Time>
                      <Timeline.Title>
                        Make DataLayer even easier to use.
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          Our journey forward includes the creation of both
                          desktop and mobile applications, crafted to simplify
                          the user experience with DataLayer. These
                          applications, rooted in the robust DataLayer APIs,
                          will be open-source, embodying our commitment to
                          transparency and community collaboration. Their
                          design, centered around user-friendly interfaces, aims
                          to democratize access to DataLayer, making it an
                          intuitive platform for users of all technical
                          abilities.
                        </p>
                        <br />
                        <p>
                          A flagship initiative in this endeavor is the
                          development of a comprehensive desktop application.
                          This app will revolutionize how users interact with
                          their local DataLayer. It will offer visual tools for
                          managing local datalayers, creating stores, and
                          uploading various types of content like data, files,
                          and images. Additionally, the app will provide
                          functionalities to manage mirrors and track global
                          mirrors that are replicating your data. This project
                          is more than just a tool; it's a gateway to unleashing
                          creativity and innovation, making DataLayer a
                          cornerstone for digital empowerment.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 6 - Spring 2024</Timeline.Time>
                      <Timeline.Title>
                        Decentralize DataLayer Routing from the mesh network -
                        With the "Chia Web Browser and the chia:// protocol".
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          The current use of the "https://datalayer.link" domain
                          is a strategic and necessary compromise to initiate
                          the mesh network and establish DataLayer as a viable
                          solution for data and website providers. This domain
                          serves as a portal into the DataLayer universe,
                          accessible through traditional web browsers. However,
                          our ultimate goal is to transcend this centralized
                          access point in favor of a more decentralized
                          approach.
                        </p>
                        <br />
                        <p>
                          To achieve this, we will develop a custom Chromium
                          Browser, tentatively named the "Chia Web Browser."
                          This innovative browser will either connect directly
                          to your local Chia node or incorporate a light wallet,
                          with the specific details still under consideration.
                          The Chia Web Browser is designed to assume the routing
                          responsibilities currently handled by DataLayer.Link,
                          but it will operate locally, eliminating the need for
                          a centralized service. This browser will be capable of
                          querying both "Browser" and "Mirror" coins and will
                          facilitate direct access to data from the user's local
                          DataLayer and from the mesh network of web2 providers.
                        </p>
                        <br />
                        <p>
                          The successful integration of the Chia Web Browser,
                          combined with the previously outlined steps, is
                          expected to culminate in the creation of a fully
                          decentralized Data Network powered by Chia. This
                          evolution marks a significant leap towards a
                          decentralized digital ecosystem, where data access and
                          sharing are streamlined, secure, and user-driven. It
                          is a complete restructuring of the modern internet and
                          puts data soverignty and privacy back in the hands of
                          the people.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                  <Timeline.Item>
                    <Timeline.Point />
                    <Timeline.Content>
                      <Timeline.Time>Step 7 - TBD</Timeline.Time>
                      <Timeline.Title>
                        Normalize Chia Powered Internet Across the World
                      </Timeline.Title>
                      <Timeline.Body>
                        <p>
                          As we embark on the ambitious journey of reinventing
                          the internet through the power of Chia, we envision a
                          future where our innovative technology becomes a
                          cornerstone of the digital world. This transformative
                          phase will see us reaching out to mainstream browsers,
                          proposing the integration of our cutting-edge
                          technology. This collaboration marks a pivotal step
                          towards our dream of a decentralized internet, a
                          vision that extends far beyond the current digital
                          landscape. Our efforts will not only reach a global
                          audience but also inspire a new era of internet usage,
                          where decentralization is not just a feature, but the
                          very foundation of our digital interactions. Through
                          this endeavor, we aim to reshape the internet, making
                          it more accessible, equitable, and in true alignment
                          with the ethos of a connected world.
                        </p>
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                </Timeline>
                <hr />
                <br />
                <div>
                  <p>
                    If the vision of a Chia Powered Internet resonates with you,
                    if you find yourself inspired by the boundless potential it
                    holds, know that you're not alone. We share in this
                    excitement, this dream of a revolutionary digital future.
                    And now, we invite you to be a part of this transformative
                    journey.
                  </p>
                  <br />
                  <p>
                    Your support, in any form, is a stepping stone towards
                    making this vision a reality.{' '}
                    <a href="/signup">
                      By becoming a premium user for just $10 a month
                    </a>
                    , you contribute directly to the development of this
                    groundbreaking roadmap. But our call extends beyond
                    financial support. We are on the lookout for passionate
                    developers ready to contribute their skills to our
                    open-source software, to weave their creativity and
                    expertise into the fabric of this new internet.
                  </p>
                  <br />
                  <p>
                    For those who can, funding full-time development efforts
                    will accelerate our progress towards this new era. Every
                    contribution, every individual, matters in this collective
                    effort. Together, we're not just building technology; we're
                    crafting a new digital destiny, a world where our
                    interactions, data, and digital experiences are empowered by
                    the potential of Chia. Join us, and let's reimagine our
                    digital future, together.
                  </p>
                </div>
              </div>
            </div>
          </Header>
        </HeaderContainer>
      </Container>
    </>
  );
});

export { Roadmap };
