import * as yup from 'yup';
import constants from 'constants';

export const generateValidationSchema = (schema) => {
  const validation = {};
  Object.keys(schema).forEach((key) => {
    validation[key] = yup.object().shape({
      value: schema[key],
      errorText: yup.string(),
    });
  });
  return yup.object().shape(validation);
};

export const botToPropertiesHashMap = (bots) => {
  const resultsMapArray = [];

  bots.forEach((bot) => {
    const userPropertiesMap = Object.keys(bot?.properties || {}).reduce(
      (map, key) => {
        map[key] = bot?.properties[key]?.value;
        return map;
      },
      {},
    );

    resultsMapArray.push({
      id: bot._id,
      name: bot.name,
      updateAt: bot.updatedAt,
      properties: userPropertiesMap,
    });
  });

  return resultsMapArray;
};

export const globalToPropertiesHashMap = (properties) => {
  const globalPropertiesMap = properties.reduce((map, property) => {
    map[property.name] = property?.value;
    return map;
  }, {});

  return globalPropertiesMap;
};

export const padTimeComponent = (n) => {
  return n < 10 ? '0' + n : n;
};

export const reprojectRange = (min, max, value) => {
  const oldRange = max - min;
  const newRange = 100 - 0;
  const newValue = ((value - min) * newRange) / oldRange + 1;
  return Math.ceil(newValue);
};

export const caseIgnoringRegex = (str) => {
  if (str == 'null' || str == null) {
    return 'null';
  }

  return `{$regex:"${str}",$options:"i"}`;
};

export const clearEmptySubObjects = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
    return o;
  }
};

export const propertiesToArray = (obj) => {
  const isObject = (val) =>
    val && typeof val === 'object' && !Array.isArray(val);

  const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

  const paths = (obj = {}, head = '') => {
    return Object.entries(obj).reduce((product, [key, value]) => {
      let fullPath = addDelimiter(head, key);
      return isObject(value)
        ? product.concat(paths(value, fullPath))
        : product.concat(fullPath);
    }, []);
  };

  return paths(obj);
};

export const hasActiveProductKey = (userSubscriptions) => {
  return (
    userSubscriptions?.some(
      (subscription) =>
        subscription.product === constants.ENTERPRISE_PRODUCT_ID &&
        ['active', 'trialing'].includes(subscription.status),
    ) || false
  );
}
    
