import React from 'react';
import { withTheme } from 'styled-components';

const RedoIcon = withTheme(({ width = 20, height = 19, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7.5H9C6.87827 7.5 4.84344 8.34285 3.34315 9.84315C1.84285 11.3434 1 13.3783 1 15.5V17.5M19 7.5L13 13.5M19 7.5L13 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export { RedoIcon };
