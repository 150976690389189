import _ from 'lodash';
import React, { useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { FormattedMessage } from 'react-intl';
import { Container, Spacer } from 'components';
import { useNavigate } from 'react-router-dom';

const SomethingWentWrong = ({ onClose = _.noop }) => {
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/');
  }, [onClose]);

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="sorry" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size="20" />
        <Container align="center" padding="0">
          Something appears to have gone wrong. Please try again later.
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export { SomethingWentWrong };
