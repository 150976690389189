import React from 'react';
import styled, { withTheme } from 'styled-components';
import footerImg from 'assets/images/footer.svg';

const FooterWrapper = styled.footer`
  background-image: url(${footerImg});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: white;
`;

const Footer = withTheme(() => {
  return (
    <>
      <FooterWrapper>
        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
          <nav
            className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
            aria-label="Footer"
          >
            <div className="pb-6">
              <a
                href="/privacy-policy"
                className="text-sm leading-6 text-white hover:text-gray-900"
              >
                Privacy Policy
              </a>
            </div>
            <div className="pb-6">
              <a
                href="/terms-of-service"
                className="text-sm leading-6 text-white hover:text-gray-900"
              >
                Terms Of Service
              </a>
            </div>
          </nav>

          <p className="mt-10 text-center text-xs leading-5 text-white">
            &copy; 2023 Taylor Digital Services, LLC. All rights reserved.
          </p>
        </div>
      </FooterWrapper>
    </>
  );
});

export { Footer };
