import React, { useState } from 'react';
import { Spacer, SubscriptionLandingModal } from 'components';

const EnterpriseSubscriptionLanding = () => {
  const [showSubscriptionLandingModal, setShowSubscriptionLandingModal] =
    useState(false);

  return (
    <div className="bg-transparent">
      <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-1 sm:px-6 sm:pb-24 lg:px-8">
        <div class="bg-transparent">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-4xl text-center">
              <Spacer size={60} />
              <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Simple Pricing Plans for Powerful Innovation
              </p>
            </div>
            <p class="mx-auto mt-6 max-w-2xl text-center text-sm leading-8 text-gray-600">
              The Enterprise Subscription Plan streamlines your DataLayer
              management with endless hosting and uploading of mirrored
              datastores and files. It includes monthly wallet top-offs and
              unlimited access keys, all delivered on the world's most
              eco-friendly blockchain for an efficient and responsible data
              management experience.
            </p>

            <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
                <h3
                  id="tier-freelancer"
                  class="text-lg font-semibold leading-8 text-gray-900"
                >
                  Free Teir
                </h3>
                <p class="mt-4 text-sm leading-6 text-gray-600">
                  The essentials to provide easy access to Chia DataLayer
                </p>

                <br />
                <br />
                <br />

                <ul
                  role="list"
                  class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600"
                >
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    10 Free Mirrored Stores
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    All Mirrors Available through datalayer.link web2 gateway
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    DataLayer Place Proxy Service
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Global Mirror Analytics
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    API Access
                  </li>
                </ul>
              </div>
              <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
                <h3
                  id="tier-startup"
                  class="text-lg font-semibold leading-8 text-gray-900"
                >
                  Enterprise
                </h3>
                <p class="mt-4 text-sm leading-6 text-gray-600">
                  A plan that scales with your rapidly growing business.
                </p>
                <p class="mt-6 flex items-baseline gap-x-1">
                  <span class="text-4xl font-bold tracking-tight text-gray-900">
                    $10
                  </span>

                  <span class="text-sm font-semibold leading-6 text-gray-600">
                    /month
                  </span>
                </p>
                <a
                  onClick={() => setShowSubscriptionLandingModal(true)}
                  aria-describedby="tier-startup"
                  style={{ cursor: 'pointer' }}
                  class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600"
                >
                  Buy plan
                </a>
                <ul
                  role="list"
                  class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600"
                >
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Everything in the free tier plus:
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Unlimited Mirrors
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    DataLayer Storage Upload Software (Hides IP address,
                    automated file mirroring)
                  </li>

                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Attach Subdomains to Mirrors
                  </li>
                  <li class="flex gap-x-3">
                    <svg
                      class="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    XCH Faucet for Blockchain Access
                  </li>
                </ul>
              </div>
              <div class="rounded-3xl p-8 ring-1 xl:p-10 bg-gray-900 ring-gray-900">
                <h3
                  id="tier-enterprise"
                  class="text-lg font-semibold leading-8 text-white"
                >
                  Supporter
                </h3>
                <p class="mt-4 text-sm leading-6 text-gray-300">
                  Helps support more innovative features and services to build
                  up Chia DataLayer.
                </p>
                <p class="mt-6 flex items-baseline gap-x-1">
                  <span class="text-4xl font-bold tracking-tight text-white">
                    $50
                  </span>

                  <span class="text-sm font-semibold leading-6 text-white">
                    /month
                  </span>
                </p>

                <a
                  href="https://buy.stripe.com/28oaIq5Ca6sHgfufYZ"
                  target="_blank"
                  aria-describedby="tier-startup"
                  class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600"
                >
                  Buy plan
                </a>

                <ul
                  role="list"
                  class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300"
                >
                  <li class="flex gap-x-3 text-white">
                    <svg
                      class="h-6 w-5 flex-none text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Everything in Enterprise Plan
                  </li>
                  <li class="flex gap-x-3 text-white">
                    <svg
                      class="h-6 w-5 flex-none text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    1 on 1 support
                  </li>
                  <li class="flex gap-x-3 text-white">
                    <svg
                      class="h-6 w-5 flex-none text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    You get to influence what features we build next.
                  </li>
                  <li class="flex gap-x-3 text-white">
                    <svg
                      class="h-6 w-5 flex-none text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Supports development in the Chia DataLayer space.
                  </li>
                  <li class="flex gap-x-3 text-white">
                    <svg
                      class="h-6 w-5 flex-none text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Occasional Goodies sent to your XCH Address
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSubscriptionLandingModal && (
        <SubscriptionLandingModal
          onClose={() => setShowSubscriptionLandingModal(false)}
        />
      )}
    </div>
  );
};

export { EnterpriseSubscriptionLanding };
