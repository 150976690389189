import React from 'react';
import { withTheme } from 'styled-components';

const EditIcon = withTheme(({ width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" id="edit">
      <path d="M5,18H9.24a1,1,0,0,0,.71-.29l6.92-6.93h0L19.71,8a1,1,0,0,0,0-1.42L15.47,2.29a1,1,0,0,0-1.42,0L11.23,5.12h0L4.29,12.05a1,1,0,0,0-.29.71V17A1,1,0,0,0,5,18ZM14.76,4.41l2.83,2.83L16.17,8.66,13.34,5.83ZM6,13.17l5.93-5.93,2.83,2.83L8.83,16H6ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"></path>
    </svg>
  );
});

export { EditIcon };
