import React from 'react';
import { withTheme } from 'styled-components';

const Page404Icon = withTheme(
  ({ width = 1092, height = 538}) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 1092 538"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          clipPath="url(#clip0_14827_85530)"
          filter="url(#filter0_dd_14827_85530)"
        >
          <path
            d="M689.98 130C689.98 174.4 664.58 213.5 625.98 236.4V258C625.98 275.7 611.68 290 593.98 290H497.98C480.28 290 465.98 275.7 465.98 258V236.4C427.38 213.4 401.98 174.3 401.98 130C401.98 59.3 466.48 2 545.98 2C625.48 2 689.98 59.3 689.98 130ZM489.98 178C507.68 178 521.98 163.7 521.98 146C521.98 128.3 507.68 114 489.98 114C472.28 114 457.98 128.3 457.98 146C457.98 163.7 472.28 178 489.98 178ZM633.98 146C633.98 128.3 619.68 114 601.98 114C584.28 114 569.98 128.3 569.98 146C569.98 163.7 584.28 178 601.98 178C619.68 178 633.98 163.7 633.98 146ZM325.38 275.7C333.28 259.9 352.48 253.5 368.28 261.4L545.98 350.2L723.68 261.4C739.48 253.5 758.68 259.9 766.58 275.7C774.48 291.5 768.08 310.7 752.28 318.6L617.58 386L752.38 453.4C768.18 461.3 774.58 480.5 766.68 496.3C758.78 512.1 739.58 518.5 723.78 510.6L545.98 421.8L368.28 510.6C352.48 518.5 333.28 512.1 325.38 496.3C317.48 480.5 323.88 461.3 339.68 453.4L474.38 386L339.68 318.6C323.88 310.7 317.48 291.5 325.38 275.7V275.7Z"
            fill="#1C64F2"
          />
        </g>
        <g filter="url(#filter1_dd_14827_85530)">
          <path
            d="M17.1756 358.256V315.873L123.443 148.455H159.985V207.119H138.358L71.3659 313.138V315.127H222.378V358.256H17.1756ZM139.352 403V345.33L140.347 326.562V148.455H190.808V403H139.352Z"
            fill="#1C64F2"
          />
        </g>
        <g filter="url(#filter2_dd_14827_85530)">
          <path
            d="M871.176 358.256V315.873L977.443 148.455H1013.98V207.119H992.358L925.366 313.138V315.127H1076.38V358.256H871.176ZM993.352 403V345.33L994.347 326.562V148.455H1044.81V403H993.352Z"
            fill="#1C64F2"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_14827_85530"
            x="310"
            y="0"
            width="472.07"
            height="535.98"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_14827_85530"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="3"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_14827_85530"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_14827_85530"
              result="effect2_dropShadow_14827_85530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_14827_85530"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_dd_14827_85530"
            x="5.17578"
            y="146.455"
            width="229.202"
            height="278.545"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_14827_85530"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="3"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_14827_85530"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_14827_85530"
              result="effect2_dropShadow_14827_85530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_14827_85530"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_dd_14827_85530"
            x="859.176"
            y="146.455"
            width="229.202"
            height="278.545"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_14827_85530"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="3"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_14827_85530"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="7.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_14827_85530"
              result="effect2_dropShadow_14827_85530"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_14827_85530"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_14827_85530">
            <rect
              width="448.07"
              height="511.98"
              fill="white"
              transform="translate(322 2)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export { Page404Icon };
