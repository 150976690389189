/**
 * @api {module} UserApi
 * @apiDescription Provides access to user-related endpoints.
 * @apiVersion 1.0.0
 * @apiName UserApi
 * @apiGroup API Modules
 */
import { dlaasApi } from '../';

const userApi = dlaasApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          password,
        }),
      }),
    }),

    createUser: builder.mutation({
      query: ({ username, password, email }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/register`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          email,
        }),
      }),
    }),

    /**
     * @api {function} useUserProfileQuery Get User Profile Query
     * @apiDescription This query allows you to retrieve user profile information.
     * @apiName useUserProfileQuery
     * @apiGroup UserApi
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the user profile.
     */
    userProfile: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/me`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return {
          ...response,
          automatic_topoff: response.automatic_topoff === 'true',
        };
      },
      providesTags: (result) => [{ type: 'user', id: result?.user_id }],
    }),

    resetPassword: builder.mutation({
      query: ({ email }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/reset_password`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      }),
    }),

    confirmNewPassword: builder.mutation({
      query: ({ password, code }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/confirm_new_password`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          code,
        }),
      }),
    }),

    /**
     * @api {function} useClientAccessKeysQuery Get Client Access Keys Query
     * @apiDescription This query allows you to retrieve client access keys.
     * @apiName useClientAccessKeysQuery
     * @apiGroup UserApi
     *
     * @apiParam {Object} variables - The parameters for retrieving client access keys.
     * @apiParam {number} variables.page - The page number.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the client access keys.
     */
    clientAccessKeys: builder.query({
      query: ({ page }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/access_keys`,
        params: { page },
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'access_keys', id: result?.user_id }],
    }),

    /**
     * @api {function} useCreateClientAccessKeysMutation Create Client Access Keys Mutation
     * @apiDescription This mutation allows creating client access keys.
     * @apiName useCreateClientAccessKeysMutation
     * @apiGroup UserApi
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the created client access keys.
     */
    createClientAccessKeys: builder.mutation({
      query: () => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/access_keys`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'access_keys', id: userId },
      ],
    }),

    /**
     * @api {function} useDeleteClientAccessKeysMutation Delete Client Access Keys Mutation
     * @apiDescription This mutation allows deleting client access keys.
     * @apiName useDeleteClientAccessKeysMutation
     * @apiGroup UserApi
     *
     * @apiParam {Object} variables - The parameters for deleting client access keys.
     * @apiParam {string} variables.accessKey - The access key to delete.
     *
     * @apiSuccess {Promise} Promise - A Promise that resolves with the deletion result.
     */
    deleteClientAccessKeys: builder.mutation({
      query: ({ accessKey }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/access_keys/${accessKey}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'access_keys', id: userId },
      ],
    }),

    updateMirrorIpAddress: builder.mutation({
      query: ({ ipAddress }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/update_user_ip`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ip_address: ipAddress,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),

    updateMirrorIpMonitoringAddress: builder.mutation({
      query: ({ monitor }) => ({
        url: `${process.env.REACT_APP_API_HOST}/user/v1/update_user_ip_monitoring`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          monitor,
        }),
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'user', id: userId },
      ],
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateUserMutation,
  useResetPasswordMutation,
  useConfirmNewPasswordMutation,
  useUserProfileQuery,
  useClientAccessKeysQuery,
  useCreateClientAccessKeysMutation,
  useDeleteClientAccessKeysMutation,
  useUpdateMirrorIpAddressMutation,
  useUpdateMirrorIpMonitoringAddressMutation,
} = userApi;
