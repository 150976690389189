import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Modal } from 'flowbite-react';
import { Spacer, Banner } from 'components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'flowbite-react';
import { useCancelSubscriptionMutation } from 'api/dlaas/subscription';
import { useUserProfileQuery } from 'api/dlaas/user';
import { Spinner } from 'flowbite-react';

const CancelSubscriptionModal = ({ onClose = _.noop }) => {
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [modalState, setModalState] = useState('LANDING');
  const [triggerCancelEnterpriseSubscription] = useCancelSubscriptionMutation();
  const [errorMessage, setErrorMessage] = useState();

  const handleCancelSubscription = useCallback(async () => {
    const response = await triggerCancelEnterpriseSubscription({
      user_id: user.user_id,
    });
    if (response.error) {
      setErrorMessage(response.error.data.message);
      setModalState('ERROR');
    } else {
      setModalState('CONFIRM');
    }
  });

  if (userDataIsLoading) {
    return (
      <Modal
        dismissible={true}
        show={true}
        size="2xl"
        popup={true}
        onClose={onClose}
      >
        <Modal.Header>
          <FormattedMessage id="cancel-subscription" />
        </Modal.Header>
        <Modal.Body>
          <Spinner />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="2xl"
      popup={true}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="cancel-subscription" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        {errorMessage && <Banner type="error">{errorMessage}</Banner>}
        {modalState === 'LANDING' && (
          <FormattedMessage id="cancel-subscription-message" />
        )}
        {modalState === 'CONFIRM' && (
          <FormattedMessage id="cancel-subscription-confirm-message" />
        )}
        {modalState === 'ERROR' && (
          <FormattedMessage id="cancel-subscription-error-message" />
        )}
      </Modal.Body>
      <Modal.Footer>
        {modalState === 'LANDING' && (
          <>
            <Button onClick={onClose}>Nevermind!</Button>
            <Button onClick={handleCancelSubscription} color="gray">
              Yes, cancel my subscription
            </Button>
          </>
        )}
        {modalState === 'CONFIRM' && (
          <Button onClick={onClose}>Sounds Good</Button>
        )}
        {modalState === 'ERROR' && <Button onClick={onClose}>OK</Button>}
      </Modal.Footer>
    </Modal>
  );
};

export { CancelSubscriptionModal };
