import React from 'react';
import { StopSignIcon } from 'components';
import { Body2, Spacer } from 'components';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Body2>
          <strong>Something went wrong</strong>
        </Body2>
        <Spacer size={35} />
        <StopSignIcon width="100%" />
        <Spacer size={35} />
        <Body2>
          <strong>
            Please contact your admin or{' '}
            <a style={{cursor: 'pointer'}} onClick={() => window.location.reload()}>try again</a>.
          </strong>
        </Body2>
      </div>
    );
  }
}

export { ErrorBoundary };