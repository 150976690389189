import React from 'react';
import styled, { withTheme } from 'styled-components';
import { LogoutModal, LogoIcon } from 'components';
import { Avatar } from '../Avatar';
import { useUrlHash } from 'hooks';
import { Button } from 'flowbite-react';
import { useUserProfileQuery } from 'api/dlaas/user';
import { hasActiveProductKey } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.surface.slot2.background.hex};
`;

const LogoContainer = styled('div')`
  margin: 8px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
`;

const Header = withTheme(({ theme }) => {
  const navigate = useNavigate();
  const [, setLogoutModal] = useUrlHash('logout');
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();

  return (
    <>
      <Container
        className={`border-b bg-${theme.surface.slot1.background.class}`}
      >
        <LogoContainer>
          <div
            style={{
              marginLeft: 5,
              marginRight: 10,
              borderRadius: 10,
            }}
          >
            <LogoIcon width={125} height={125} color="#4561F4" />
          </div>
        </LogoContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
            cursor: 'pointer',
          }}
        >
          {!userDataIsLoading &&
            user &&
            !hasActiveProductKey(user.subscriptions) && (
              <Button
                style={{ marginRight: 15 }}
                onClick={() => navigate('/account')}
              >
                <FormattedMessage id="get-subscription" />
              </Button>
            )}

          <div
            onClick={() => setLogoutModal(true)}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 10,
              cursor: 'pointer',
            }}
          >
            <Avatar size={50} />
          </div>
        </div>
      </Container>

      <LogoutModal />
    </>
  );
});

export { Header };
