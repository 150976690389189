import React from 'react';
import styled, { withTheme } from 'styled-components';

const Content = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.color || props.theme.surface.slot1.text.hex};
`;

const Body2 = withTheme(({ children }) => <Content>{children}</Content>);

export { Body2 };
