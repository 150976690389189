import React from 'react';
import { withTheme } from 'styled-components';

const DocumentsIcon = withTheme(({ width = 20, height = 20, color, theme }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3999 0.599976C6.08164 0.599976 5.77642 0.726404 5.55137 0.951447C5.32633 1.17649 5.1999 1.48172 5.1999 1.79998C5.1999 2.11824 5.32633 2.42346 5.55137 2.6485C5.77642 2.87355 6.08164 2.99998 6.3999 2.99998H13.5999C13.9182 2.99998 14.2234 2.87355 14.4484 2.6485C14.6735 2.42346 14.7999 2.11824 14.7999 1.79998C14.7999 1.48172 14.6735 1.17649 14.4484 0.951447C14.2234 0.726404 13.9182 0.599976 13.5999 0.599976H6.3999ZM2.7999 5.39998C2.7999 5.08172 2.92633 4.77649 3.15137 4.55145C3.37642 4.3264 3.68164 4.19998 3.9999 4.19998H15.9999C16.3182 4.19998 16.6234 4.3264 16.8484 4.55145C17.0735 4.77649 17.1999 5.08172 17.1999 5.39998C17.1999 5.71824 17.0735 6.02346 16.8484 6.2485C16.6234 6.47355 16.3182 6.59998 15.9999 6.59998H3.9999C3.68164 6.59998 3.37642 6.47355 3.15137 6.2485C2.92633 6.02346 2.7999 5.71824 2.7999 5.39998ZM0.399902 10.2C0.399902 9.56346 0.652759 8.95301 1.10285 8.50292C1.55293 8.05283 2.16338 7.79997 2.7999 7.79998H17.1999C17.8364 7.79998 18.4469 8.05283 18.897 8.50292C19.347 8.95301 19.5999 9.56346 19.5999 10.2V15C19.5999 15.6365 19.347 16.2469 18.897 16.697C18.4469 17.1471 17.8364 17.4 17.1999 17.4H2.7999C2.16338 17.4 1.55293 17.1471 1.10285 16.697C0.652759 16.2469 0.399902 15.6365 0.399902 15V10.2Z"
        fill={color || theme.surface.slot5.background.hex} 
      />
    </svg>
  );
});

export { DocumentsIcon };
