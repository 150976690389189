import React from 'react';
import styled from 'styled-components';

const Content = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => props.color || props.theme.surface.slot1.text.hex};
`;

const Caption1 = ({ children, style, color }) => <Content color={color} style={style}>{children}</Content>;

export { Caption1 };
