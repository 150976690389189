import { CreateNewNotificationForm } from 'components';

const Notifications = () => {
  return (
    <div style={{ margin: 25 }}>
      <h1>Notifications</h1>
      <p>
        The provided system offers a notification subscription service, allowing
        users to stay informed about various events within the system. Users
        have the option to receive notifications either through email or via
        webhook push. Webhook pushes are great for integrating with other tools
        and apps.
      </p>

        <CreateNewNotificationForm />


    </div>
  );
};

export { Notifications };
