import React from 'react';
import styled from 'styled-components';

const Content = styled('h2')`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: ${props => props.color || 'black'};
  text-transform: capitalize;
`;

const H2 = ({ children, color }) => <Content color={color}>{children}</Content>;

export { H2 };
