import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Spacer, UpdateMirrorConnectionForm, ToggleSwitch } from 'components';
import { Alert, Spinner, Card, Badge } from 'flowbite-react';
import styled from 'styled-components';
import {
  useUserProfileQuery,
  useUpdateMirrorIpMonitoringAddressMutation,
} from 'api/dlaas/user';
import { useCheckMirrorConnectionMutation } from 'api/dlaas/mirror';

const Subtitle = styled.h2`
  font-size: 1.2em; /* Smaller than h1 but still prominent */
  color: #666; /* Lighter color for less emphasis */
  font-style: italic; /* Styling to differentiate from the main title */
  margin-top: 0;
`;

const Proxy = () => {
  const { data: user, isLoading: userDataIsLoading } = useUserProfileQuery();
  const [checkIpAddress, { isLoading }] = useCheckMirrorConnectionMutation();
  const [
    updateMirrorIpMonitoringAddress,
    { isLoading: isMirrorNotificationProcessing },
  ] = useUpdateMirrorIpMonitoringAddressMutation();
  const [ipAddressDiscoverable, setIpAddressDiscoverable] = useState(false);
  const [datalayerPlaceDiscoverable, setDatalayerPlaceDiscoverable] =
    useState(null);

  useEffect(() => {
    if (user?.proxy_ip_address) {
      const checkIpAddressPromise = async () => {
        const values = { ipAddress: user.proxy_ip_address };
        try {
          const response = await checkIpAddress(values);
          setIpAddressDiscoverable(response.data?.connection_detected);
        } catch {
          setIpAddressDiscoverable(false);
        }
      };

      checkIpAddressPromise();
    }
  }, [user?.proxy_ip_address, checkIpAddress]);

  useEffect(() => {
    if (user?.proxy_key) {
      const checkIpAddressPromise = async () => {
        const values = { host: `https://datalayer.place/${user.proxy_key}` };
        try {
          const response = await checkIpAddress(values);
          console.log('response', response);
          setDatalayerPlaceDiscoverable(response.data?.connection_detected);
        } catch (error) {
          setDatalayerPlaceDiscoverable(false);
        }
      };

      checkIpAddressPromise();
    }
  }, [user?.proxy_key, checkIpAddress]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Address copied to clipboard!');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  };

  return (
    <div style={{ margin: 10 }}>
      <h1>Your DataLayer Place</h1>
      <Subtitle>Static Mirror addresses for Dynamic IPs</Subtitle>
      <Alert color="info">
        To optimize your hosting experience on the DataLayer peer-to-peer
        network, a static IP address is recommended. However, for users on home
        networks or with dynamic IP addresses, a viable solution is available:
        you can secure a DataLayer Place address. This approach enables seamless
        hosting on the DataLayer network, eliminating concerns about fluctuating
        IP addresses. With a DataLayer Place address, continuity is maintained
        regardless of IP changes. Simply update your new IP address in our
        system, and your mirrors will continue functioning without interruption.
      </Alert>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h2>Your DataLayer Place Address</h2>
        <Card
          onClick={() =>
            copyToClipboard(`https://datalayer.place/${user.proxy_key}`)
          }
          style={{ cursor: 'pointer' }}
        >
          {userDataIsLoading ? (
            <Spinner />
          ) : (
            <>{`https://datalayer.place/${user.proxy_key}`}</>
          )}
        </Card>
        <Spacer size={10} />
        {isLoading || _.isNil(datalayerPlaceDiscoverable) ? (
          <Spinner />
        ) : (
          <>
            {datalayerPlaceDiscoverable ? (
              <Badge color="success">This proxy is set up correctly</Badge>
            ) : (
              <Badge color="failure">
                This proxy is not running, see proxy settings to configure
              </Badge>
            )}
            <Spacer size={30} />
          </>
        )}

        <div>
          For an enhanced and uninterrupted experience in hosting mirrors from a
          machine with a dynamic IP, it's advisable to utilize your DataLayer
          Place address. This address acts as a stable endpoint, ensuring
          consistent access and connectivity for your mirrors, even when your
          machine's IP address changes. Remember to use this address during the
          mirror creation process to leverage its benefits fully.
        </div>

        <h3>Example Usage:</h3>
        <p>
          When your using the sprout tool, you can add this last line to your
          sprout.config.json to use the proxy address for your mirrors instead of your IP address.
        </p>
        <pre>
          {JSON.stringify(
            {
              store_id:null,
              deploy_dir: './build',
              datalayer_host: 'https://localhost:8562',
              wallet_host: 'https://localhost:9256',
              certificate_folder_path: '~/.chia/mainnet/config/ssl',
              default_wallet_id: 1,
              default_fee: 300000000,
              default_mirror_coin_amount: 300000000,
              maximum_rpc_payload_size: 26214400,
              web2_gateway_port: 41410,
              web2_gateway_host: 'localhost',
              forceIp4Mirror: true,
              mirror_url_override: `https://datalayer.place/${user.proxy_key}`,
            },
            null,
            2,
          )}
        </pre>
      </div>
      <Spacer size={30} />
      <Card>
        <h2>Proxy Settings</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <div style={{ flex: 1 }}>
            {' '}
            <p>
              Your Current IP Address:{' '}
              {userDataIsLoading ? <Spinner /> : user?.ip_address}
            </p>
            <p>
              IP address set to your place:{' '}
              {userDataIsLoading ? (
                <Spinner />
              ) : (
                user?.proxy_ip_address || 'Not Set'
              )}
            </p>
            <p>
              {isLoading || userDataIsLoading ? (
                <Spinner />
              ) : (
                <span>
                  {ipAddressDiscoverable ? (
                    <Badge color="success">
                      Detected File Propagation Server At IP Address
                    </Badge>
                  ) : (
                    <Badge color="failure">
                      Can No Detect File Propagation Server At IP Address
                    </Badge>
                  )}
                </span>
              )}
            </p>
          </div>
          <div style={{ flex: 1 }}>
            <Spacer size={30} />

            {isMirrorNotificationProcessing ? (
              <Spinner />
            ) : (
              <ToggleSwitch
                checked={user?.proxy_monitoring || false}
                label="Notify me when my mirror is not discoverable Checked once a day, you may get an email if you turn your computer off."
                onChange={() =>
                  updateMirrorIpMonitoringAddress({
                    monitor: !user?.proxy_monitoring,
                    userId: user?.id,
                  })
                }
                color="black"
              />
            )}

            <UpdateMirrorConnectionForm />
          </div>
        </div>
      </Card>
    </div>
  );
};

export { Proxy };
