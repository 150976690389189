import React, { useState } from 'react';
import { TextInput, Spinner, Button } from 'flowbite-react';
import { useCreateSubdomainMutation } from 'api/dlaas/mirror';
import { Container, Spacer } from 'components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import {  Alert } from 'flowbite-react';

const initialValues = {
  subdomain: '',
};

const validationSchema = yup.object({
  subdomain: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]+$/,
      'Subdomain can only contain alphanumeric characters.',
    )
});

const CreateSubdomainForm = withTheme(({ mirror, onClose, theme }) => {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [createSubdomain, { isLoading }] = useCreateSubdomainMutation();
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitted(true);
      const response = await createSubdomain({
        ...values,
        singletonId: mirror.singleton_id,
      });
      if (response.error) {
        setSubmitted(false);
        setError(response.error.message);
        return;
      } else {
        onClose();
      }
    },
  });

  return (
    <form onSubmit={submitted ? onClose : formik.handleSubmit}>
      {error && <Alert type="error">{error}</Alert>}
      <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <div>
          <div
            className={`mb-2 block text-base font-normal text-${theme.surface.slot1.text.class}`}
          >
            <FormattedMessage id="creating-a-subdomain-gives" />
            <Spacer size="10" />
          </div>
          <span style={{ color: 'red' }}>
            A blank value will create a random generated subdomain
          </span>
          <Spacer size="10" />
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <div style={{ width: 200 }}>
              <TextInput
                id="subdomain"
                placeholder={intl.formatMessage({ id: 'subdomain' })}
                value={formik.values.subdomain}
                onChange={formik.handleChange}
              />
            </div>
            <div>.datalayer.link</div>
            {formik.errors.subdomain ? (
              <div>{formik.errors.subdomain}</div>
            ) : null}
          </div>
        </div>
      </div>

      <Container align="center" padding="0">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span>
            <Button style={{ width: 75 }} onClick={onClose} color="light">
              <FormattedMessage id="cancel" />
            </Button>
          </span>
          <span style={{ marginLeft: 15 }}>
            <Button type="submit" value="Save">
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <FormattedMessage id="submit" />
              )}
            </Button>
          </span>
        </div>
      </Container>
    </form>
  );
});

export { CreateSubdomainForm };
