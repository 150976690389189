import React from 'react';
import { withTheme } from 'styled-components';

const LifePreserverIcon = withTheme(({ width = 20, height = 20, color, theme }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5999 10C19.5999 12.5461 18.5885 14.9879 16.7881 16.7883C14.9878 18.5886 12.546 19.6 9.9999 19.6C7.45382 19.6 5.01203 18.5886 3.21168 16.7883C1.41133 14.9879 0.399902 12.5461 0.399902 10C0.399902 7.45395 1.41133 5.01215 3.21168 3.2118C5.01203 1.41145 7.45382 0.400024 9.9999 0.400024C12.546 0.400024 14.9878 1.41145 16.7881 3.2118C18.5885 5.01215 19.5999 7.45395 19.5999 10V10ZM17.1999 10C17.1999 11.1916 16.9107 12.3148 16.3983 13.3048L14.5695 11.4748C14.8433 10.6273 14.8757 9.72018 14.6631 8.85522L16.5375 6.98082C16.9623 7.89882 17.1999 8.92002 17.1999 10ZM11.0019 14.6956L12.8979 16.5916C11.9847 16.9937 10.9977 17.2009 9.9999 17.2C8.95732 17.2013 7.92699 16.9753 6.9807 16.5376L8.8551 14.6632C9.55911 14.8357 10.293 14.8468 11.0019 14.6956V14.6956ZM5.3895 11.3404C5.15335 10.5273 5.13638 9.66621 5.3403 8.84442L5.2443 8.94042L3.4083 7.10082C3.00607 8.01439 2.79885 9.00183 2.7999 10C2.7999 11.1448 3.0675 12.2272 3.5427 13.1884L5.3907 11.3404H5.3895ZM6.6951 3.60042C7.71657 3.07245 8.85005 2.79792 9.9999 2.80002C11.1447 2.80002 12.2271 3.06762 13.1883 3.54282L11.3403 5.39082C10.419 5.12248 9.43847 5.13627 8.5251 5.43042L6.6951 3.60162V3.60042ZM12.3999 10C12.3999 10.6365 12.147 11.247 11.697 11.6971C11.2469 12.1472 10.6364 12.4 9.9999 12.4C9.36338 12.4 8.75293 12.1472 8.30285 11.6971C7.85276 11.247 7.5999 10.6365 7.5999 10C7.5999 9.36351 7.85276 8.75306 8.30285 8.30297C8.75293 7.85288 9.36338 7.60002 9.9999 7.60002C10.6364 7.60002 11.2469 7.85288 11.697 8.30297C12.147 8.75306 12.3999 9.36351 12.3999 10V10Z"
        fill={color || theme.surface.slot5.background.hex}
      />
    </svg>
  );
});

export { LifePreserverIcon };
