import React from 'react';
import { withTheme } from 'styled-components';

const PeopleIcon = withTheme(({ width = 20, height = 20, color, theme }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79995 4.19998C9.79995 5.15475 9.42067 6.07043 8.74554 6.74556C8.0704 7.42069 7.15473 7.79998 6.19995 7.79998C5.24517 7.79998 4.3295 7.42069 3.65437 6.74556C2.97924 6.07043 2.59995 5.15475 2.59995 4.19998C2.59995 3.2452 2.97924 2.32952 3.65437 1.65439C4.3295 0.97926 5.24517 0.599976 6.19995 0.599976C7.15473 0.599976 8.0704 0.97926 8.74554 1.65439C9.42067 2.32952 9.79995 3.2452 9.79995 4.19998ZM19.4 4.19998C19.4 4.67273 19.3068 5.14086 19.1259 5.57764C18.945 6.01441 18.6798 6.41127 18.3455 6.74556C18.0112 7.07985 17.6144 7.34503 17.1776 7.52594C16.7408 7.70686 16.2727 7.79998 15.8 7.79998C15.3272 7.79998 14.8591 7.70686 14.4223 7.52594C13.9855 7.34503 13.5887 7.07985 13.2544 6.74556C12.9201 6.41127 12.6549 6.01441 12.474 5.57764C12.2931 5.14086 12.2 4.67273 12.2 4.19998C12.2 3.2452 12.5792 2.32952 13.2544 1.65439C13.9295 0.97926 14.8452 0.599976 15.8 0.599976C16.7547 0.599976 17.6704 0.97926 18.3455 1.65439C19.0207 2.32952 19.4 3.2452 19.4 4.19998ZM14.516 17.4C14.5712 17.0076 14.6 16.608 14.6 16.2C14.6027 14.3146 13.9684 12.4836 12.8 11.004C13.7121 10.4774 14.7467 10.2001 15.7999 10.2001C16.8531 10.2001 17.8878 10.4773 18.7999 11.0039C19.712 11.5305 20.4694 12.2879 20.996 13.2C21.5227 14.1121 21.7999 15.1468 21.7999 16.2V17.4H14.516ZM6.19995 10.2C7.79125 10.2 9.31737 10.8321 10.4426 11.9573C11.5678 13.0826 12.2 14.6087 12.2 16.2V17.4H0.199951V16.2C0.199951 14.6087 0.832092 13.0826 1.95731 11.9573C3.08253 10.8321 4.60865 10.2 6.19995 10.2Z"
        fill={color || theme.surface.slot5.background.hex}
      />
    </svg>
  );
});

export { PeopleIcon };
