import React from 'react';
import styled from 'styled-components';

const Content = styled('span')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.color || '#111928'};
`;

const SubTitle1 = ({ children, color }) => <Content color={color}>{children}</Content>;

export { SubTitle1 };
