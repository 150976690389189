import { useCallback } from 'react';
import { withTheme } from 'styled-components';
import { Sidebar } from 'flowbite-react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ROUTES from 'routes/route-constants';
import { useNavigate } from 'react-router-dom';

const LeftNav = withTheme(({ theme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = useCallback(
    (path) => {
      return location.pathname === path;
    },
    [location],
  );

  return (
    <div
      className={`bg-${theme.surface.slot1.background.class}`}
      style={{ width: '100%', height: 'calc(100% - 64px)', overflow: 'auto' }}
    >
      <Sidebar aria-label="App Navigation">
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.MY_MIRRORS)}
              onClick={() => navigate(ROUTES.MY_MIRRORS)}
            >
              <FormattedMessage id="my-mirrors" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.MY_FILES)}
              onClick={() => navigate(ROUTES.MY_FILES)}
            >
              <FormattedMessage id="private-mirrors" />
            </Sidebar.Item>

            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.PROXY)}
              onClick={() => navigate(ROUTES.PROXY)}
            >
              <FormattedMessage id="proxy" />
            </Sidebar.Item>

            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.ACCESS_KEYS)}
              onClick={() => navigate(ROUTES.ACCESS_KEYS)}
            >
              <FormattedMessage id="access-keys" />
            </Sidebar.Item>

          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.PREREQUISITES)}
              onClick={() => navigate(ROUTES.PREREQUISITES)}
            >
              <FormattedMessage id="prerequisites" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.ACCOUNT)}
              onClick={() => navigate(ROUTES.ACCOUNT)}
            >
              <FormattedMessage id="subscription" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.HOW_TO)}
              onClick={() => navigate(ROUTES.HOW_TO)}
            >
              <FormattedMessage id="how-to" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.MY_XCH_TOP_OFFS)}
              onClick={() => navigate(ROUTES.MY_XCH_TOP_OFFS)}
            >
              <FormattedMessage id="blockchain-access" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={isActive(ROUTES.DOWNLOADS)}
              onClick={() => navigate(ROUTES.DOWNLOADS)}
            >
              <FormattedMessage id="downloads" />
            </Sidebar.Item>
            <Sidebar.Item
              style={{ cursor: 'pointer' }}
              active={false}
              onClick={() => navigate('/docs')}
            >
              <a
                href="/docs"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <FormattedMessage id="api" />
              </a>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
});

export { LeftNav };
