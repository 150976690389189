import React from 'react';
import styled from 'styled-components';

const Content = styled('h1')`
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
  color: ${(props) => props.color || 'black'};
`;

const H1 = ({ children, color, style }) => {
  return (
    <Content style={style} color={color}>
      {children}
    </Content>
  );
};

export { H1 };
