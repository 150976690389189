import React from 'react';
import { withTheme } from 'styled-components';

const Card = withTheme(({ children, backgroundClass }) => {
  return (
    <div
      className={`
      block 
      h-max
      p-6 
      bg-${backgroundClass || 'white'} 
      border-gray-200 
      shadow-sm
    `}
    >
      {children}
    </div>
  );
});

export { Card };
