import React from 'react';
import heroSubscription from 'assets/images/hero_plant.jpg';
import { withTheme } from 'styled-components';

const features = [
  {
    name: 'Unlimited Mirrors',
    description:
      "Boost your data's resilience with our Enterprise Subscription Plan. Benefit from unlimited data mirrors, ensuring constant availability of your important data. Experience secure, worry-free data management.",
  },
  {
    name: 'Unlimited Data Store Files + Upload plugin',
    description:
      'Store your DataLayer files directly with us, and we will ensure they are always available to its subscribers.',
  },
  {
    name: 'Monthly Wallet Top Offs',
    description:
      'Shift your focus to what matters - your data. With the Enterprise Subscription Plan, we monitor your wallet each month, ensuring you always have sufficient resources to push your data.',
  },
  {
    name: 'World-Wide Content Delivery Network',
    description:
      'Hosted on a global Content Delivery Network, our service ensures rapid access to your data anywhere, anytime, breaking down geographic barriers to meet data needs efficiently.',
  },
  {
    name: 'Dedicated Support',
    description:
      'We will work with you to help setup Datalayer.storage on your system, and ensure you are getting the most out of your subscription.',
  },
  {
    name: 'Protect the Environment in more ways then one.',
    description:
      'We will contribute 1% of your subscription to removing CO₂ from the atmosphere.',
  },
];

const AppLanding = withTheme(() => {
  return (
    <div className="bg-white">
      <div aria-hidden="true" className="relative">
        <img
          src={heroSubscription}
          alt=""
          className="h-96 w-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />

        <div
          style={{ top: '50%', width: '100%' }}
          className="absolute mx-auto -mt-12 px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8"
        >
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              The Easiest way to engage with Chia DataLayer!
            </h1>
          </div>
        </div>
      </div>

      <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            DataLayer Storage
          </h2>

          <p className="mt-4 text-gray-500">
            Whether you are an active participant in the Chia ecosystem, or you
            have just learned about Chia, this service can make sharing your
            data a breeze.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-gray-900">{feature.name}</dt>
              <dd className="mt-2 text-sm text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                What is Chia DataLayer?
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  Chia DataLayer is a tool which anyone in the world can use to
                  share their data in a transparent, secure, and provable way.
                  DataLayer is the go-to tool for sharing your data
                  intentionally, ensuring that you control which data you share,
                  and with whom! To learn more, visit{' '}
                  <a href="https://chia.net/datalayer" target="_blank">
                    https://chia.net/datalayer
                  </a>
                </p>
              </dd>
            </div>
          </dl>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                Why would I use this service?
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  Anyone in the world can use Chia DataLayer, but doing so
                  requires some knowledge of the service and may be a bit tricky
                  for those who do not have experience working with different
                  peer-to-peer file sharing protocols. DataLayer.storage
                  accelerates and simplifies the use of Chia DataLayer. If you
                  are wanting to share your data using DataLayer, and want to
                  bypass the infrastructure setup, simply create an account with
                  Datalayer.storage and begin sharing your data today!
                </p>
              </dd>
            </div>
          </dl>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                How to get started?
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  Simply create an account and use traditional payment methods
                  like a credit card to begin using DataLayer.storage! No crypto
                  needed. Follow the steps below to install the
                  DataLayer.storage plugin and you will be ready to share your
                  data in a transparent, secure, and provable way in no time.
                </p>
              </dd>
            </div>
          </dl>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                What is the Top-Off Service?
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  Using Chia DataLayer requires a small amount of XCH in order
                  to share data updates. The XCH is used to pay the validators
                  of the blockchain to include your data update into a block on
                  the Chia blockchain. One key benefit that the
                  DataLayer.storage service provides is that you can easily
                  obtain XCH by leveraging the top-off service. This service
                  will convert USD into XCH and ensure that you always have
                  enough XCH to be able to add data! We refill it every day up
                  to a limit of 0.05 XCH to ensure you always have the funds
                  needed for transactions to the data layer. You can easily
                  track this daily top-up activity in your history below.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
});

export { AppLanding };
