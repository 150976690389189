import React from 'react';
import { Page404Icon } from 'components/icons';
import { Body2, Spacer } from 'components';

const ErrorBoundary404 = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Body2>
        <strong>Uh Oh</strong>
      </Body2>
      <Spacer size={35} />
      <Page404Icon width="100%" />
      <Spacer size={35} />
      <Body2>
        <strong>
          Reach out to <a href="mailto:admin@hexagram.io">Admin</a>
        </strong>
      </Body2>
      <Body2>
        <strong>
          Return to <a href="/">Home Page</a>
        </strong>
      </Body2>
    </div>
  );
};

export { ErrorBoundary404 };
