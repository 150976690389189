import React from 'react';

const EmptyAvatarIcon = ({ width = 80, height = 80, style = {} }) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#F3F4F6" />
      <g clipPath="url(#clip0_14789_84183)">
        <path
          d="M40 20C45.05 20 49.1429 24.0289 49.1429 29C49.1429 33.9711 45.05 38 40 38C34.95 38 30.8571 33.9711 30.8571 29C30.8571 24.0289 34.95 20 40 20ZM40.95 48.9688L45.9857 41.5367C51.6714 42.4648 56 47.3305 56 53.1875V53.75C56 54.9945 54.9786 56 53.7143 56H26.2857C25.0214 56 24 54.9945 24 53.75V53.1875C24 47.3305 28.3286 42.4648 34.0143 41.5367L39.05 48.9688C39.5 49.6367 40.5 49.6367 40.95 48.9688Z"
          fill="#1C64F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_14789_84183">
          <rect
            width="32"
            height="36"
            fill="white"
            transform="matrix(-1 0 0 1 56 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { EmptyAvatarIcon };
