import React, { useCallback } from 'react';
import { ErrorBoundary } from 'pages';
import { withTheme } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Footer } from 'components';
import { useNavigate } from 'react-router-dom';

import {
  ResetPasswordModal,
  ConfirmNewPasswordModal,
  UserConfirmedModal,
  DatalayerLinkModal
} from 'components';

const UnauthenticatedTemplate = withTheme(() => {
  const navigate = useNavigate();
  const handleOnCloseResetPasswordModal = useCallback(() => navigate('/login'));
  return (
    <ErrorBoundary>
      <div style={{ width: '100%', height: '100%' }} className="bg-white">
        <div className="bg-white">
          <div className="grid grid-cols-1 md:grid-cols-1 h-full bg-white">
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
        <Footer />
      </div>
      <ResetPasswordModal onClose={handleOnCloseResetPasswordModal} />
      <ConfirmNewPasswordModal onClose={handleOnCloseResetPasswordModal} />
      <UserConfirmedModal onClose={handleOnCloseResetPasswordModal} />
      <DatalayerLinkModal onClose={handleOnCloseResetPasswordModal} />
    </ErrorBoundary>
  );
});

export { UnauthenticatedTemplate };
