import _ from 'lodash';
import { useCallback, useState, useEffect, useMemo } from 'react';
import styled, { withTheme } from 'styled-components';
import { reprojectRange } from 'utils/helpers';

const ToolTip = styled('div')`
  position: absolute;
  top: -25px;
  color: white;
  width: 35;
  font-size: 0.75rem;
  height: 25;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
`;

const Range = withTheme(
  ({
    min = -50,
    max = 50,
    value = 0,
    label,
    id,
    onChange = _.noop,
    theme,
    disabled,
  }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const percentageSelected = useMemo(() => {
      return reprojectRange(min, max, inputValue) - 1;
    }, [min, max, inputValue]);

    const handleOnMouseUp = useCallback(
      _.debounce((id, event) => onChange(id, event), 100),
      [],
    );

    // eslint-disable-next-line
    const trackColor = useMemo(() => {
      if (!inputValue) {
        return 'accent-gray-500';
      }

      if (inputValue < -30) {
        return 'accent-red-500';
      }

      if (inputValue < 30) {
        return 'accent-gray-500';
      }

      return 'accent-green-500';
    }, [inputValue]);

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginTop: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label
          style={{ flex: 1 }}
          htmlFor={id}
          className={`
          block 
          mb-2
          mr-2
          text-sm 
          font-medium 
          text-${theme.surface.slot1.text.class}
        `}
        >
          {label}
        </label>
        <div id={id} style={{ flex: 4, position: 'relative', width: '100%' }}>
          <input
            type="range"
            min={min}
            max={max}
            disabled={disabled}
            defaultValue={value}
            onChange={(event) => setInputValue(event.target.value)}
            onMouseUp={(event) => handleOnMouseUp(id, event)}
            style={{
              top: 10,
              background: 'none',
              border: 'none',
              outline: 'none',
              boxShadow:
                '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            }}
            className={`
              range
              w-full 
              h-2 
              absolute left-0
              rounded-lg 
              ${trackColor}
              cursor-pointer
            `}
          />

          <ToolTip
            className="tooltip"
            style={{
              left: `calc(${percentageSelected}% - 15px)`,
            }}
          >
            <div
              style={{
                width: 35,
                height: 25,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 10,
                justifyContent: 'center',
              }}
              className={trackColor.replace('accent', 'bg')}
            >
              {inputValue}
            </div>
          </ToolTip>

          <div
            style={{ marginTop: 25, background: 'none' }}
            className="flex justify-between mt-2 text-xs text-gray-600"
          >
            <span className="w-8 text-left">{min}</span>
            <span className="w-8 text-left" style={{ marginLeft: -30 }}>
              {-30}
            </span>
            <span className="w-8 text-center">0</span>
            <span className="w-8 text-left" style={{ paddingLeft: 30 }}>
              +{30}
            </span>
            <span className="w-8 text-right">+{max}</span>
          </div>
        </div>
      </div>
    );
  },
);

export { Range };
