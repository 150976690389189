import _ from 'lodash';
import React, { useCallback } from 'react';
import { FileUploadsTable, Container, PageTitle } from 'components';
import { useUserFilesQuery } from 'api/dlaas/file';
import { useQueryParamState } from 'hooks';
import { Alert } from 'flowbite-react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowMyFilesAlert } from 'store/slices/userOptions/userOptions.slice';
import { Link } from 'react-router-dom';

const MyFilesPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userOptionsStore = useSelector((state) => state.userOptions);
  const [currentPage, setCurrentPage] = useQueryParamState('page', 1);
  const { data, isLoading } = useUserFilesQuery(
    { page: currentPage },
    { pollingInterval: 60000 },
  );

  const handlePageChange = useCallback(
    _.debounce((page) => setCurrentPage(page), 800),
    [setCurrentPage],
  );

  const handleDismissAlert = useCallback(() => {
    dispatch(toggleShowMyFilesAlert());
  }, [dispatch]);

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'private-mirrors' })} />
      <Container height="unset">
        {userOptionsStore.showMyFilesAlert && (
          <div style={{ margin: 25 }}>
            <Alert color="info" onDismiss={handleDismissAlert}>
              <p>
                When you install our advanced file upload plugin for Chia Data
                Layer, your store data is immediately placed on our worldwide
                network. This makes sure your data is fast and easy to access
                from anywhere. As a result, any files you host this way can be
                easily seen and managed in this dedicated space. To start
                uploading files to your store, just download and install the
                DataLayer Storage Uploader.
              </p>
              <Link to="/downloads">
                Click here to download the Datalayer Storage Uploader.
              </Link>
            </Alert>
            <br />
            <Alert color="info">
              <p>
                The difference between "My Mirrors" and "My Files" lies in their
                functionalities and the way they interact with the datalayer.
                <br />
                <br />
                "My Mirrors" helps you create additional mirrors for stores
                already shared on the datalayer. Typically, this sharing
                originates from your own machine. By using "My Mirrors," you can
                work on your data locally, upload it to the datalayer, and then
                it is automatically propagated to all connected mirrors
                globally. You do not need to download the "DataLayer Storage
                Uploader" software to add new mirrors to the system.
                <br />
                <br />
                On the other hand, "Private Mirrors" offers a similar outcome but
                operates differently. It does not necessitate setting up your
                machine for mirroring. Instead, you upload your local datalayer
                files directly to our servers. From there, we handle the
                distribution and management. This method is ideal for users who
                prefer not to establish a mirror on their own machine or wish to
                keep their IP address private from the peer-to-peer network.
              </p>
            </Alert>
          </div>
        )}

        <FileUploadsTable
          data={data?.stores || []}
          isLoading={isLoading}
          currentPage={Number(currentPage)}
          onPageChange={handlePageChange}
          totalPages={data?.total_pages}
          totalCount={data?.total_count}
        />
      </Container>
    </>
  );
};

export { MyFilesPage };
