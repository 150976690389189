import React from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { EmptyAvatarIcon } from 'components';

const Image = styled('img')`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 50%;
`;

const Avatar = withTheme(({src, size = 80}) => {
  const appStore = useSelector((state) => state.app);

  if (src || appStore.user?.avatar) {
    return <Image src={src || appStore.user?.avatar} width={size} height={size} />;
  } else {
    return <EmptyAvatarIcon width={size} height={size} />;
  }
});

export { Avatar };
