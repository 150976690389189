import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { ResetPasswordForm, Spacer } from 'components';
import { FormattedMessage } from 'react-intl';
import { useUrlHash } from 'hooks';

const ResetPasswordModal = ({ onClose = _.noop }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [hashIsActive, setHashActive] = useUrlHash('reset-password');

  useEffect(() => {
    if (hashIsActive) {
      setShowForgotPassword(true);
    } else {
      setShowForgotPassword(false);
    }
  }, [hashIsActive, setShowForgotPassword]);

  const handleClose = useCallback(() => {
    setHashActive(false);
    onClose();
  });

  if (!showForgotPassword) {
    return null;
  }

  return (
    <Modal
      dismissible={true}
      show={true}
      size="md"
      popup={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="forgot-password" />
      </Modal.Header>
      <Modal.Body>
        <Spacer size={20} />
        <ResetPasswordForm onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
};

export { ResetPasswordModal };
