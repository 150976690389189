import { configureStore } from '@reduxjs/toolkit';
import { dlaasApi } from 'api';
import appReducer from './slices/app/app.slice';
import userOptionsReducer from './slices/userOptions/userOptions.slice';
import storage from 'redux-persist/lib/storage';
import { rtkQueryErrorLogger } from './middleware/rtkQueryErrorLogger';

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  REGISTER,
  persistStore,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// The saved auth information is either in sessionStorage
// or localStorage depending on the users preferance.
const getPreloadedState = () => {
  const persistedLocalStorageState =
    window.localStorage.getItem('persist:auth');
  const persistedSessionStorageState =
    window.sessionStorage.getItem('persist:auth');

  return JSON.parse(
    persistedSessionStorageState || persistedLocalStorageState || '{}',
  );
};

const persistUserOptionsConfig = {
  key: 'userOptions',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

const store = configureStore({
  reducer: {
    app: appReducer,
    userOptions: persistReducer(persistUserOptionsConfig, userOptionsReducer),
    [dlaasApi.reducerPath]: dlaasApi.reducer,
  },
  preloadedState: getPreloadedState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER],
      },
    })
      .concat(dlaasApi.middleware)
      .concat(rtkQueryErrorLogger),
});

// Save the auth information either to sessionStorage or localStorage depending on the user preferance.
store.subscribe(() => {
  const state = store.getState();

  if (
    state?.userOptions?.saveTokenToLocalStorage &&
    state?.app?.auth?.accessToken
  ) {
    window.localStorage.setItem(
      'persist:auth',
      JSON.stringify({
        app: {
          auth: state.app.auth,
          user: state.app.user,
        },
      }),
    );
  } else if (state?.app?.auth?.accessToken) {
    window.sessionStorage.setItem(
      'persist:auth',
      JSON.stringify({
        app: {
          auth: state.app.auth,
          user: state.app.user,
        },
      }),
    );
  }
});

const persistor = persistStore(store);

// eslint-disable-next-line no-undef
window.store = store;

export { store, persistor };
